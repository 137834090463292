import React from 'react'
import useValidationSubscription from 'hooks/useValidationSubscription'
import FiglooSelectController from 'components/product-configuration/FiglooSelectController'
import { Valid, Invalid, ValidationResult } from 'hooks/useFormHandler'

function TitleCase(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1)
}

const CardProviderInput = () => {
  const { REACT_APP_ENABLED_CARD_PROVIDERS = 'pps' } = process.env

  const enabledCardProviders = REACT_APP_ENABLED_CARD_PROVIDERS.split(',').map(provider => ({
    name: TitleCase(provider),
    value: provider
  }))

  const name = 'cardProviderSelect'

  const validate = (formValues: Record<string, unknown>): ValidationResult => {
    return formValues[name] ? Valid : Invalid('Please select a card provider to use')
  }

  useValidationSubscription(name, validate)

  return (
    <FiglooSelectController name={name} testId="payment-provider-select">
      <option disabled value="">
        Select your card provider
      </option>
      {enabledCardProviders.map(provider => (
        <option key={provider.value.toLowerCase()} value={provider.value.toLowerCase()}>
          {provider.name}
        </option>
      ))}
    </FiglooSelectController>
  )
}

export default CardProviderInput
