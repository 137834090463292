import React, { ChangeEvent } from 'react'
import { Flex, Input, Text } from '@11FSFoundry/figloo'

export interface RepaymentSchedule {
  min?: number // months
  max?: number
}

interface Props {
  schedule: RepaymentSchedule
  setSchedule: (schedule: RepaymentSchedule) => void
  disabled: boolean
}

const RepaymentScheduleConfiguration = ({ schedule, setSchedule, disabled }: Props) => {
  const handleMonthInput = (fieldName: 'min' | 'max') => (event: ChangeEvent<HTMLInputElement>) =>
    setSchedule({ ...schedule, [fieldName]: parseInt(event.target.value, 10) })
  return (
    <Flex opacity={disabled ? 0.5 : 1} mx={5} flexDirection="column">
      <Flex my={3} flex={1} flexDirection="row" alignItems="center">
        <Text>Term</Text>
        <Input
          mx={2}
          min={1}
          width="100px"
          type="number"
          value={schedule.min || ''}
          disabled={disabled}
          data-testid="min-term"
          onChange={handleMonthInput('min')}
        />
        <Text>—</Text>
        <Input
          mx={2}
          min={schedule.min ? schedule.min + 1 : 2}
          width="100px"
          type="number"
          value={schedule.max || ''}
          disabled={disabled}
          onChange={handleMonthInput('max')}
          data-testid="max-term"
        />
        <Text>months</Text>
      </Flex>
    </Flex>
  )
}

export default RepaymentScheduleConfiguration
