import { Dispatch } from 'react'

export enum ConditionActionType {
  SET_GROUP = 'SET_GROUP',
  SET_PROPERTY = 'SET_PROPERTY',
  SET_OPERATOR = 'SET_OPERATOR',
  SET_PARAMETER = 'SET_PARAMETER',
  DELETE_CONDITION = 'DELETE_CONDITION',
  ADD_BLANK_CONDITION = 'ADD_BLANK_CONDITION'
}

interface SetGroupAction {
  type: ConditionActionType.SET_GROUP
  conditionGroup: string
  index: number
}

interface SetPropertyAction {
  type: ConditionActionType.SET_PROPERTY
  conditionProperty: string
  index: number
}

interface SetOperatorAction {
  type: ConditionActionType.SET_OPERATOR
  conditionOperator: string
  index: number
}

export interface ParameterActionData {
  name: string
  value: RuleParameterValue
  dataType: RuleParameterType
  parameterIndex: number
}

interface AddBlankCondition {
  type: ConditionActionType.ADD_BLANK_CONDITION
}

interface SetConditionParameterAction extends ParameterActionData {
  type: ConditionActionType.SET_PARAMETER
  conditionIndex: number
}

interface DeleteConditionAction {
  type: ConditionActionType.DELETE_CONDITION
  index: number
}

export type ConditionAction =
  | SetGroupAction
  | SetPropertyAction
  | DeleteConditionAction
  | SetOperatorAction
  | SetConditionParameterAction
  | AddBlankCondition

export interface RulesSchema {
  label: string
  propertyGroups: PropertyGroup[]
}

export interface PropertyGroup {
  __typename: 'RulePropertyGroupDefinition'
  name: string
  label: string
  properties: RulesProperty[]
}

export interface RulesProperty {
  __typename: 'RulePropertyDefinition'
  name: string
  label: string
  operatorInput: string
}

export interface ConditionData {
  conditionGroup: string | undefined
  conditionProperty: string | undefined
  conditionOperator: string | undefined
  conditionParameters: RuleParameter[]
}

export enum RuleParameterType {
  Text = 'string',
  Enum = 'enum',
  Amount = 'amount',
  Long = 'long',
  Asset = 'asset',
  Decimal = 'decimal'
}

export interface RuleParameter {
  name?: string
  type?: RuleParameterType
  value?: RuleParameterValue
}

export type RuleParameterValue = AssetAndQuantity | string[] | (number | undefined)[] | undefined

export interface RuleParameterDefinition {
  name: string
  label: string
  type: string
  arity: Arity
  unit: Unit | null
  constraints: string[]
  options: RuleParameterOption[]
}

export interface RuleParameterOption {
  name: string
  label: string
  description: string
}

export type Arity = 'ZeroOrOne' | 'One' | 'OneOrMany' | 'ZeroOrMany'

export interface RuleConditionOperator {
  name: string
  label: string
  parameters: RuleParameterDefinition[]
}

export interface ValidateParametersArgs {
  parameterDefinitions?: RuleParameterDefinition[]
  conditionParameters: RuleParameter[]
  index: number
}

export interface RulesState {
  conditions: ConditionData[]
}

export interface RulesContextData {
  state: RulesState
  dispatch: Dispatch<ConditionAction>
}

export type Unit = {
  symbol: string
  affix: 'Prefix' | 'Suffix'
}

export interface RuleParameterInputWithLabelAndUnit extends RuleParameterInput {
  label: string
  unit: Unit | null
}

export interface RuleParameterInput {
  name: string
  stringValues?: string[]
  longValues?: number[]
  bigDecimalValues?: number[]
  enumValues?: string[]
  intValues?: number[]
  amountValues?: AssetAndBalance[]
}

export interface RuleConditionInputSingle {
  property: {
    name: string
    group: string
  }
  operator: {
    name: string
    parameters: RuleParameterInput[]
  }
}

export interface RuleConditionInputAnd {
  conditions: {
    single?: RuleConditionInputSingle
    and?: RuleConditionInputAnd
    or?: RuleConditionInputOr
  }[]
}

export interface RuleConditionInputOr {
  conditions: {
    single?: RuleConditionInputSingle
    and?: RuleConditionInputAnd
    or?: RuleConditionInputOr
  }[]
}

export interface ProductRuleInput {
  id?: number
  name: string
  description?: string
  trigger: {
    name: string
  }
  condition: {
    single?: RuleConditionInputSingle
    and?: RuleConditionInputAnd
    or?: RuleConditionInputOr
  }
  action: {
    name: string
    parameters: RuleParameterInput[]
  }
}

export type ConditionType = RuleConditionSingle | RuleConditionAnd | RuleConditionOr

export interface RuleConditionSingle {
  number: number
  property: {
    name: string
    group: string
    label: string
    groupLabel: string
  }
  operator: {
    name: string
    label: string
    parameters: RuleParameterInputWithLabelAndUnit[]
  }
  __typename: 'RuleCondition_Single'
}

export interface RuleConditionAnd {
  number: number
  conditions: ConditionType[]
  __typename: 'RuleCondition_And'
}

export interface RuleConditionOr {
  number: number
  conditions: ConditionType[]
  __typename: 'RuleCondition_Or'
}

export interface ProductRule {
  number: number
  name: string
  description?: string
  trigger: {
    name: string
    label: string
  }
  condition: ConditionType
  action: {
    name: string
    label: string
    parameters: RuleParameterInputWithLabelAndUnit[]
  }
}

export interface AddRuleVariables {
  productId: string
  versionNumber: number
  rule: ProductRuleInput
}

export interface EditRuleVariables {
  productId: string
  versionNumber: number
  input: {
    ruleNumber: number
    rule: ProductRuleInput
  }
}

export interface AssetAndQuantity {
  asset: string | undefined
  quantity: number | undefined
}

export interface AssetAndBalance {
  asset: string
  balance: number
}
