import React from 'react'
import { Change } from 'diff'
import { Box } from '@11FSFoundry/figloo'

interface Props {
  changes: Change[]
  when: 'before' | 'after'
}

const getColour = ({ added, removed }: Change) => {
  if (added) return '#5FFFD5'
  if (removed) return '#EF8989'
  return 'transparent'
}

const insertLineBreaks = (string: string) => (
  <>
    {string.split('\n').map((chunk, i, arr) => {
      if (i === arr.length - 1) {
        return <>{chunk}</>
      }
      // if not last item in array insert br where \n was
      return (
        <>
          {chunk}
          <br />
        </>
      )
    })}
  </>
)

interface ChangeChunkProps {
  change: Change
}

const ChangeChunk = ({ change }: ChangeChunkProps) => {
  const border = {
    backgroundColor: getColour(change)
  }

  return <span style={border}>{insertLineBreaks(change.value)}</span>
}

const RuleChanges = ({ changes, when }: Props) => (
  <Box>
    {changes.map(change => {
      if (!change.added && !change.removed) {
        return <ChangeChunk change={change} />
      }

      if (when === 'before' && change.removed) {
        return <ChangeChunk change={change} />
      }

      if (when === 'after' && change.added) {
        return <ChangeChunk change={change} />
      }

      return null
    })}
  </Box>
)

export default RuleChanges
