import { Box, Button, FileUploader } from '@11FSFoundry/figloo'
import React from 'react'

interface Props {
  handleFileUpload: (files: FileList) => void
}

const UploadPanel = ({ handleFileUpload }: Props) => (
  <Box>
    <FileUploader onChange={handleFileUpload} multiple>
      <Button as="div">+ Upload document</Button>
    </FileUploader>
  </Box>
)

export default UploadPanel
