import { Button, Flex, IconClose, Text } from '@11FSFoundry/figloo'
import Condition from 'components/product-configuration/rules/Condition'
import { TacoRuleConditionData } from 'components/product-configuration/rules/taco-rules/types'
import useValidateParameters from 'components/product-configuration/rules/taco-rules/useValidateParameters'
import { RulesSchema, ParameterActionData } from 'components/product-configuration/rules/types'
import React from 'react'

interface Props {
  schemaItem: RulesSchema
  index: number
  condition: TacoRuleConditionData
  disabled?: boolean
  conditionLogicalOperator: string
  setGroup: (group: string) => void
  setProperty: (property: string) => void
  setOperator: (operator: string) => void
  setParameter: (paramActionData: ParameterActionData) => void
  deleteCondition: () => void
}

const TacoRuleCondition = ({
  schemaItem,
  condition,
  index,
  disabled,
  conditionLogicalOperator,
  setGroup,
  setProperty,
  setOperator,
  setParameter,
  deleteCondition
}: Props) => {
  return (
    <Flex backgroundColor="white" py={2} px={4} mt="1px">
      <Flex flex={1} alignItems="baseline">
        <Text mr={2} minWidth={50} data-testid={`tacoConditionLabel-${index}`}>
          {!index ? 'IF' : conditionLogicalOperator}
        </Text>
        <Flex alignItems="flex-start" justifyContent="flex-start" flex={1} flexWrap="wrap">
          <Condition
            schemaItem={schemaItem}
            condition={condition}
            disabled={disabled}
            index={index}
            useValidateParameters={useValidateParameters}
            setGroup={setGroup}
            setProperty={setProperty}
            setOperator={setOperator}
            setParameter={setParameter}
          />
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <Button variant="icon" minWidth="auto" padding={0} disabled={disabled} onClick={deleteCondition}>
          <IconClose />
        </Button>
      </Flex>
    </Flex>
  )
}

export default TacoRuleCondition
