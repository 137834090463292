import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const CLONE_VERSION = gql`
  mutation($productId: String!, $versionNumber: Int!, $note: String!) {
    cloneProductVersion(productId: $productId, versionNumber: $versionNumber, note: $note) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default CLONE_VERSION
