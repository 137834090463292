import React from 'react'
import useValidationSubscription from 'hooks/useValidationSubscription'
import FiglooTextareaController from 'components/product-configuration/FiglooTextareaController'
import { ValidationResult, Invalid, Valid } from 'hooks/useFormHandler'

interface Props {
  testId?: string
}

const ProductDescriptionInput = ({ testId }: Props) => {
  const name = 'description'

  const validate = (formValues: Record<string, unknown>): ValidationResult =>
    formValues[name] ? Valid : Invalid('Please enter the product description')

  useValidationSubscription(name, validate)

  return <FiglooTextareaController name={name} testId={testId} />
}
export default ProductDescriptionInput
