import React, { useState, useContext } from 'react'
import { Flex, Switch, Text } from '@11FSFoundry/figloo'
import { notifySuccess, notifyError } from 'components/Toast'
import RepaymentScheduleConfiguration, {
  RepaymentSchedule
} from 'components/product-configuration/repayment-schedule/RepaymentScheduleConfiguration'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import { getAttributeValue } from 'util/productVersion'
import useUpsertAttributes from 'gql/useUpsertAttributes'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import MainPage from 'components/product-configuration/MainPage'

const BillingConfiguration = () => {
  const [loading, setLoading] = useState(false)
  const { product, version } = useContext(ProductVersionContext)
  const initialSchedule = getAttributeValue<RepaymentSchedule>(version, 'repaymentTermRange') || {}
  const [schedule, setSchedule] = useState(initialSchedule)
  const upsertAttributes = useUpsertAttributes(product.id, version)
  const productVersionEditable = useProductVersionEditable()
  const hasUpdateProductVersionPermission = useCheckPermissions('update:product-versions')
  const disabled = !hasUpdateProductVersionPermission || !productVersionEditable

  const valid = ({ min, max }: RepaymentSchedule) => !!(min && max && min > 0 && max > min)

  const editScheduleAttribute = async () => {
    if (valid(schedule)) {
      setLoading(true)
      try {
        await upsertAttributes({ repaymentTermRange: schedule })

        notifySuccess('Repayment schedule updated')
      } catch (e) {
        notifyError('Updating repayment schedule failed')
      } finally {
        setLoading(false)
      }
    } else {
      notifyError('Invalid data. Please check values')
    }
  }

  return (
    <MainPage
      headline="Repayment schedule"
      saveDisabled={disabled}
      handleSave={editScheduleAttribute}
      saveLabel="Save"
      loading={loading}
      returnUrl={`/products/${product.id}/versions/${version.number}`}>
      <Flex alignItems="center" mb={4}>
        <Switch checked disabled onChange={() => {}} />
        <Text mx={2}>Product has a Repayment Schedule</Text>
      </Flex>
      <Flex backgroundColor="white" p={4}>
        <Flex flexDirection="column">
          <RepaymentScheduleConfiguration schedule={schedule} setSchedule={setSchedule} disabled={disabled} />
        </Flex>
      </Flex>
    </MainPage>
  )
}

export default BillingConfiguration
