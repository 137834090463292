import React from 'react'
import { Flex } from '@11FSFoundry/figloo'
import { ProductRule } from 'components/product-configuration/rules/types'
import { stringifyTacoRule } from 'components/product-configuration/audit/ruleFormatting'

interface Props {
  rule: ProductRule
}

const TacoRuleSummary = ({ rule }: Props) => {
  const stringifiedRule = stringifyTacoRule(rule)
  return (
    <Flex flexDirection="column">
      {stringifiedRule.map(ruleString => (
        <Flex>{ruleString}</Flex>
      ))}
    </Flex>
  )
}

export default TacoRuleSummary
