import React from 'react'
import { Box, Flex, IconClose, Input, Text } from '@11FSFoundry/figloo'
import { ProductVersionFileInput } from 'types'

interface Props {
  doc: ProductVersionFileInput
  handleNameChange: (newName: string) => void
  handleCancelClick: () => void
}

const DocumentToUpload = ({ doc, handleNameChange, handleCancelClick }: Props) => (
  <Flex height={88} alignItems="center" backgroundColor="rgba(255,255,255,0.5)" mb={1} key={doc.file.name}>
    <Box width={96} />
    <Box flex={1} sx={{ px: 1 }} mr={48}>
      <Input required value={doc.name || ''} onChange={e => handleNameChange(e.currentTarget.value)} />
    </Box>
    <Text flex={2}>{doc.file.name}</Text>
    <Text flex={1}>{doc.file.type}</Text>

    {/* 
    note: editing descriptions not currently in designs 
    <Box flex={2} sx={{ px: 1 }}>
      <Input value={description || ''} onChange={updateFileMetadata(i, 'description')} />
    </Box> 
    */}
    <Box px={32}>
      <IconClose onClick={handleCancelClick} />
    </Box>
  </Flex>
)

export default DocumentToUpload
