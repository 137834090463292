import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const ADD_RULE = gql`
  mutation($productId: String!, $versionNumber: Int!, $rule: AddRuleInput!) {
    product(productId: $productId) {
      addRule(versionNumber: $versionNumber, input: $rule) {
        ...product
      }
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default ADD_RULE
