import React, { useState } from 'react'
import { Button, Flex, Heading, Input, Select, Text, Image } from '@11FSFoundry/figloo'
import useCloneVersion from 'gql/useCloneVersion'
import { notifyError, notifySuccess } from 'components/Toast'
import { ProductVersion } from 'types'
import IconClose from 'images/icon-close.svg'

interface Props {
  versions: ProductVersion[]
  productId: string
  setModal: React.Dispatch<React.SetStateAction<boolean>>
}

const NewVersionModal = ({ versions, productId, setModal }: Props) => {
  const [cloneVersionNumber, setCloneVersionNumber] = useState(versions[0].number)
  const [name, setName] = useState('')
  const [cloneVersionMutation, { loading }] = useCloneVersion()

  const handleClone = async () => {
    try {
      await cloneVersionMutation({
        variables: {
          productId,
          versionNumber: cloneVersionNumber,
          note: name
        }
      })

      notifySuccess('Version cloned')
      setModal(false)
    } catch (errors) {
      notifyError('Error creating a new version')
    }
  }

  return (
    <Flex
      onClick={event => {
        event.preventDefault()
        if (event.target === event.currentTarget) setModal(false)
      }}
      style={{ position: 'fixed', zIndex: 1, top: 0, left: 0 }}
      height="100vh"
      width="100vw"
      bg="rgba(0, 0, 0, 0.4)"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      data-testid="Modal">
      <Flex bg="white" p={4} flexWrap="wrap" style={{ position: 'relative' }}>
        <Image
          onClick={() => {
            setModal(false)
          }}
          style={{ position: 'absolute', top: 15, right: 15, cursor: 'pointer' }}
          src={IconClose}
          height="24"
          width="24"
        />
        <Flex flexDirection="column">
          <Heading variant="h3" fontFamily="demi">
            Create new version
          </Heading>
          <Flex flexDirection="column" py={4}>
            <Flex pb={3} alignItems="center" justifyContent="space-between">
              <Text mr={2}>Derived from</Text>
              <Select
                value={cloneVersionNumber}
                onChange={event => {
                  setCloneVersionNumber(Number(event.target.value))
                }}
                width="300px">
                {versions.map(({ number, note }) => (
                  <option key={note} value={number}>
                    {number} {note}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <Text mr={2}>Name</Text>
              <Input
                value={name}
                onChange={event => {
                  setName(event.target.value)
                }}
                width="300px"
              />
            </Flex>
          </Flex>
          <Flex>
            <Button variant="secondary" flex="1" onClick={() => setModal(false)} disabled={loading}>
              Cancel
            </Button>
            <Button
              variant="primary"
              flex="1"
              ml={2}
              type="submit"
              onClick={handleClone}
              loading={loading}
              disabled={loading || !name}>
              Create version
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NewVersionModal
