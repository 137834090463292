/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from 'react'
import { format, parseISO } from 'date-fns'
import { Flex, Text } from '@11FSFoundry/figloo'
import * as Sentry from '@sentry/browser'
import { formatUser } from 'util/format'
import { ProductVersionFileType } from 'types'
import TacoRuleSummary from 'components/product-configuration/audit/TacoRuleSummary'
import DecisionRuleSummary from 'components/product-configuration/audit/DecisionRuleSummary'
import RuleDiff from 'components/product-configuration/audit/RuleDiff'
import { ActualAuditItem, NoteVisibility } from 'components/product-configuration/audit/types'
import { stringifyDecisionRule, stringifyTacoRule } from 'components/product-configuration/audit/ruleFormatting'

interface Props {
  auditTrail: ActualAuditItem[]
}

const fieldName = (field: string) => {
  switch (field) {
    case 'note':
      return 'Note'
    case 'termsAndConditions':
      return 'Terms And Conditions'
    default:
      return field
  }
}

const noteVisibilityDescription = (noteVisibility: NoteVisibility) => {
  switch (noteVisibility) {
    case 'PUBLIC':
      return 'Public'
    case 'ADMIN':
      return 'Admin'
    default:
      return 'Unknown note visibility'
  }
}
const fileTypeDescription = (fileType: ProductVersionFileType) => {
  switch (fileType) {
    case ProductVersionFileType.Document:
      return 'Document'
    case ProductVersionFileType.Image:
      return 'Image'
    case ProductVersionFileType.Approval:
      return 'Approval document'
    default:
      return 'Unknown file type'
  }
}

const describeAttribute = (attributeName: string, attributeValue: unknown): string => {
  switch (attributeName) {
    case 'repaymentTermRange': {
      const range = attributeValue as { min: number; max: number }
      return `${range.min}–${range.max} months`
    }

    default:
      return attributeValue as string
  }
}

const categoriseAuditItem = (auditItem: ActualAuditItem) => {
  switch (auditItem.__typename) {
    case 'ProductVersionAttributeAdded':
      return {
        ...auditItem,
        label: 'Attributed added',
        message: (
          <span>
            Attribute <strong>{auditItem.name}</strong> added with value{' '}
            <strong>{describeAttribute(auditItem.name, auditItem.value)}</strong>
          </span>
        )
      }
    case 'ProductVersionAttributeChanged':
      return {
        ...auditItem,
        label: 'Attribute changed',
        message: (
          <span>
            Attribute <strong>{auditItem.name}</strong> changed from{' '}
            <strong>{describeAttribute(auditItem.name, auditItem.oldValue)}</strong> to{' '}
            <strong>{describeAttribute(auditItem.name, auditItem.newValue)}</strong>
          </span>
        )
      }
    case 'ProductVersionApprovalRequested':
      return {
        ...auditItem,
        label: 'Approval requested',
        message: (
          <span>
            Approval requested from <strong>{auditItem.approver}</strong>
          </span>
        )
      }
    case 'ProductVersionApproved':
      return {
        ...auditItem,
        label: 'Version approved',
        message: ''
      }
    case 'ProductVersionCreated':
      return {
        ...auditItem,
        label: 'Version created',
        message: ''
      }
    case 'ProductVersionCloned':
      return {
        ...auditItem,
        label: 'Version created',
        message: (
          <span>
            Cloned from <strong>{auditItem.baseVersion}</strong>
          </span>
        )
      }
    case 'ProductVersionStatusChanged':
      return {
        ...auditItem,
        label: 'Status changed',
        message: (
          <span>
            Status from <strong>{auditItem.oldStatus}</strong> to <strong>{auditItem.newStatus}</strong>
          </span>
        )
      }
    case 'ProductVersionRejected':
      return {
        ...auditItem,
        label: 'Version rejected',
        message: (
          <span>
            Review rejected with comment: <strong>{auditItem.comment}</strong>
          </span>
        )
      }
    case 'ProductVersionAttributeRemoved':
      return {
        ...auditItem,
        label: 'Attribute removed',
        message: (
          <span>
            Attribute <strong>{auditItem.name}</strong>
          </span>
        )
      }
    case 'ProductVersionFieldChanged':
      return {
        ...auditItem,
        label: 'Field changed',
        message: (
          <span>
            <strong>{fieldName(auditItem.field)}</strong> from <strong>{auditItem.oldFieldValue}</strong> to{' '}
            <strong>{auditItem.newFieldValue}</strong>
          </span>
        )
      }
    case 'ProductVersionNoteAdded':
      return {
        ...auditItem,
        label: 'Note added',
        message: (
          <span>
            <strong>{noteVisibilityDescription(auditItem.noteVisibility)}</strong> note{' '}
            <strong>{auditItem.noteName}</strong> with value <strong>{auditItem.noteValue}</strong>
          </span>
        )
      }
    case 'ProductVersionNoteRemoved':
      return {
        ...auditItem,
        label: 'Note removed',
        message: (
          <span>
            Note <strong>{auditItem.noteName}</strong>
          </span>
        )
      }
    case 'ProductVersionNoteChanged':
      return {
        ...auditItem,
        label: 'Note changed',
        message: (
          <span>
            Note <strong>{auditItem.noteName}</strong> changed to{' '}
            <strong>{noteVisibilityDescription(auditItem.newNoteVisibility)}</strong> with value{' '}
            <strong>{auditItem.newNoteValue}</strong>
          </span>
        )
      }
    case 'ProductVersionFileAdded':
      return {
        ...auditItem,
        label: 'File added',
        message: (
          <span>
            {fileTypeDescription(auditItem.fileType)} <strong>'{auditItem.fileName}'</strong> with description{' '}
            <strong>'{auditItem.fileDescription}'</strong>
          </span>
        )
      }
    case 'ProductVersionFileRemoved':
      return {
        ...auditItem,
        label: 'File removed',
        message: (
          <span>
            {fileTypeDescription(auditItem.fileType)} <strong>'{auditItem.fileName}'</strong> removed
          </span>
        )
      }
    case 'ProductVersionFileChanged':
      return {
        ...auditItem,
        label: 'Filed changed',
        message: (
          <span>
            <span>File changed</span>
            {auditItem.oldFileName !== auditItem.newFileName ? (
              <span>
                from <strong>'{auditItem.oldFileName}'</strong> to <strong>{auditItem.newFileName}</strong>
                <br />
              </span>
            ) : (
              ''
            )}
            {auditItem.oldFileDescription !== auditItem.newFileDescription ? (
              <span>
                Description changed from <strong>'{auditItem.oldFileDescription}'</strong> to{' '}
                <strong>'{auditItem.newFileDescription}'</strong>
              </span>
            ) : (
              ''
            )}
          </span>
        )
      }
    case 'ProductVersionRuleAdded':
      return {
        ...auditItem,
        label: 'Rule Added',
        message: (
          <span>
            Rule <strong>'{auditItem.rule.name}'</strong> added
            {auditItem.rule.description && (
              <>
                {' with description'} <strong>{auditItem.rule.description}</strong>
              </>
            )}
            <TacoRuleSummary rule={auditItem.rule} />
          </span>
        )
      }
    case 'ProductVersionRuleDeleted':
      return {
        ...auditItem,
        label: 'Rule Removed',
        message: (
          <span>
            Rule <strong>'{auditItem.rule.name}'</strong> removed
            {auditItem.rule.description && (
              <>
                {' with description'} <strong>{auditItem.rule.description}</strong>
              </>
            )}
            <TacoRuleSummary rule={auditItem.rule} />
          </span>
        )
      }
    case 'ProductVersionRuleChanged':
      return {
        ...auditItem,
        label: 'Rule changed',
        message: (
          <Flex flexDirection="column" flex={1}>
            <RuleDiff
              oldRule={stringifyTacoRule(auditItem.oldRule).join('\n')}
              newRule={stringifyTacoRule(auditItem.newRule).join('\n')}
            />
          </Flex>
        )
      }
    case 'ProductVersionDecisionRuleAdded':
      return {
        ...auditItem,
        label: 'Decision rule added',
        message: <DecisionRuleSummary rule={auditItem.rule} />
      }

    case 'ProductVersionDecisionRuleChanged': {
      const oldRuleStrings = stringifyDecisionRule(auditItem.oldDecisionRule).join('\n')
      const newRuleStrings = stringifyDecisionRule(auditItem.newDecisionRule).join('\n')

      return {
        ...auditItem,
        label: 'Decision rule changed',
        message: (
          <Flex flexDirection="column" flex={1}>
            <RuleDiff oldRule={oldRuleStrings} newRule={newRuleStrings} />
          </Flex>
        )
      }
    }
    case 'ProductVersionWorkflowTriggerAdded': {
      return {
        ...auditItem,
        label: 'Workflow trigger added',
        message: (
          <span>
            Trigger ID: <strong>{auditItem.triggerId}</strong>
          </span>
        )
      }
    }
    case 'ProductVersionWorkflowTriggerRemoved': {
      return {
        ...auditItem,
        label: 'Workflow trigger removed',
        message: (
          <span>
            Trigger ID: <strong>{auditItem.triggerId}</strong>
          </span>
        )
      }
    }
    case 'ProductVersionRoleAdded': {
      return {
        ...auditItem,
        label: 'Role added',
        message: (
          <span>
            Role ID: <strong>{auditItem.roleId}</strong>
          </span>
        )
      }
    }
    case 'ProductVersionRoleRemoved': {
      return {
        ...auditItem,
        label: 'Role removed',
        message: (
          <span>
            Role ID: <strong>{auditItem.roleId}</strong>
          </span>
        )
      }
    }
    default:
      Sentry.withScope(scope => {
        // @ts-ignore
        scope.setExtra('type', auditItem.__typename)
        scope.setLevel(Sentry.Severity.Error)
        Sentry.captureMessage('Audit event type not found')
      })

      return null
  }
}

interface AuditSummary {
  author: string
  createdAt: string
  label: string
  message: ReactNode
}

const Audit = ({ auditTrail }: Props) => {
  const chunks = auditTrail
    .map(auditItem => categoriseAuditItem(auditItem))
    .filter(auditItem => auditItem !== null) as AuditSummary[]

  return (
    <>
      <Flex pt={1} mt={70} mb={1} mx="auto" p={2} alignItems="center" justifyContent="center" width={1152}>
        <Flex flexDirection="column" alignItems="center" width={180}>
          <Text>Date</Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center" width={180}>
          <Text>Time</Text>
        </Flex>
        <Flex width={80}>
          <Text>Editor</Text>
        </Flex>
        <Flex justifyContent="center" width={180}>
          <Text>Action Type</Text>
        </Flex>
        <Flex flex={1} width={180}>
          <Text textAlign="center" margin="auto">
            Action
          </Text>
        </Flex>
      </Flex>
      <Flex flex={1} justifyContent="center" mb={7}>
        <Flex flexDirection="column" width={1152} data-testid="auditList">
          {chunks.map(({ author, createdAt, label, message }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex key={i} pt={1} mb={1} backgroundColor="white" p={2} alignItems="center" data-testid="auditDetail">
              <Flex flexDirection="column" alignItems="center" width={180} data-testid="actionDate">
                <Text>{format(parseISO(createdAt), 'dd/MM/yyyy')}</Text>
              </Flex>
              <Flex flexDirection="column" alignItems="center" width={180} data-testid="actionTime">
                <Text>{format(parseISO(createdAt), 'HH:mm')}</Text>
              </Flex>
              <Flex width={80} data-testid="editorDetail">
                <Text variant="circle" bg="tagBanana">
                  {formatUser(author)}
                </Text>
              </Flex>
              <Flex justifyContent="center" width={180} data-testid="actionType">
                <Text>{label}</Text>
              </Flex>
              <Flex flex={1} width={180} data-testid="actionNote">
                <Text ml={2} color={message === '' ? 'gsConcrete' : 'copyOne'} data-testid="actionNoteContent">
                  {message !== '' ? message : 'No action notes found'}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  )
}

export default Audit
