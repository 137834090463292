import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import reducer from 'components/product-configuration/rules/decision-rules/reducer'
import {
  DecisionRuleAction,
  DecisionRuleData,
  DecisionRuleState,
  DecisionRuleType
} from 'components/product-configuration/rules/decision-rules/types'
import useDecisionRuleEditable from 'components/product-configuration/rules/decision-rules/useDecisionRuleEditable'
import { getParameterData } from 'components/product-configuration/rules/taco-rules/TacoRules'
import { useContext, useReducer } from 'react'
import { v4 as uuid } from 'uuid'
import useSaveDecisionRule from 'components/product-configuration/rules/decision-rules/useSaveDecisionRule'

export interface DecisionRuleHandler {
  ruleType: DecisionRuleType
  state: DecisionRuleState
  dispatch: React.Dispatch<DecisionRuleAction>
  ruleIsNew: boolean
  editable: boolean
  hasData: boolean
  saveDecisionRule: () => Promise<void>
}

export const stateFromData = (decisionRule: DecisionRuleData): DecisionRuleState => {
  const defaultResult = decisionRule.defaultResult.parameters.map(getParameterData)

  const conditions = decisionRule.steps.map((step) => {
    return {
      key: uuid(),
      conditionGroup: step.condition.property.group,
      conditionProperty: step.condition.property.name,
      conditionOperator: step.condition.operator.name,
      conditionParameters: step.condition.operator.parameters.map(getParameterData),
      result: step.result.parameters.map(getParameterData)
    }
  })

  return {
    conditions,
    defaultResult
  }
}

export default (ruleType: DecisionRuleType): DecisionRuleHandler => {
  const { product, version } = useContext(ProductVersionContext)

  const ruleFromVersion: DecisionRuleData | undefined = version.decisionRules.find(
    ({ name }: DecisionRuleData) => name === ruleType
  )

  const initialState = ruleFromVersion
    ? stateFromData(ruleFromVersion)
    : {
        conditions: [],
        defaultResult: []
      }

  const [state, dispatch] = useReducer(reducer, initialState)

  const ruleIsNew = ruleFromVersion === undefined
  const editable = useDecisionRuleEditable(ruleIsNew)
  const hasData = state.conditions.length > 0 || state.defaultResult.length > 0

  const save = useSaveDecisionRule({
    decisionRuleType: ruleType,
    newRule: ruleIsNew,
    productId: product.id,
    versionNumber: version.number,
    failureMessage: `Failed to update ${ruleType}`
  })

  return {
    ruleType,
    state,
    dispatch,
    ruleIsNew,
    editable,
    hasData,
    saveDecisionRule: () => save(state)
  }
}
