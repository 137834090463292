import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client, {
  RedirectLoginOptions,
  getIdTokenClaimsOptions,
  IdToken,
  RedirectLoginResult,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  Auth0ClientOptions,
  Auth0Client
} from '@auth0/auth0-spa-js'

interface User {
  name: string
  picture: string
  email: string
  sub: string
  [otherKey: string]: unknown
}

interface Auth0Context {
  isAuthenticated: boolean
  user?: User
  token?: string
  loading: boolean
  handleRedirectCallback(): Promise<RedirectLoginResult>
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken | undefined>
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  logout(o?: LogoutOptions): void
}
interface Auth0ProviderOptions {
  children: React.ReactElement
  onRedirectCallback?(result: RedirectLoginResult): void
}

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<Auth0Context>({} as Auth0Context)
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({} as User)
  const [token, setToken] = useState('')
  const [auth0Client, setAuth0] = useState<Auth0Client>({} as Auth0Client)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.pathname.includes('/auth/auth0') && window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isUserAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isUserAuthenticated)

      if (isUserAuthenticated) {
        const authenticatedUser = await auth0FromHook.getUser()
        // @ts-ignore
        setUser(authenticatedUser)

        const authToken = await auth0FromHook.getTokenSilently()
        setToken(authToken)
      }

      setLoading(false)
    }

    initAuth0()
    // eslint-disable-next-line
  }, [])

  const handleRedirectCallback = async () => {
    setLoading(true)
    const result = await auth0Client.handleRedirectCallback()

    const authenticatedUser = await auth0Client.getUser()

    setLoading(false)
    setIsAuthenticated(true)
    // @ts-ignore
    setUser(authenticatedUser)
    return result
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        token,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (o: getIdTokenClaimsOptions) => auth0Client.getIdTokenClaims(o),
        loginWithRedirect: (o: RedirectLoginOptions) => auth0Client.loginWithRedirect(o),
        getTokenSilently: (o: GetTokenSilentlyOptions) => auth0Client.getTokenSilently(o),
        getTokenWithPopup: (o: GetTokenWithPopupOptions) => auth0Client.getTokenWithPopup(o),
        logout: (o: LogoutOptions) => auth0Client.logout(o)
      }}>
      {children}
    </Auth0Context.Provider>
  )
}
