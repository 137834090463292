import React, { RefObject, ReactNode, useState, ChangeEvent, useEffect, createRef } from 'react'
import { Button, Input, Textarea, Box, IconEdit, SxStyleProp, Spinner } from '@11FSFoundry/figloo'

interface Props {
  value?: string
  children: ReactNode
  displayAsTextarea?: boolean
  handleChange?: (value: string) => void
  handleCommit?: () => void
  handleCancel?: () => void
  disabled?: boolean
  withExplicitSave?: boolean
  sx?: SxStyleProp
  saving?: boolean
  ml?: number
}

const ClickEdit = ({
  children,
  handleChange,
  handleCommit,
  handleCancel,
  value = '',
  displayAsTextarea,
  disabled,
  withExplicitSave,
  sx,
  saving,
  ml = -5,
  ...rest
}: Props) => {
  const [editable, setEditable] = useState(false)
  const [focused, setFocused] = useState(false)
  const textInput = displayAsTextarea ? createRef<HTMLTextAreaElement>() : createRef<HTMLInputElement>()

  // Idea: pass in component which will render input component
  const input = displayAsTextarea ? (
    <Textarea
      data-testid="clickEditTextarea"
      ref={textInput as RefObject<HTMLTextAreaElement>}
      sx={{ backgroundColor: 'gsSmoke', ...sx }}
      value={value}
      onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) => handleChange && handleChange(target.value)}
    />
  ) : (
    <Input
      data-testid="clickEditTextInput"
      ref={textInput as RefObject<HTMLInputElement>}
      sx={{ backgroundColor: 'gsSmoke', ...sx }}
      value={value}
      onChange={({ target }: ChangeEvent<HTMLInputElement>) => handleChange && handleChange(target.value)}
    />
  )

  useEffect(() => {
    if (editable && textInput && textInput.current && !disabled) {
      textInput.current.focus()
      setFocused(true)
    }
  }, [editable, textInput, disabled])

  const commit = (explicit?: boolean) => {
    setEditable(false)
    setFocused(false)

    if (withExplicitSave && !explicit) return

    if (handleCommit) handleCommit()
  }

  const cancel = () => {
    setEditable(false)
    setFocused(false)
    if (handleCancel) handleCancel()
  }

  return (
    <>
      <Button
        data-testid="clickEdit"
        variant="icon"
        disabled={disabled}
        type="button"
        onClick={() => !disabled && setEditable(true)}
        onBlur={() => {
          if (focused && !withExplicitSave) {
            commit()
          }
        }}
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          p: 0,
          width: editable ? '100%' : 'auto',
          color: 'copyOne'
        }}
        onKeyDown={event => {
          if (event.key === 'Enter' && !disabled) commit(true)
        }}
        {...rest}>
        {!focused && !disabled && <Box sx={{ position: 'absolute', ml }}>{saving ? <Spinner /> : <IconEdit />}</Box>}
        {editable ? input : children}
      </Button>
      {withExplicitSave && editable && (
        <>
          <Button variant="secondary" ml={2} type="button" onClick={cancel} data-testid="clickEditCancel">
            Cancel
          </Button>
          <Button variant="primary" ml={2} type="button" onClick={() => commit(true)} data-testid="clickEditSave">
            Save
          </Button>
        </>
      )}
    </>
  )
}
export default ClickEdit
