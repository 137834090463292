import { Flex, Heading } from '@11FSFoundry/figloo'
import React, { ChangeEvent, useContext, useState } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import MainPage from 'components/product-configuration/MainPage'
import useUpsertAttributes from 'gql/useUpsertAttributes'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { getAttributeValue } from 'util/productVersion'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import BillingInterval from 'components/product-configuration/billing-dates/BillingInterval'
import DayPicker from 'components/product-configuration/billing-dates/DayPicker'
import DueDate from 'components/product-configuration/billing-dates/DueDate'

const BillingDates = () => {
  const [loading, setLoading] = useState(false)
  const { product, version } = useContext(ProductVersionContext)

  const productVersionEditable = useProductVersionEditable()
  const hasUpdateProductVersionPermission = useCheckPermissions('update:product-versions')
  const disabled = !productVersionEditable || !hasUpdateProductVersionPermission

  const upsertAttributes = useUpsertAttributes(product.id, version)

  const [billingInterval, setBillingInterval] = useState(1)
  const [billingDays, setBillingDays] = useState<number[]>(getAttributeValue(version, 'billingPossibleDueDays') || [])
  const [gracePeriod, setGracePeriod] = useState<number>(getAttributeValue(version, 'gracePeriod') || 1)

  const handleSave = async () => {
    setLoading(true)
    try {
      await upsertAttributes({
        billingPossibleDueDays: billingDays,
        ...(gracePeriod !== undefined ? { gracePeriod } : {})
      })

      notifySuccess('updated billing dates')
    } catch (e) {
      notifyError('Updating billing dates failed')
    } finally {
      setLoading(false)
    }
  }

  const isDaySelected = (day: number) => billingDays.includes(day)

  const handleDayChange = (day: number) => {
    if (isDaySelected(day)) {
      setBillingDays(billingDays.filter(i => i !== day))
    } else {
      setBillingDays(billingDays.concat([day]))
    }
  }

  const handleGracePeriodChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { valueAsNumber } = event.target
    if (valueAsNumber <= 0) return
    setGracePeriod(valueAsNumber)
  }

  return (
    <MainPage
      headline="Billing Dates"
      handleSave={handleSave}
      saveDisabled={disabled}
      saveLabel="Save"
      loading={loading}
      returnUrl={`/products/${product.id}/versions/${version.number}`}>
      <Flex backgroundColor="white" p={3}>
        <Flex flexDirection="column" mr={8}>
          <Heading as="h3" variant="h3">
            Billing Interval
          </Heading>
          <BillingInterval disabled={disabled} onChange={setBillingInterval} value={billingInterval} />
        </Flex>
        <Flex flexDirection="column">
          <Heading as="h3" variant="h3" flex="1">
            Due date options
          </Heading>
          <DayPicker
            isDaySelected={isDaySelected}
            maxDays={28}
            rows={3}
            onChange={handleDayChange}
            disabled={disabled}
          />
          <DueDate onChange={handleGracePeriodChange} value={gracePeriod} disabled={disabled} />
        </Flex>
      </Flex>
    </MainPage>
  )
}

export default BillingDates
