import React, { ChangeEvent, useState } from 'react'
import { Button, CheckboxField, Input, Flex, Text } from '@11FSFoundry/figloo'
import { ProductVersionNote } from 'types'

interface Props {
  addNote: (note: ProductVersionNote) => void
  disabled?: boolean
}

const AddNote = ({ addNote, disabled }: Props) => {
  const initialNote: ProductVersionNote = {
    name: '',
    value: '',
    visibility: 'PUBLIC'
  }
  const [open, setOpen] = useState(false)
  const [note, setNote] = useState(initialNote)

  const onCancel = () => {
    setNote(initialNote)
    setOpen(false)
  }

  const onSave = () => {
    addNote(note)
    setNote(initialNote)
    setOpen(false)
  }

  const onOpen = () => {
    setOpen(true)
  }

  const nameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setNote({ ...note, name: event.target.value })
  }

  const valueChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setNote({ ...note, value: event.target.value })
  }

  const isPublic = (v: string) => v === 'PUBLIC'

  const visibilityChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newVisibility = isPublic(note.visibility) ? 'ADMIN' : 'PUBLIC'
    setNote({ ...note, visibility: newVisibility })
    return event
  }

  const renderClosed = () => (
    <Button
      backgroundColor="gsMist"
      variant="dashed"
      p={3}
      mb={1}
      onClick={onOpen}
      disabled={disabled}
      display="flex"
      height="auto"
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}
      flex="1">
      <Flex flex="initial" backgroundColor="mint" px={2} py={1} alignItems="center">
        <Text pl={1} color="copyOne">
          + Add note
        </Text>
      </Flex>
    </Button>
  )

  const renderOpen = () => (
    <Flex flex={1} alignItems="center" p={3} mb={1} backgroundColor="white">
      <Flex flex={1}>
        <Input name="name" type="text" value={note.name} onChange={nameChanged} sx={{ mr: 3 }} />
      </Flex>
      <Flex flex={2}>
        <Input name="value" type="text" value={note.value} onChange={valueChanged} />
      </Flex>
      <Flex width={75} justifyContent="center">
        <CheckboxField label="" checked={isPublic(note.visibility)} onChange={visibilityChanged} />
      </Flex>
      <Flex flex={1} justifyContent="flex-end">
        <Button variant="secondary" onClick={onCancel} disabled={disabled} mr={3}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave} disabled={disabled}>
          Save
        </Button>
      </Flex>
    </Flex>
  )

  const getContent = () => (open ? renderOpen() : renderClosed())

  return <Flex>{getContent()}</Flex>
}

export default AddNote
