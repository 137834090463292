import React from 'react'
import { Box } from '@11FSFoundry/figloo'
import Parameter from 'components/product-configuration/rules/Parameter'
import {
  RuleParameterDefinition,
  RuleParameter,
  ParameterActionData
} from 'components/product-configuration/rules/types'

interface Props {
  parameterDefinitions: RuleParameterDefinition[]
  parameters: RuleParameter[]
  disabled?: boolean
  handleChange: (parameterActionData: ParameterActionData) => void
  conditionIndex?: number
  testLabel?: string
  testId?: string
}

type TestIdArgs = [number, number?, string?]

const generateTestId = (...args: TestIdArgs) => {
  return `conditionOperand-${args.filter(item => item !== undefined).join('-')}`
}

const Parameters = ({ parameterDefinitions, parameters, disabled, handleChange, conditionIndex, testLabel }: Props) => (
  <>
    {parameterDefinitions.map((parameterDefinition, parameterIndex) => (
      <Box mr={3} py={1} key={parameterDefinition.name}>
        <Parameter
          parameterDefinition={parameterDefinition}
          parameterIndex={parameterIndex}
          parameterValue={parameters && parameters[parameterIndex]?.value}
          disabled={disabled}
          handleChange={handleChange}
          testId={generateTestId(parameterIndex, conditionIndex, testLabel)}
        />
      </Box>
    ))}
  </>
)

export default Parameters
