import React from 'react'
import { useHistory } from 'react-router-dom'
import { notifySuccess, notifyError } from 'components/Toast'
import { useMutation } from '@apollo/react-hooks'
import CREATE_PRODUCT from 'gql/CREATE_PRODUCT'
import CreateProduct from 'components/product-configuration/create-product/CreateProduct'

interface CreateProductVariables {
  name: string
  description: string
  attributes: string
}

const CreateProductController = () => {
  const history = useHistory()

  const [createNewProduct, { loading }] = useMutation<{ createNewProduct: { id: string } }, CreateProductVariables>(
    CREATE_PRODUCT,
    {
      onError: () => notifyError('create new product failed'),
      onCompleted: data => {
        notifySuccess('Product created')
        history.push(`/products/${data.createNewProduct.id}`)
      }
    }
  )

  return <CreateProduct completeSubmit={createNewProduct} loading={loading} />
}

export default CreateProductController
