import { Heading } from '@11FSFoundry/figloo'
import MainPage from 'components/product-configuration/MainPage'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import DecisionRule from 'components/product-configuration/rules/decision-rules/DecisionRule'
import { DecisionRuleType } from 'components/product-configuration/rules/decision-rules/types'
import useDecisionRuleHandler from 'hooks/useDecisionRuleHandler'
import React, { MouseEvent, useContext, useState } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'

const FeesPage = () => {
  const { product, version } = useContext(ProductVersionContext)
  const invoiceFeeHandler = useDecisionRuleHandler(DecisionRuleType.InvoiceFee)
  const openingFeeHandler = useDecisionRuleHandler(DecisionRuleType.AccountOpeningFee)

  const [loading, setLoading] = useState(false)

  const onClick = async (event: MouseEvent) => {
    event.preventDefault()

    setLoading(true)

    try {
      if (openingFeeHandler.hasData) await openingFeeHandler.saveDecisionRule()
      if (invoiceFeeHandler.hasData) await invoiceFeeHandler.saveDecisionRule()
      notifySuccess('Updated fees & charges')
    } catch (e) {
      notifyError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const saveEnabled =
    (openingFeeHandler.editable && openingFeeHandler.hasData) ||
    (invoiceFeeHandler.editable && invoiceFeeHandler.hasData)

  return (
    <MainPage
      headline="Fees & Charges"
      handleSave={onClick}
      loading={loading}
      saveDisabled={!saveEnabled}
      returnUrl={`/products/${product.id}/versions/${version.number}`}>
      <>
        <Heading variant="h2" m="3">
          Account opening fee
        </Heading>
        <DecisionRule handler={openingFeeHandler} newRuleLabel="+ Add new fee rule" defaultResultLabel="Nominal fee" />
        <Heading variant="h2" m="3">
          Invoice fee
        </Heading>
        <DecisionRule handler={invoiceFeeHandler} newRuleLabel="+ Add new fee rule" defaultResultLabel="Nominal fee" />
      </>
    </MainPage>
  )
}

export default FeesPage
