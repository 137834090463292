import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const DELETE_RULE = gql`
  mutation($productId: String!, $versionNumber: Int!, $ruleNumber: Int!) {
    product(productId: $productId) {
      deleteRule(versionNumber: $versionNumber, input: { ruleNumber: $ruleNumber }) {
        ...product
      }
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default DELETE_RULE
