import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import ProductList from 'components/product-configuration/ProductList'

const GET_PRODUCTS = gql`
  query {
    products {
      id
      name
      liveVersions
      userCount
      versions {
        status
        review {
          reviewer
        }
      }
    }
  }
`

const ProductListLoader = () => {
  const { loading, error, data } = useQuery(GET_PRODUCTS)

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  return <ProductList products={data.products} />
}

export default ProductListLoader
