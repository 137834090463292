import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const ARCHIVE_VERSION = gql`
  mutation($productId: String!, $versionNumber: Int!) {
    archiveProductVersion(productId: $productId, versionNumber: $versionNumber) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default ARCHIVE_VERSION
