import React, { ReactNode } from 'react'
import { Heading } from '@11FSFoundry/figloo'

interface Props {
  label?: string
  children: ReactNode[] | ReactNode
}

const FormSection = ({ label: sectionLabel, children }: Props) => (
  <>
    {sectionLabel && (
      <Heading variant="h2" as="h2" mt={7} mb={5}>
        {sectionLabel}
      </Heading>
    )}
    {children}
  </>
)

export default FormSection
