/* eslint-disable no-underscore-dangle */
import React, { ChangeEvent, useContext, useReducer, useState } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import { Box, Button, Flex } from '@11FSFoundry/figloo'
import FormSection from 'components/product-configuration/FormSection'
import FormField from 'components/product-configuration/FormField'
import WorkflowInput from 'components/product-configuration/workflow/WorkflowInput'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { WorkflowDefinition } from 'components/product-configuration/workflow/types'
import GET_WORKFLOW_DEFINITIONS from 'gql/GET_WORKFLOW_DEFINITIONS'
import { useQuery } from '@apollo/react-hooks'
import useEditNote from 'gql/useEditNote'
import { ProductVersionNote } from 'types'
import useAddNote from 'gql/useAddNote'
import Page from 'components/Page'
import ProductVersionTabs from 'components/product-configuration/ProductVersionTabs'
import { WorkflowContext, reducer, initialState, ReducerActions } from 'reducers/WorkflowReducer'
import WorkflowBlock from './WorkflowBlock'

const WorkflowPage = () => {
  const [loading, setLoading] = useState(false)
  const { product, version } = useContext(ProductVersionContext)
  const hasUpdateProductVersionPermission = useCheckPermissions('update:product-versions')
  const productVersionEditable = useProductVersionEditable()
  const { data } = useQuery<{ definitions: { workflows: WorkflowDefinition[] } }>(GET_WORKFLOW_DEFINITIONS)
  const disabled = !hasUpdateProductVersionPermission || !productVersionEditable
  const workflowSelect = version.notes.find((i: ProductVersionNote) => i.name === 'product_category')?.value ?? ''
  const [selectedWorkflow, setSelectedWorkflow] = useState(workflowSelect)
  const [addNoteMutation] = useAddNote()
  const [editNoteMutation] = useEditNote()
  const [state, dispatch] = useReducer(reducer, initialState)

  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedWorkflow(event.target.value)
    dispatch({
      type: ReducerActions.REMOVE_ALL_ATTRIBUTES
    })
  }

  const completeSubmit = async (workflow: string) => {
    setLoading(true)
    try {
      if (workflowSelect) {
        await editNoteMutation({
          variables: {
            productId: product.id,
            versionNumber: version.number,
            // @ts-ignore
            note: { name: 'product_category', value: workflow, visibility: 'PUBLIC' }
          }
        })
      } else {
        await addNoteMutation({
          variables: {
            productId: product.id,
            versionNumber: version.number,
            // @ts-ignore
            note: { name: 'product_category', value: workflow, visibility: 'PUBLIC' }
          }
        })
      }
      notifySuccess('Updated workflow')
    } catch (error) {
      notifyError('Updating workflow failed')
    } finally {
      setLoading(false)
    }
  }

  const workflows = data?.definitions.workflows ?? []

  return (
    <Page>
      <ProductVersionTabs>
        <WorkflowContext.Provider value={{ state, dispatch }}>
          <Flex flex={1} alignItems="center" py={3} width="100%" maxWidth={1152} flexDirection="column">
            <Flex width="100%" flex={1} flexDirection="column">
              <FormSection>
                <FormField label="Application Workflow">
                  <WorkflowInput
                    disabled={disabled}
                    onChange={onSelectChange}
                    options={workflows}
                    value={selectedWorkflow}
                  />
                </FormField>
              </FormSection>
              {workflows
                .filter(workflow => workflow.name === selectedWorkflow)
                .map(workflow => (
                  <Box mb={4} key={workflow.label}>
                    {workflow.steps.map(step => (
                      <WorkflowBlock key={step.label} step={step} />
                    ))}
                  </Box>
                ))}
              <Flex flex={1} py={5}>
                <Button disabled variant="secondary" flex={1}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  flex="1"
                  ml={2}
                  type="submit"
                  onClick={() => {
                    completeSubmit(selectedWorkflow)
                  }}
                  loading={loading}
                  disabled={disabled || loading || !selectedWorkflow}>
                  Save
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </WorkflowContext.Provider>
      </ProductVersionTabs>
    </Page>
  )
}

export default WorkflowPage
