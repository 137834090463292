import { useEffect } from 'react'
import {
  RuleParameterDefinition,
  RuleParameter,
  ValidateParametersArgs
} from 'components/product-configuration/rules/types'
import { TacoRulesActionType, TacoRulesContextData } from 'components/product-configuration/rules/taco-rules/types'
import useRulesContext from 'components/product-configuration/rules/useRulesContext'

export const validate = (
  conditionParameters: RuleParameter[],
  parameterDefinitions?: RuleParameterDefinition[]
) => {
  if (!parameterDefinitions) return false
  if (conditionParameters.length !== parameterDefinitions.length) return false

  return parameterDefinitions.reduce<boolean>((isValid, _, index) => {
    // if previous parameter is not valid then it does not matter if this one is valid as well
    if (!isValid) return false

    // if parameter is missing then it is invalid
    const parameter = conditionParameters[index]
    if (!parameter) return false

    const { name, value, type } = parameter
    // some parameter values are arrays in which case we want at least to check first value
    const normalisedValue = Array.isArray(value) ? value[0] : value
    // check that each parameter attribute is set
    if (!name || !type || normalisedValue === undefined) return false

    return true
  }, true)
}

const useValidateParameters = ({ parameterDefinitions, conditionParameters, index }: ValidateParametersArgs) => {
  const { dispatch } = useRulesContext<TacoRulesContextData>()
  useEffect(() => {
    const valid = validate(conditionParameters, parameterDefinitions)
    dispatch({ type: TacoRulesActionType.SET_PARAMETER_VALID, index, valid })
  }, [conditionParameters, parameterDefinitions, index, dispatch])
}

export default useValidateParameters
