import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { ThemeProvider } from 'emotion-theming'
import { theme } from '@11FSFoundry/figloo'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { onError } from 'apollo-link-error'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { v4 as uuid } from 'uuid'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles/toaster-theme.css'
import introspectionQueryResultData from 'fragmentTypes.json'

import { AuthProvider } from 'components/auth/Auth'
import App from 'components/App'
import * as serviceWorker from 'serviceWorker'
import { Auth0Provider } from 'components/auth/Auth0'

const { REACT_APP_CLUSTER_NAME, REACT_APP_API_URL, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } = process.env

Sentry.init({
  dsn: 'https://48b5a3586cd74e9dba494f2cdbeb5c32@sentry.io/4464578',
  environment: REACT_APP_CLUSTER_NAME
})

const authLink = setContext((_, { headers }) => {
  const authToken = window.localStorage.getItem('token')
  const traceId = uuid().split('-').slice(3).join('')
  return {
    headers: {
      ...headers,
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
      'Foundry-Trace-ID': traceId
    }
  }
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const { origin } = window.location

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ networkError, graphQLErrors, operation }) => {
      const { headers } = operation.getContext()
      const traceId = headers && headers['Foundry-Trace-ID']
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Warning)
        scope.setExtra('Foundry-Trace-ID', traceId)
        // TODO enrich with userId, not super important now as we use editor@11fs
        // but useful in the future when real people will start using it
        // scope.setUser({ id: userId })
        if (graphQLErrors && graphQLErrors.length > 0) {
          scope.setExtra(
            'graphQLErrors',
            graphQLErrors.map(err => err.message)
          )
        }
        if (operation) {
          scope.setExtra('operation', operation.operationName)
          scope.setExtra('variables', operation.variables)
        }
        if (networkError) {
          scope.setExtra('network', networkError.message)
        }
        Sentry.captureException(new Error('GraphQL error'))
      })
      // @ts-ignore
      if (networkError && networkError.statusCode === 401) {
        window.localStorage.removeItem('tokenData')
        window.localStorage.removeItem('token')
        window.location.href = '/login'
      }
    }),
    authLink,
    createUploadLink({ uri: REACT_APP_API_URL })
  ]),
  cache: new InMemoryCache({
    fragmentMatcher
  })
})

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Auth0Provider
          domain={REACT_APP_AUTH0_DOMAIN || ''}
          audience={REACT_APP_API_URL || ''}
          client_id={REACT_APP_AUTH0_CLIENT_ID || ''}
          redirect_uri={`${origin}/auth/auth0`}
          onRedirectCallback={() => {}}>
          <AuthProvider>
            <ToastContainer autoClose={2000} position="top-center" hideProgressBar closeButton={false} />
            <App />
          </AuthProvider>
        </Auth0Provider>
      </ApolloProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
