import gql from 'graphql-tag'

export const GET_USER_ACCOUNT = gql`
  query($userId: String!, $accountId: String!) {
    user(userId: $userId) {
      account(accountId: $accountId) {
        id
        state
        product {
          name
        }
        credit {
          currentRepaymentSchedule {
            params {
              asset
              invoiceFee
              accountOpeningDate
              annualNominalInterestRate
            }
            summary {
              totalPrincipal
              effectiveInterestRate
            }
          }
        }
      }
    }
  }
`

export default GET_USER_ACCOUNT
