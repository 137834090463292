import { useContext, Context } from 'react'
import RulesContext from 'components/product-configuration/rules/RulesContext'

function useRulesContext<T>() {
  const topContext = useContext(RulesContext)
  if (!topContext || !topContext.TheContext)
    throw new Error('useRulesContext must be used within a RulesContextProvider')
  const { TheContext } = topContext

  const bottomContext = useContext<T>(TheContext as Context<T>)
  if (!bottomContext)
    throw new Error('useRulesContext must be used within a TacoRulesProvider or ConditionRuleProvider')

  return bottomContext
}

export default useRulesContext
