import { Dispatch } from 'react'
import {
  ConditionAction,
  ConditionData,
  RulesSchema,
  RulesState,
  RulesContextData,
  RuleParameter,
  RuleParameterDefinition,
  ParameterActionData
} from 'components/product-configuration/rules/types'

export enum TacoRulesActionType {
  SET_RULE_NAME = 'SET_RULE_NAME',
  SET_RULE_DESCRIPTION = 'SET_RULE_DESCRIPTION',
  SET_TRIGGER_NAME = 'SET_TRIGGER_NAME',
  SET_ACTION_NAME = 'SET_ACTION_NAME',
  SET_ACTION_PARAMETER = 'SET_ACTION_PARAMETER',
  CLEAR_ACTION_PARAMETERS = 'CLEAR_ACTON_PARAMETERS',
  SET_CONDITION_LOGICAL_OPERATOR = 'SET_CONDITION_LOGICAL_OPERATOR',
  SET_PARAMETER_VALID = 'SET_PARAMETER_VALID'
}

export enum LogicalOperator {
  OR = 'OR',
  AND = 'AND'
}

interface SetRuleNameAction {
  type: TacoRulesActionType.SET_RULE_NAME
  ruleName: string
}

interface SetRuleDescriptionAction {
  type: TacoRulesActionType.SET_RULE_DESCRIPTION
  ruleDescription: string
}

interface SetTriggerNameAction {
  type: TacoRulesActionType.SET_TRIGGER_NAME
  triggerName: string
}

interface SetActionNameAction {
  type: TacoRulesActionType.SET_ACTION_NAME
  actionName: string
}

interface SetActionParamAction extends ParameterActionData {
  type: TacoRulesActionType.SET_ACTION_PARAMETER
}

interface ClearActionParamsAction {
  type: TacoRulesActionType.CLEAR_ACTION_PARAMETERS
}

interface SetParameterValidAction {
  type: TacoRulesActionType.SET_PARAMETER_VALID
  valid: boolean
  index: number
}

interface SetConditionLogicalOperator {
  type: TacoRulesActionType.SET_CONDITION_LOGICAL_OPERATOR
  value: LogicalOperator
}

export type TacoAction =
  | SetRuleNameAction
  | SetRuleDescriptionAction
  | SetTriggerNameAction
  | SetActionNameAction
  | SetActionParamAction
  | ClearActionParamsAction
  | SetParameterValidAction
  | ConditionAction
  | SetConditionLogicalOperator

export interface TacoRuleConditionData extends ConditionData {
  key: string
  parametersAreValid: boolean
}

export interface TacoRulesSchema extends RulesSchema {
  trigger: {
    name: string
    label: string
  }
  action: {
    name: string
    label: string
    parameters: RuleParameterDefinition[]
  }
}

export interface TacoRuleState extends RulesState {
  ruleName?: string
  ruleDescription?: string
  triggerName?: string
  actionName?: string
  actionParams: RuleParameter[]
  conditions: TacoRuleConditionData[]
  conditionLogicalOperator: LogicalOperator
}

export interface TacoRulesContextData extends RulesContextData {
  state: TacoRuleState
  dispatch: Dispatch<TacoAction>
}
