import React, { ReactNode, MouseEvent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Flex, Link, Button, IconArrowLeft, Heading } from '@11FSFoundry/figloo'

interface Props {
  headline?: string
  returnUrl: string
  children: ReactNode
  loading: boolean
  saveDisabled: boolean
  saveLabel?: string
  handleSave?: (event: MouseEvent) => void
  noSaveButton?: boolean
}

const MainPage = ({
  headline,
  returnUrl,
  children,
  loading,
  saveDisabled,
  saveLabel = 'Save',
  handleSave = () => {},
  noSaveButton
}: Props) => (
  <Flex flex="1">
    <Flex flexDirection="column" flex="1" mt={8}>
      <Flex flex="1" justifyContent="center">
        <Flex maxWidth="1152px" flex="1" flexDirection="column">
          {headline && (
            <Heading as="h1" variant="h1" mb={6}>
              {headline}
            </Heading>
          )}
          {children}
          <Flex py={5}>
            <Link variant="secondaryButtonLink" as={RouterLink} flex={1} to={returnUrl}>
              <IconArrowLeft /> Back
            </Link>
            {!noSaveButton && (
              <Button
                variant="primary"
                flex="1"
                ml={2}
                type="submit"
                onClick={handleSave}
                loading={loading}
                disabled={saveDisabled || loading}>
                {saveLabel}
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
)

export default MainPage
