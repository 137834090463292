import React, { MouseEvent, useContext, useState } from 'react'
import { Heading, Flex, Switch, Text } from '@11FSFoundry/figloo'
import CalculationMethodForm from 'components/product-configuration/credit/CalculationMethodForm'
import MainPage from 'components/product-configuration/MainPage'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import DecisionRule from 'components/product-configuration/rules/decision-rules/DecisionRule'
import { DecisionRuleType } from 'components/product-configuration/rules/decision-rules/types'
import useDecisionRuleHandler from 'hooks/useDecisionRuleHandler'
import { notifySuccess, notifyError } from 'components/Toast'

const CreditLimitPage = () => {
  const { product, version } = useContext(ProductVersionContext)
  const interestRateHandler = useDecisionRuleHandler(DecisionRuleType.InterestRate)

  const [loading, setLoading] = useState(false)

  const onClick = async (event: MouseEvent) => {
    event.preventDefault()

    setLoading(true)

    try {
      if (interestRateHandler.hasData) await interestRateHandler.saveDecisionRule()

      notifySuccess('Updated credit limits')
    } catch (e) {
      notifyError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const saveEnabled = interestRateHandler.editable && interestRateHandler.hasData

  return (
    <MainPage
      headline="Credit"
      handleSave={onClick}
      loading={loading}
      saveDisabled={!saveEnabled}
      returnUrl={`/products/${product.id}/versions/${version.number}`}>
      <>
        <Flex alignItems="center" mb={1}>
          <Switch checked disabled onChange={() => {}} />
          <Text mx={2}>Customer can have credit</Text>
        </Flex>
        <CalculationMethodForm />

        <Heading as="h1" variant="h1" mt={5} mb={3}>
          Interest Rates
        </Heading>

        <DecisionRule
          handler={interestRateHandler}
          newRuleLabel="+ Add new rule"
          defaultResultLabel="Use default nominal annual interest rate of"
        />
      </>
    </MainPage>
  )
}

export default CreditLimitPage
