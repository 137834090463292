import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const EDIT_RULE = gql`
  mutation($productId: String!, $versionNumber: Int!, $input: EditRuleInput!) {
    product(productId: $productId) {
      editRule(versionNumber: $versionNumber, input: $input) {
        ...product
      }
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default EDIT_RULE
