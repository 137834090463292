import { useQuery } from '@apollo/react-hooks'
import { RuleConditionOperator } from 'components/product-configuration/rules/types'
import GET_OPERATOR from './GET_OPERATOR'

const useGetOperator = (operatorInput: string) =>
  useQuery<{ definitions: {operators: RuleConditionOperator[]} }>(GET_OPERATOR, {
    variables: { operatorInput }
  })

export default useGetOperator
