import React, { useContext } from 'react'

import { InputWithValidation } from '@11FSFoundry/figloo'
import { FormContext } from 'components/product-configuration/Form'

interface Props {
  name: string
  testId?: string
}

const FiglooTextInputController = ({ name, testId }: Props) => {
  const { handleChange, getValue, getError, disabled } = useContext(FormContext)
  const value = getValue(name) || ''

  return (
    <InputWithValidation
      data-testid={testId}
      name={name}
      onChange={event => handleChange(name, event.target.value)}
      value={value}
      errorMessage={getError(name)}
      type="text"
      disabled={disabled}
    />
  )
}

export default FiglooTextInputController
