import React, { useContext } from 'react'
import { Flex, Text, Box } from '@11FSFoundry/figloo'
import AddNoteController from 'components/product-configuration/notes/AddNoteController'
import EditNoteController from 'components/product-configuration/notes/EditNoteController'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import { ProductVersionNote } from 'types'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import MainPage from 'components/product-configuration/MainPage'

const Notes = () => {
  const { product, version } = useContext(ProductVersionContext)
  const hasCreateNotesPermission = useCheckPermissions('create:notes')
  const productVersionEditable = useProductVersionEditable()
  const addNotesEnabled = hasCreateNotesPermission && productVersionEditable

  const notes = version.notes.sort((n1: ProductVersionNote, n2: ProductVersionNote) => n1.name.localeCompare(n2.name))

  return (
    <MainPage
      returnUrl={`/products/${product.id}/versions/${version.number}`}
      loading={false}
      saveDisabled={addNotesEnabled}
      noSaveButton>
      <Flex p={3}>
        <Text flex={1} style={{ fontWeight: 'bold' }}>
          Name
        </Text>
        <Text flex={2} style={{ fontWeight: 'bold' }}>
          Value
        </Text>
        <Text width={75} style={{ fontWeight: 'bold' }}>
          Public
        </Text>
        <Box flex={1} />
      </Flex>
      {addNotesEnabled && <AddNoteController />}
      {notes.map((note: ProductVersionNote) => (
        <EditNoteController key={note.name} note={note} />
      ))}
    </MainPage>
  )
}

export default Notes
