import React, { useContext, MouseEvent } from 'react'
import { Flex, Text, Box, Logo, theme } from '@11FSFoundry/figloo'
import BoxLink from 'components/product-configuration/BoxLink'
import CreditCardImage from 'images/credit-card.svg'
import AvatarImage from 'images/avatar.svg'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { NavLink } from 'react-router-dom'
import { AuthContext } from './auth/Auth'
import NavBarItem from './product-configuration/NavBarItem'

const formatName = (name: string) => name.toUpperCase().charAt(0)

export const HomePage = () => {
  const hasCreateProductPermissions = useCheckPermissions('create:products')
  const hasApproveProductVersionPermissions = useCheckPermissions('approve:product-versions')
  const hasReadAccountsPermissions = useCheckPermissions('read:accounts')
  const isEditorOrApprover = hasApproveProductVersionPermissions || hasCreateProductPermissions
  const { logout, tokenData } = useContext(AuthContext)
  return (
    <Flex flexDirection="column" flex={1}>
      <Flex py={4} bg="navBackground" justifyContent="center">
        <Flex width={1152}>
          <Box pr={6}>
            <NavLink to="/">
              <Logo type="solo-light" />
            </NavLink>
          </Box>

          {tokenData && (
            <Flex flex={1} justifyContent="flex-end" alignItems="center">
              <Box pr={3}>
                <Text css={theme.text.circle} backgroundColor={theme.colors.tagPeach}>
                  {formatName(tokenData.name)}
                </Text>
              </Box>
              <NavBarItem
                to="/"
                isActive={() => false}
                onClick={(event: MouseEvent) => {
                  event.preventDefault()
                  logout()
                }}
                testId="logout">
                Logout
              </NavBarItem>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex bg="navBackground" justifyContent="center" flex={1}>
        <Flex flexDirection="row" width={952} pt={7}>
          {isEditorOrApprover && <BoxLink to="/products" label="Products" image={CreditCardImage} />}
          {hasReadAccountsPermissions && <BoxLink to="/customers/search" label="Customers" image={AvatarImage} />}
        </Flex>
      </Flex>
    </Flex>
  )
}
export default HomePage
