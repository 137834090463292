import React, { useState, useContext, useEffect } from 'react'
import { Flex, Box, Button, FileUploader, theme } from '@11FSFoundry/figloo'
import update from 'immutability-helper'
import { notifySuccess, notifyError } from 'components/Toast'
import { ProductVersionFileInput, ProductVersionFileType, ProductVersionFile } from 'types'
import UploadingImage from 'components/product-configuration/UploadingImage'
import ExistingImage from 'components/product-configuration/ExistingImage'
import MainPage from 'components/product-configuration/MainPage'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import useAddFiles from 'gql/useAddFiles'

const VersionImages = () => {
  const hasCreateFilesPermission = useCheckPermissions('create:files')
  const productVersionEditable = useProductVersionEditable()
  const uploadImagesEnabled = hasCreateFilesPermission && productVersionEditable

  const { product, version } = useContext(ProductVersionContext)
  const [addFiles, { loading }] = useAddFiles()
  const [images, setImages] = useState<ProductVersionFileInput[]>([])
  const [highlightMissingNames, setHighlightMissingNames] = useState(false)

  const savedImages = version.files.filter(({ type }: ProductVersionFile) => type === ProductVersionFileType.Image)

  interface UpdateImageArg {
    i: number
    fieldName: string
    value: string
  }

  useEffect(() => {
    if (highlightMissingNames) setHighlightMissingNames(false)
  }, [highlightMissingNames])

  const updateImage = ({ i, fieldName, value }: UpdateImageArg) => {
    setImages(
      update(images, {
        [i]: { $merge: { [fieldName]: value } }
      })
    )
  }

  const deleteImage = (i: number) => {
    setImages(images.filter((_, index) => i !== index))
  }

  const saveImages = async () => {
    if (!images.length) {
      return notifyError('No images staged for uploading')
    }

    const hasMissingNames = images.find(({ name }) => !name)

    if (hasMissingNames) {
      notifyError('One or more images are missing names')
      setHighlightMissingNames(true)
      return
    }

    try {
      await addFiles({
        variables: { files: images, productId: product.id, versionNumber: version.number }
      })

      setImages([])
      notifySuccess('Images saved')
    } catch (error) {
      notifyError('Images saving failed')
    }
  }

  return (
    <MainPage
      headline="Product images"
      saveLabel="Save"
      handleSave={saveImages}
      returnUrl={`/products/${product.id}/versions/${version.number}`}
      loading={loading}
      saveDisabled={!uploadImagesEnabled}>
      <Flex flexWrap="wrap" mr={-3}>
        {savedImages.map((image: ProductVersionFile) => (
          <ExistingImage key={image.id} image={image} />
        ))}
        {images.map((image, i) => (
          <UploadingImage
            // eslint-disable-next-line react/no-array-index-key
            key={`${image.file.name}${i}`}
            image={image}
            deleteImage={() => deleteImage(i)}
            highlightMissingNames={highlightMissingNames}
            updateImage={(fieldName: string, value: string) => updateImage({ i, fieldName, value })}
          />
        ))}
        {uploadImagesEnabled && (
          <Box>
            <FileUploader
              multiple
              onChange={fileList => {
                const newImages = Array.from(fileList).map(file => ({
                  file,
                  name: '',
                  type: ProductVersionFileType.Image
                }))

                setImages(images.concat(newImages))
              }}>
              <Flex
                width={365}
                height={350}
                justifyContent="center"
                alignItems="center"
                backgroundColor="rgba(255,255,255,0.4)"
                sx={{ border: `3px dashed ${theme.colors.gsRat}`, cursor: 'pointer' }}>
                <Button as="div">+ Upload image</Button>
              </Flex>
            </FileUploader>
          </Box>
        )}
      </Flex>
    </MainPage>
  )
}

export default VersionImages
