import React, { createContext, ReactNode, FormEvent } from 'react'
import useFormHandler, { ValidationFunction } from 'hooks/useFormHandler'

interface Props {
  children: ReactNode
  completeSubmit: (values: Record<string, unknown>) => void
  initialData: Record<string, unknown>
  fields: string[]
  disabled?: boolean
}

interface FormOutput {
  handleChange: (name: string, value: unknown) => void
  handleSubmit: (event: FormEvent) => void
  getValue: (name: string) => unknown
  getError: (name: string) => string
  registerValidationFunction: (name: string, validationFunction: ValidationFunction<unknown>) => void
  evictValidationFunction: (name: string) => void
  validate: () => boolean
  disabled?: boolean
}

export const FormContext = createContext<FormOutput>({
  handleChange: () => {},
  handleSubmit: () => {},
  getValue: () => {},
  getError: () => '',
  evictValidationFunction: () => {},
  registerValidationFunction: () => {},
  validate: () => true,
  disabled: false
})

export function FormProvider({ children, completeSubmit, initialData, disabled }: Props) {
  const {
    handleChange,
    handleSubmit,
    getValue,
    getError,
    validate,
    registerValidationFunction,
    evictValidationFunction
  } = useFormHandler(initialData, completeSubmit)

  return (
    <FormContext.Provider
      value={{
        handleChange,
        handleSubmit,
        getValue,
        validate,
        getError,
        registerValidationFunction,
        evictValidationFunction,
        disabled
      }}>
      {children}
    </FormContext.Provider>
  )
}
