import React from 'react'
import { Flex } from '@11FSFoundry/figloo'
import { ProductVersion, VersionStatus } from 'types/index'
import MainPage from './MainPage'
import ApplicationBlock from './ApplicationBlock'

interface Props {
  product: {
    id: string
    name: string
    versions: ProductVersion[]
  }
}

const ApplicationsList = ({ product }: Props) => {
  const openVersion = product.versions.find(({ status }) => status === 'APPLICATIONS_OPEN')
  const currentLiveVersion = openVersion ? openVersion.number : null

  const isApprovedOrOpenOrPaused = (status: VersionStatus) => {
    return ['APPROVED', 'APPLICATIONS_OPEN', 'APPLICATIONS_PAUSED'].includes(status)
  }

  return (
    <MainPage
      headline="Applications"
      returnUrl={`/products/${product.id}`}
      loading={false}
      saveDisabled={false}
      noSaveButton>
      <Flex justifyContent="center">
        <Flex flexDirection="column" my={6} width={1152} data-testid="versionList">
          {product.versions
            .sort((a: { number: number }, b: { number: number }) => b.number - a.number)
            .filter(({ status }) => isApprovedOrOpenOrPaused(status))
            .map((version: ProductVersion) => {
              return (
                <ApplicationBlock
                  key={version.number}
                  version={version}
                  currentLiveVersion={currentLiveVersion}
                  productId={product.id}
                />
              )
            })}
        </Flex>
      </Flex>
    </MainPage>
  )
}
export default ApplicationsList
