import React from 'react'
import { Flex, Text } from '@11FSFoundry/figloo'
import {
  TacoRulesSchema,
  TacoRulesContextData,
  TacoRulesActionType
} from 'components/product-configuration/rules/taco-rules/types'
import useRulesContext from 'components/product-configuration/rules/useRulesContext'
import Parameters from 'components/product-configuration/rules/Parameters'

interface ActionParams {
  disabled?: boolean
}

interface Props extends ActionParams {
  schemaItem: TacoRulesSchema
}

const RuleAction = ({ schemaItem, disabled }: Props) => {
  const { state, dispatch } = useRulesContext<TacoRulesContextData>()
  const { actionParams } = state

  return (
    <Flex backgroundColor="white" py={3} px={4} mt={2} display="row" alignItems="center">
      <Text mr={3} minWidth={60}>
        THEN
      </Text>
      <Text mr={3}>{schemaItem.action.label}</Text>
      <Parameters
        parameterDefinitions={schemaItem.action.parameters}
        parameters={actionParams}
        disabled={disabled}
        handleChange={param => {
          dispatch({
            type: TacoRulesActionType.SET_ACTION_PARAMETER,
            ...param
          })
        }}
      />
    </Flex>
  )
}

export default RuleAction
