import React, { useContext } from 'react'
import { Flex, Heading, Text, Select, Box } from '@11FSFoundry/figloo'
import { notifyError } from 'components/Toast'
import MainPage from 'components/product-configuration/MainPage'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import TacoRuleConditions from 'components/product-configuration/rules/taco-rules/TacoRuleConditions'
import ClickEdit from 'components/product-configuration/ClickEdit'
import { ProductRuleInput } from 'components/product-configuration/rules/types'
import RuleAction from 'components/product-configuration/rules/taco-rules/RuleAction'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import {
  TacoRulesSchema,
  TacoRuleConditionData,
  TacoRulesContextData,
  TacoRulesActionType
} from 'components/product-configuration/rules/taco-rules/types'
import { ruleToRuleInput } from 'components/product-configuration/rules/taco-rules/format'
import useRulesContext from 'components/product-configuration/rules/useRulesContext'

interface ValidationArgs {
  ruleName?: string
  triggerName?: string
  conditions: TacoRuleConditionData[]
  actionName?: string
  schemaItem?: TacoRulesSchema
}

const validateTacoRule = ({ ruleName, triggerName, conditions, actionName, schemaItem }: ValidationArgs) => {
  if (!triggerName) return 'No trigger selected'

  // TODO Add validation for parameters
  const hasMissingConditionData = conditions.some((condition: TacoRuleConditionData) => {
    const { conditionGroup, conditionProperty, conditionOperator, parametersAreValid } = condition
    // if entire condition is blank then it's no data is considered missing
    if (!conditionGroup && !conditionProperty && !conditionOperator && !parametersAreValid) return false

    if (!conditionProperty) return true

    if (!conditionOperator) return true

    if (!parametersAreValid) return true

    return false
  })

  if (!ruleName) return 'No rule name specified'

  if (hasMissingConditionData) return 'Some condition parameters missing'

  if (!actionName) return 'No action selected'

  if (!schemaItem) return 'No schema selected'
}

interface Props {
  schema: TacoRulesSchema[]
  saveRule: (rule: ProductRuleInput) => void
  loading: boolean
}

const TacoRuleEditor = ({ schema, saveRule, loading }: Props) => {
  const { product, version } = useContext(ProductVersionContext)
  const { state, dispatch } = useRulesContext<TacoRulesContextData>()

  const hasChangeRulesPermission = useCheckPermissions('update:rules')
  const productVersionEditable = useProductVersionEditable()
  const disabled = !hasChangeRulesPermission || !productVersionEditable

  const {
    conditions,
    ruleName,
    ruleDescription,
    triggerName,
    actionName,
    actionParams,
    conditionLogicalOperator
  } = state

  const schemaItem = schema.find(({ action, trigger }) => action.name === actionName && trigger.name === triggerName)

  const handleSave = () => {
    const validationMessage = validateTacoRule({ ruleName, triggerName, conditions, actionName, schemaItem })

    if (validationMessage) notifyError(validationMessage)

    const newConditions = conditions.filter(
      (condition: TacoRuleConditionData) =>
        condition.conditionGroup && condition.conditionProperty && condition.conditionOperator
    )

    try {
      const currentRule = ruleToRuleInput({
        ruleName,
        ruleDescription,
        triggerName,
        conditions: newConditions,
        actionName,
        actionParams,
        conditionLogicalOperator
      })

      saveRule(currentRule)
    } catch (error) {
      notifyError('Saving rule failed')
    }
  }

  return (
    <MainPage
      saveLabel="Save"
      returnUrl={`/products/${product.id}/versions/${version.number}`}
      loading={loading}
      saveDisabled={disabled}
      handleSave={handleSave}>
      <Box>
        <ClickEdit
          data-testid="editRuleName"
          handleChange={newRuleName => dispatch({ type: TacoRulesActionType.SET_RULE_NAME, ruleName: newRuleName })}
          value={ruleName}
          disabled={disabled}>
          <Heading variant="h1" as="h1" color="copyOne">
            {ruleName || 'Untitled rule'}
          </Heading>
        </ClickEdit>
      </Box>

      <Box my={2}>
        <ClickEdit
          data-testid="editRuleDescription"
          handleChange={newRuleDescription =>
            dispatch({ type: TacoRulesActionType.SET_RULE_DESCRIPTION, ruleDescription: newRuleDescription })
          }
          value={ruleDescription}
          displayAsTextarea
          disabled={disabled}>
          <Text color="copyOne">{ruleDescription || 'Add description'}</Text>
        </ClickEdit>
      </Box>
      <Flex backgroundColor="white" py={3} px={4} mt={6} alignItems="center">
        <Text mr={3} minWidth={60}>
          WHEN
        </Text>

        <Select
          data-testid="editRuleTrigger"
          disabled={disabled}
          value={`${triggerName}:${actionName}`}
          onChange={({ target }) => {
            const newSchemaItem = schema.find(
              ({ action, trigger }) => `${trigger.name}:${action.name}` === target.value
            )
            if (newSchemaItem) {
              dispatch({ type: TacoRulesActionType.SET_TRIGGER_NAME, triggerName: newSchemaItem.trigger.name })
              dispatch({ type: TacoRulesActionType.SET_ACTION_NAME, actionName: newSchemaItem.action.name })
              dispatch({ type: TacoRulesActionType.CLEAR_ACTION_PARAMETERS })
            }
          }}>
          <option value="">Please select...</option>
          {schema.map(({ action, trigger }) => (
            <option key={`${trigger.name}:${action.name}`} value={`${trigger.name}:${action.name}`}>
              {trigger.label}
            </option>
          ))}
        </Select>
      </Flex>

      {schemaItem && (
        <TacoRuleConditions data-testid="editRuleConditions" schemaItem={schemaItem} disabled={disabled} />
      )}
      {schemaItem && <RuleAction schemaItem={schemaItem} disabled={disabled} />}
    </MainPage>
  )
}

export default TacoRuleEditor
