import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'

const useDecisionRuleEditable = (newRule: boolean) => {
  const hasCreateRulesPermission = useCheckPermissions('create:decision-rules')
  const hasUpdateRulesPermission = useCheckPermissions('update:decision-rules')

  const productVersionEditable = useProductVersionEditable()

  if (!productVersionEditable) return false
  if (newRule) return hasCreateRulesPermission
  return hasUpdateRulesPermission
}

export default useDecisionRuleEditable
