import React from 'react'
import { Flex } from '@11FSFoundry/figloo'
import { DecisionRuleData } from 'components/product-configuration/rules/decision-rules/types'
import { stringifyDecisionRule } from 'components/product-configuration/audit/ruleFormatting'

interface Props {
  rule: DecisionRuleData
}

const DecisionRuleSummary = ({ rule }: Props) => {
  const stringifiedRule = stringifyDecisionRule(rule)

  return (
    <Flex flexDirection="column">
      {stringifiedRule.map(ruleString => (
        <Flex>{ruleString}</Flex>
      ))}
    </Flex>
  )
}

export default DecisionRuleSummary
