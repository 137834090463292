import React, { useContext } from 'react'
import { OauthReceiver } from 'react-oauth-flow'
import { Token } from 'types'
import { AuthContext } from 'components/auth/Auth'
import JwtTools from 'tools/JwtTools'

const FoundryOAuthReceiver = () => {
  const { loginSuccess } = useContext(AuthContext)

  const handleSuccess = (_accessToken: string, { response }: { response: { json: () => Promise<Token> } }) => {
    response.json().then(token => {
      const jwt = JwtTools.parseJwt(token.id_token)
      const { permissions } = JwtTools.parseJwt(token.access_token)

      const userInfo = {
        dateOfBirth: undefined,
        name: jwt.name || '',
        photo: jwt.picture || '',
        email: jwt.email || '',
        externalUserId: jwt.sub,
        tokenId: token.access_token,
        permissions,
        source: 'foundryauth' as 'foundryauth'
      }

      loginSuccess(userInfo)
    })
  }

  const handleError = (error: Error) => {
    console.error('An error occured')
    console.error(error.message)
  }

  const tokenFn = (url: string, fetchArgs: RequestInit) => fetch(url, fetchArgs)

  const { origin } = window.location

  return (
    <div className="oauth-foundry">
      <div className="oauth-foundry__button-container">
        <OauthReceiver
          tokenUrl={process.env.REACT_APP_FOUNDRY_OAUTH_TOKEN_URL}
          clientId={process.env.REACT_APP_FOUNDRY_OAUTH_CLIENT_ID}
          clientSecret={process.env.REACT_APP_FOUNDRY_OAUTH_CLIENT_SECRET}
          redirectUri={`${origin}/auth/foundry`}
          onAuthSuccess={handleSuccess}
          onAuthError={handleError}
          tokenFn={tokenFn}
          render={({ processing, error }: { processing: unknown; error: Error }) => (
            <div>
              {processing && <p>Authorizing...</p>}
              {error && <p className="error">An error occurred: {error.message}</p>}
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default FoundryOAuthReceiver
