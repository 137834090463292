import { Box, Select } from '@11FSFoundry/figloo'
import Parameters from 'components/product-configuration/rules/Parameters'
import {
  ParameterActionData,
  RuleParameter,
  ValidateParametersArgs
} from 'components/product-configuration/rules/types'
import useGetOperator from 'gql/useGetOperator'
import React from 'react'

interface Props {
  operatorInput: string
  conditionOperator: string | undefined
  conditionParameters: RuleParameter[]
  index: number
  disabled?: boolean
  setOperator: (operator: string) => void
  setParameter: (paramActionData: ParameterActionData) => void
  useValidateParameters: (args: ValidateParametersArgs) => void
}

const OperatorController = ({
  operatorInput,
  conditionOperator,
  conditionParameters,
  disabled,
  index,
  setOperator,
  setParameter,
  useValidateParameters
}: Props) => {
  const { data } = useGetOperator(operatorInput)
  const selectedPropertyOperator = data && data.definitions.operators.find(({ name }) => name === conditionOperator)
  const parameterDefinitions = selectedPropertyOperator?.parameters

  useValidateParameters({ parameterDefinitions, conditionParameters, index })

  return (
    <>
      {data && (
        <Box mr={3} py={1}>
          {data.definitions.operators.length ? (
            <Select
              data-testid={`conditionOperatorSelector-${index}`}
              disabled={disabled}
              value={conditionOperator}
              onChange={({ target }) => setOperator(target.value)}>
              <option value="">Please select...</option>
              {data.definitions.operators.map(({ name, label }) => (
                <option value={name} key={name}>
                  {label}
                </option>
              ))}
            </Select>
          ) : (
            <Box>No operators found</Box>
          )}
        </Box>
      )}
      {parameterDefinitions && (
        <Parameters
          key={conditionOperator}
          parameterDefinitions={parameterDefinitions}
          conditionIndex={index}
          parameters={conditionParameters}
          handleChange={setParameter}
          disabled={disabled}
          testLabel="parameter"
        />
      )}
    </>
  )
}

export default OperatorController
