import React, { createContext, ReactNode } from 'react'
import { ProductVersion } from 'types'

interface ProductVersionThing {
  product: {
    id: string
    name: string
    versions: ProductVersion[]
  }
  version: ProductVersion
  refetch: () => {}
}

export const ProductVersionContext = createContext<ProductVersionThing>({
  // @ts-ignore
  product: undefined,
  // @ts-ignore
  version: undefined,
  // @ts-ignore
  refetch: () => {}
})

interface Props extends ProductVersionThing {
  children: ReactNode
}

export const ProductVersionProvider = ({ product, version, refetch, children }: Props) => (
  <ProductVersionContext.Provider value={{ product, version, refetch }}>{children}</ProductVersionContext.Provider>
)
