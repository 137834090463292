import React, { useState } from 'react'
import { Button, Flex, Text, Box, theme, IconBin, IconDownload, Link } from '@11FSFoundry/figloo'
import { ProductVersionFile } from 'types'
import { documentDownloadURL, useAuthenticatedDownload } from 'hooks/useAuthenticatedDownload'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

import ClickEdit from 'components/product-configuration/ClickEdit'
import Confirmation from 'components/product-configuration/Confirmation'

interface Props {
  file: ProductVersionFile
  deleteApproval: (fileId: string) => void
  edit: (newDescription: string) => void
}

const ApprovalDocument = ({ file, deleteApproval, edit }: Props) => {
  const hasDeleteFilesPermission = useCheckPermissions('delete:files')
  const hasUpdateFilesPermission = useCheckPermissions('update:files')
  const productVersionEditable = useProductVersionEditable()
  const deleteApprovalEnabled = hasDeleteFilesPermission && productVersionEditable
  const editApprovalDisabled = !hasUpdateFilesPermission || !productVersionEditable

  const descriptionPlaceholder = 'Enter optional description'
  const [description, setDescription] = useState(file.description ? file.description : '')
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  const authenticatedDownload = useAuthenticatedDownload()

  const handleDescriptionChanged = () => {
    if (description !== file.description) {
      edit(description)
    }
  }

  const descriptionEmpty = !description && !file.description

  return (
    <Flex key={file.id} m={1} p={1} backgroundColor={theme.colors.white}>
      <Box p={2}>
        <Link onClick={() => authenticatedDownload(documentDownloadURL(file.url), file.fileName)}>
          <IconDownload />
        </Link>
      </Box>
      <Flex flexDirection="column" flex={1}>
        <Box>
          <Text m={1}>{file.name}</Text>
        </Box>
        {confirmingDelete ? (
          <Confirmation
            setConfirming={setConfirmingDelete}
            handleConfirm={() => {
              setConfirmingDelete(false)
              deleteApproval(file.id)
            }}>
            <Text>Are you sure you want to delete this approval document?</Text>
          </Confirmation>
        ) : (
          <Flex p={1}>
            <Box flex={1} ml={3}>
              <ClickEdit
                data-testid="editApprovalDocumentDescription"
                value={description}
                handleChange={value => setDescription(value)}
                handleCommit={handleDescriptionChanged}
                disabled={editApprovalDisabled}>
                <Text css={{ fontStyle: descriptionEmpty ? 'italic' : 'none' }}>
                  {!description ? descriptionPlaceholder : description}
                </Text>
              </ClickEdit>
            </Box>
            {deleteApprovalEnabled && (
              <Button
                data-testid="deleteApprovalDocument"
                variant="icon"
                as="div"
                p={0}
                onClick={() => setConfirmingDelete(true)}>
                <IconBin color="cherry" />
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default ApprovalDocument
