import React from 'react'
import { Link, Flex, Heading, IconAdd, theme, Text } from '@11FSFoundry/figloo'
import { Link as RouterLink } from 'react-router-dom'
import Page from 'components/Page'
import PageTitle from 'components/PageTitle'
import ProductListLoader from 'components/product-configuration/ProductListLoader'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

const ProductGallery = () => {
  const hasCreateProductPermission = useCheckPermissions('create:products')
  return (
    <Page>
      <Flex flex={1} flexDirection="column" alignItems="center">
        <PageTitle>
          <Heading variant="h1" as="h1" my={0}>
            Products
          </Heading>
          {hasCreateProductPermission && (
            <Flex justifyContent="flex-start" alignItems="center">
              <Link variant="secondaryButtonLink" as={RouterLink} to="/products/create">
                <Flex alignItems="center" mx={2}>
                  <IconAdd color="copyOne" size={theme.fontSizes[1]} />
                  <Text ml={1} variant="default" fontFamily="heavy" fontSize={1}>
                    Create new product
                  </Text>
                </Flex>
              </Link>
            </Flex>
          )}
        </PageTitle>
        <Flex flexDirection="column" width={1152}>
          <ProductListLoader />
        </Flex>
      </Flex>
    </Page>
  )
}
export default ProductGallery
