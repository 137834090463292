import React from 'react'
import useValidationSubscription from 'hooks/useValidationSubscription'
import { ValidationResult, Valid, Invalid } from 'hooks/useFormHandler'
import FiglooSelectController from 'components/product-configuration/FiglooSelectController'

const ProductAssetInput = () => {
  const { REACT_APP_ENABLED_CURRENCIES = 'gbp' } = process.env
  const enabledCurrencies = REACT_APP_ENABLED_CURRENCIES.split(',')

  const name = 'productAsset'

  const validate = (formValues: Record<string, unknown>): ValidationResult => {
    return formValues[name] ? Valid : Invalid('Please select an asset type to use for this product')
  }

  useValidationSubscription(name, validate)

  return (
    <FiglooSelectController name={name} testId="currency-select">
      <option disabled value="">
        Select your currency
      </option>
      {enabledCurrencies.map(currency => (
        <option key={currency.toLowerCase()} value={currency.toLowerCase()}>
          {currency.toUpperCase()}
        </option>
      ))}
    </FiglooSelectController>
  )
}

export default ProductAssetInput
