import React, { useState, ChangeEvent } from 'react'
import { CheckboxField, Flex, Text, Button, IconBin } from '@11FSFoundry/figloo'
import { ProductVersionNote } from 'types'
import ClickEdit from 'components/product-configuration/ClickEdit'
import RemoveNoteController from 'components/product-configuration/notes/RemoveNoteController'
import removeObjectProperty from 'util/removeObjectProperty'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

interface Props {
  note: ProductVersionNote
  editNote: (updatedNote: ProductVersionNote) => void
}

const Note = ({ note, editNote }: Props) => {
  const hasUpdateNotesPermission = useCheckPermissions('update:notes')
  const hasDeleteNotesPermission = useCheckPermissions('delete:notes')
  const productVersionEditable = useProductVersionEditable()
  const updateNotesDisabled = !hasUpdateNotesPermission || !productVersionEditable
  const deleteNotesDisabled = !hasDeleteNotesPermission || !productVersionEditable

  const [confirming, setConfirming] = useState(false)
  const [updatedNote, setUpdatedNote] = useState<ProductVersionNote>(removeObjectProperty(note) as ProductVersionNote)
  const [savingValue, setSavingValue] = useState(false)
  const [savingVisibility, setSavingVisibility] = useState(false)

  const renderNote = () => (
    <>
      <Flex flex={2}>
        <ClickEdit
          data-testid="editNoteValue"
          handleChange={value => setUpdatedNote({ ...updatedNote, value })}
          handleCommit={() => {
            setSavingValue(true)
            editNote(updatedNote)
            setSavingValue(false)
          }}
          saving={savingValue}
          value={updatedNote.value}
          disabled={updateNotesDisabled}>
          <Text>{updatedNote.value}</Text>
        </ClickEdit>
      </Flex>
      <Flex width={75} justifyContent="center">
        <CheckboxField
          data-testid="editNoteVisibility"
          disabled={updateNotesDisabled}
          saving={savingVisibility}
          checked={updatedNote.visibility === 'PUBLIC'}
          onChange={async (event: ChangeEvent<HTMLInputElement>) => {
            setSavingVisibility(true)

            const newNote = {
              ...updatedNote,
              visibility: event.target.checked ? ('PUBLIC' as 'PUBLIC') : ('ADMIN' as 'ADMIN')
            }

            editNote(newNote)
            setUpdatedNote(newNote)
            setSavingVisibility(false)
          }}
        />
      </Flex>
      <Flex flex={1} justifyContent="flex-end">
        <Button
          data-testid="deleteNote"
          onClick={() => setConfirming(true)}
          variant="icon"
          disabled={deleteNotesDisabled}>
          <IconBin />
        </Button>
      </Flex>
    </>
  )

  return (
    <Flex p={3} alignItems="center" backgroundColor="white" mb={1}>
      <Text flex={1}>{note.name}</Text>
      {confirming ? <RemoveNoteController noteName={note.name} setConfirming={setConfirming} /> : renderNote()}
    </Flex>
  )
}

export default Note
