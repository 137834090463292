import React, { useContext, ReactNode, Fragment } from 'react'
import { VersionStatus } from 'types'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'

interface Props {
  authorise: ({ versionStatus }: { versionStatus: VersionStatus }) => boolean
  children: ReactNode[] | ReactNode | string
}

const Authoriser = ({ authorise, children }: Props) => {
  const { version } = useContext(ProductVersionContext)
  const { status: versionStatus } = version

  const authorised = authorise({ versionStatus })

  return authorised ? <Fragment>{children}</Fragment> : null
}

export default Authoriser
