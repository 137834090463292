import gql from 'graphql-tag'

const CREATE_PRODUCT = gql`
  mutation createNewProduct($name: String!, $description: String!) {
    createNewProduct(name: $name, description: $description) {
      id
    }
  }
`

export default CREATE_PRODUCT
