import React, { ReactNode } from 'react'

import { Text, Flex } from '@11FSFoundry/figloo'

interface Props {
  label: string
  children: ReactNode
  secondaryContent?: string
}

const Info = ({ label, children, secondaryContent }: Props) => (
  <Flex flexDirection="column">
    <Text fontSize={16} lineHeight="1.5rem">
      {label}
    </Text>
    <Text fontSize={24} lineHeight="2rem">
      {children}
    </Text>
    {secondaryContent && (
      <Text fontSize={16} lineHeight="1.5rem">
        {secondaryContent}
      </Text>
    )}
  </Flex>
)

export default Info
