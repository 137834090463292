import {
  TacoRuleState,
  TacoRuleConditionData,
  LogicalOperator
} from 'components/product-configuration/rules/taco-rules/types'
import { ProductRuleInput } from 'components/product-configuration/rules/types'
import { conditionParameterToInput, conditionToSingleInput } from 'components/product-configuration/rules/format'

const getLogicalOperatorKey = (logicalOperator: LogicalOperator) => {
  switch (logicalOperator) {
    case LogicalOperator.OR:
      return 'or'
    case LogicalOperator.AND:
      return 'and'
    default:
      throw new Error(`${logicalOperator} is not a known condition logical type`)
  }
}

export const ruleToRuleInput = (rule: TacoRuleState): ProductRuleInput => {
  const {
    ruleName,
    ruleDescription,
    triggerName,
    conditions,
    actionName,
    actionParams,
    conditionLogicalOperator
  } = rule
  const newConditions = conditions.map((condition: TacoRuleConditionData) => ({
    single: conditionToSingleInput(condition)
  }))

  const logicalOperatorKey = getLogicalOperatorKey(conditionLogicalOperator)

  return {
    name: ruleName as string,
    description: ruleDescription,
    trigger: {
      name: triggerName as string
    },
    condition: {
      [logicalOperatorKey]: {
        conditions: newConditions
      }
    },
    action: {
      name: actionName as string,
      parameters: actionParams.map(conditionParameterToInput)
    }
  }
}
