import React, { useState, useContext } from 'react'
import { Box, Button, Flex, Text } from '@11FSFoundry/figloo'
import update from 'immutability-helper'
import { notifySuccess, notifyError } from 'components/Toast'
import useAddFiles from 'gql/useAddFiles'
import { ProductVersionFile, ProductVersionFileInput, ProductVersionFileType } from 'types'
import DocumentToUpload from 'components/product-configuration/documents/DocumentToUpload'
import ExistingDocument from 'components/product-configuration/documents/ExistingDocument'
import UploadPanel from 'components/product-configuration/documents/UploadPanel'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import MainPage from 'components/product-configuration/MainPage'

const VersionDocuments = () => {
  const { version, product } = useContext(ProductVersionContext)
  const documents = version.files.filter((f: ProductVersionFile) => f.type === ProductVersionFileType.Document)

  const hasCreateFilesPermission = useCheckPermissions('create:files')
  const productVersionEditable = useProductVersionEditable()
  const attachDocumentEnabled = hasCreateFilesPermission && productVersionEditable

  const [documentsToUpload, setDocumentsToUpload] = useState<ProductVersionFileInput[]>([])

  const handleFileUpload = (files: FileList) =>
    setDocumentsToUpload(
      documentsToUpload.concat(
        Array.from(files).map(file => ({
          file,
          name: '',
          type: ProductVersionFileType.Document
        }))
      )
    )

  // update the `i`th entry in the documents array to set the
  // specified metadata field to the value from the event
  const updateDocumentsToUpload = (i: number, field: string, value: unknown) =>
    setDocumentsToUpload(
      update(documentsToUpload, {
        [i]: { $merge: { [field]: value } }
      })
    )

  const removeDocumentToUpload = (i: number) => setDocumentsToUpload(update(documentsToUpload, { $splice: [[i, 1]] }))

  const [addFilesMutation, { loading }] = useAddFiles()

  const upload = async () => {
    try {
      await addFilesMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          files: documentsToUpload
        }
      })

      setDocumentsToUpload([])

      notifySuccess('Document uploaded')
    } catch (e) {
      notifyError('Uploading document failed')
    }
  }

  return (
    <MainPage
      headline="Documents"
      returnUrl={`/products/${product.id}/versions/${version.number}`}
      loading={false}
      saveDisabled={attachDocumentEnabled}
      noSaveButton>
      <Flex alignItems="center" mb={4}>
        <Box px={32} mr={38 /* add 38px to simulate width of svg */} />
        <Text flex={1} pl={40} pr={24} style={{ fontWeight: 'bold' }}>
          Reference
        </Text>
        <Text flex={2} style={{ fontWeight: 'bold' }}>
          Original filename
        </Text>
        <Text flex={1} style={{ fontWeight: 'bold' }}>
          Type
        </Text>
      </Flex>
      {attachDocumentEnabled && (
        <Flex
          height={88}
          alignItems="center"
          backgroundColor="rgba(255,255,255,0.4)"
          sx={{ border: '3px dashed #dcdcdc' }}
          mb={1}
          px={32}>
          <UploadPanel handleFileUpload={handleFileUpload} />
        </Flex>
      )}
      {documentsToUpload.map((doc, i) => (
        <DocumentToUpload
          doc={doc}
          key={doc.file.name}
          handleNameChange={newName => updateDocumentsToUpload(i, 'name', newName)}
          handleCancelClick={() => removeDocumentToUpload(i)}
        />
      ))}
      {attachDocumentEnabled && documentsToUpload.length > 0 && (
        <Flex mt={16} mb={64} justifyContent="flex-end">
          <Button
            width={256}
            variant="primary"
            type="submit"
            loading={loading}
            disabled={documentsToUpload.length === 0 || documentsToUpload.some(doc => !doc.name)}
            onClick={upload}>
            Upload all
          </Button>
        </Flex>
      )}
      {documents.map((doc: ProductVersionFile) => (
        <ExistingDocument doc={doc} key={doc.id} />
      ))}
    </MainPage>
  )
}

export default VersionDocuments
