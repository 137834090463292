import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const ADD_ATTRIBUTES = gql`
  mutation($productId: String!, $versionNumber: Int!, $attributes: ProductAttributes!) {
    addAttributesToProductVersion(productId: $productId, versionNumber: $versionNumber, attributes: $attributes) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default ADD_ATTRIBUTES
