import {
  ConditionAction,
  ConditionData,
  ParameterActionData,
  RuleParameterInputWithLabelAndUnit,
  RuleParameterInput,
  RuleParameterDefinition,
  RuleConditionInputSingle,
  RuleParameter,
  RuleConditionSingle,
  RulesSchema,
  RulesState
} from 'components/product-configuration/rules/types'

export interface DecisionRuleCondition extends ConditionData {
  key: string
  result: RuleParameter[]
}

export enum DecisionRuleActionType {
  REORDER = 'REORDER',
  SET_RESULT = 'SET_RESULT',
  SET_DEFAULT_RESULT = 'SET_DEFAULT_RESULT'
}

export enum DecisionRuleSpecIndex {
  CREDIT_INTEREST_RATE = 0,
  CREDIT_LIMIT = 1
}

interface ReorderAction {
  type: DecisionRuleActionType.REORDER
  oldIndex: number
  newIndex: number
}

interface SetResultAction extends ParameterActionData {
  type: DecisionRuleActionType.SET_RESULT
  conditionIndex: number
}

interface SetDefaultResultAction extends ParameterActionData {
  type: DecisionRuleActionType.SET_DEFAULT_RESULT
}

// Schemas for the different types of decision rule are predefined in GraphQL schema.
// Each one has a unique name and associated fields, properties, operators, etc.
// These unique rule identifiers are also defined as the values in this enum.
// One of these must be provided when instantiating a DecisionRule so the
//  corresponding schema can be retrieved. The current decision rule names
// can be found with this query:
//     query {
//       decisionRule {
//         name
//       }
//     }
export enum DecisionRuleType {
  InterestRate = 'interestRate',
  CreditLimit = 'creditLimit',
  AccountOpeningFee = 'accountOpeningFee',
  InvoiceFee = 'invoiceFee'
}

export interface DefinitionsSchema extends RulesSchema {
  __typename: 'Definitions'
  decisionRules: DecisionRulesSchema[]
}

export interface DecisionRulesSchema extends RulesSchema {
  __typename: 'DecisionRuleDefinition'
  name: DecisionRuleType
  result: {
    __typename: 'DecisionRuleResultDefinition'
    parameters: RuleParameterDefinition[]
  }
}

export type DecisionRuleAction = ReorderAction | SetResultAction | ConditionAction | SetDefaultResultAction

export interface DecisionRuleState extends RulesState {
  conditions: DecisionRuleCondition[]
  defaultResult: RuleParameter[]
}

export interface DecisionRuleInput {
  name: string
  rule: {
    steps: {
      condition: {
        single: RuleConditionInputSingle
      }
      result: {
        parameters: RuleParameterInput[]
      }
    }[]
    defaultResult: {
      parameters: RuleParameterInput[]
    }
  }
}

export interface DecisionRuleData {
  name: DecisionRuleType
  label: string
  steps: {
    condition: RuleConditionSingle
    result: {
      parameters: RuleParameterInputWithLabelAndUnit[]
    }
  }[]
  defaultResult: {
    parameters: RuleParameterInputWithLabelAndUnit[]
  }
}

export interface DecisionRuleVariables {
  productId: string
  versionNumber: number
  input: DecisionRuleInput
}
