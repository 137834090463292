import { Button, Flex } from '@11FSFoundry/figloo'
import React, { ReactNode, MouseEvent } from 'react'

interface Props {
  children: ReactNode
  handleConfirm: (e: MouseEvent) => void
  setConfirming: (confirming: boolean) => void
  disabled?: boolean
  vertical?: boolean
  flipped?: boolean
}

const Confirmation = ({ handleConfirm, setConfirming, disabled, children, vertical, flipped }: Props) => {
  const props = vertical
    ? {
        flexDirection: 'column' as 'column',
        justifyContent: 'space-around' as 'space-around'
      }
    : {
        alignItems: 'center' as 'center',
        justifyContent: 'flex-end' as 'flex-end'
      }

  const buttonFlexProps = vertical
    ? {
        justifyContent: 'space-around' as 'space-around'
      }
    : {}

  const buttonProps = vertical
    ? {
        flex: 1
      }
    : {}

  const flippedButtonProps = flipped
    ? {
        order: 1
      }
    : {}

  return (
    <Flex flex={1} {...props}>
      {children}
      <Flex alignItems="center" {...buttonFlexProps}>
        <Button
          {...flippedButtonProps}
          ml={1}
          variant="danger"
          onClick={handleConfirm}
          disabled={disabled}
          {...buttonProps}>
          Delete
        </Button>
        <Button
          ml={1}
          variant="secondary"
          onClick={e => {
            e.preventDefault()
            setConfirming(false)
          }}
          disabled={disabled}
          {...buttonProps}>
          Cancel
        </Button>
      </Flex>
    </Flex>
  )
}

export default Confirmation
