import React, { useState, useContext } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import { useHistory } from 'react-router-dom'
import { ApproverItem, ProductVersionFile, ProductVersionFileType } from 'types'
import SubmitVersionForReview from 'components/product-configuration/approval/SubmitVersionForReview'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import useAddFiles from 'gql/useAddFiles'
import useDeleteFile from 'gql/useDeleteFile'
import useEditFile from 'gql/useEditFile'
import useRequestReview from 'gql/useRequestReview'

const getApprovers = (approversString: string | undefined) => {
  if (!approversString) return []

  const approverPairs = approversString.split(',')
  if (!approverPairs.length) return []

  return approverPairs.reduce<ApproverItem[]>((acc, approverString) => {
    const [name, id] = approverString.split('^')
    if (!name || !id) return acc

    return [
      ...acc,
      {
        id,
        name
      }
    ]
  }, [])
}

const SubmitVersionForReviewController = () => {
  const history = useHistory()
  const [approverId, setApproverId] = useState('')
  const { product, version } = useContext(ProductVersionContext)

  const [submitVersionForReviewMutation] = useRequestReview()

  const [addFiles, { loading }] = useAddFiles()
  const [deleteFile] = useDeleteFile()
  const [editFile] = useEditFile()

  const submitVersionForReview = async () => {
    try {
      await submitVersionForReviewMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          reviewerId: approverId
        }
      })

      notifySuccess('Version submitted for review')
      history.push(`/products/${product.id}/versions/${version.number}`)
    } catch (errors) {
      notifyError('Submitting for review failed')
    }
  }

  const handleUpload = async (files: FileList) => {
    const filesInput = Array.from(files).map(file => ({
      file,
      name: file.name,
      type: ProductVersionFileType.Approval
    }))

    try {
      await addFiles({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          files: filesInput
        }
      })

      notifySuccess('Document uploaded')
    } catch (error) {
      notifyError('Uploading document failed')
    }
  }

  const handleDelete = async (fileId: string) => {
    try {
      await deleteFile({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          fileIds: [fileId]
        }
      })
      notifySuccess('Document deleted')
    } catch (error) {
      notifyError('Deleting document failed')
    }
  }

  const handleEdit = async (file: ProductVersionFile, newDescription: string) => {
    try {
      await editFile({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          input: {
            fileId: file.id,
            name: file.name,
            ...(newDescription ? { description: newDescription } : {})
          }
        }
      })

      notifySuccess('Document updated')
    } catch (error) {
      notifyError('editing document failed')
    }
  }

  const approvers = getApprovers(process.env.REACT_APP_APPROVERS)

  return (
    <SubmitVersionForReview
      submitVersionForReview={submitVersionForReview}
      uploading={loading}
      upload={handleUpload}
      deleteApproval={handleDelete}
      edit={handleEdit}
      productVersion={version}
      {...{ approvers, approverId, setApproverId }}
    />
  )
}

export default SubmitVersionForReviewController
