import React, { ChangeEvent } from 'react'
import { WorkflowDefinition } from 'components/product-configuration/workflow/types'
import { Select } from '@11FSFoundry/figloo'

interface Props {
  options: WorkflowDefinition[]
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  value: string
  disabled: boolean
}

const WorkflowInput = ({ options, onChange, value, disabled }: Props) => {
  return (
    <Select disabled={disabled} onChange={onChange} value={value}>
      <option disabled value="">
        Select your workflow
      </option>
      {options?.map(workflow => (
        <option key={workflow.name} value={workflow.name}>
          {workflow.label || workflow.name}
        </option>
      ))}
    </Select>
  )
}

export default WorkflowInput
