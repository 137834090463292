import React, { useContext, ReactNode } from 'react'

import { SelectWithValidation } from '@11FSFoundry/figloo'
import { FormContext } from 'components/product-configuration/Form'

interface Props {
  name: string
  children: ReactNode[] | ReactNode
  testId?: string
}

const FiglooSelectController = ({ name, children, testId }: Props) => {
  const { handleChange, getValue, getError, disabled } = useContext(FormContext)

  return (
    <SelectWithValidation
      name={name}
      onChange={event => handleChange(name, event.target.value)}
      value={getValue(name) as string | number | string[]} // TODO: correct type
      errorMessage={getError(name)}
      disabled={disabled}
      testId={testId}>
      {children}
    </SelectWithValidation>
  )
}

export default FiglooSelectController
