import { useContext, useEffect } from 'react'
import { FormContext } from 'components/product-configuration/Form'
import { ValidationFunction } from './useFormHandler'

function useValidationSubscription(name: string, validate?: ValidationFunction<unknown>) {
  const { registerValidationFunction, evictValidationFunction } = useContext(FormContext)

  const manageValidation = () => {
    if (validate && registerValidationFunction) {
      registerValidationFunction(name, validate)
    }
    return () => evictValidationFunction(name)
  }

  useEffect(manageValidation, [name])
}

export default useValidationSubscription
