import React, { useState, MouseEvent } from 'react'
import { Heading, Box, Card, Tag, CardFooter, Text } from '@11FSFoundry/figloo'
import DeleteButton from 'components/forms/DeleteButton'
import { Link } from 'react-router-dom'
import { ProductRule } from 'components/product-configuration/rules/types'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import RemoveRuleController from './RemoveRuleController'

interface Props {
  productId: string
  productVersion: number
  rule: ProductRule
  deleteRule: () => void
}

const ProductRuleCard = ({ productId, productVersion, rule, deleteRule }: Props) => {
  const [confirming, setConfirming] = useState(false)
  const hasDeleteRulesPermission = useCheckPermissions('delete:rules')
  const productVersionEditable = useProductVersionEditable()
  const deleteRuleEnabled = hasDeleteRulesPermission && productVersionEditable

  const handleDeleteRule = (event: MouseEvent) => {
    event.preventDefault()
    deleteRule()
  }

  const renderCard = () => (
    <CardFooter justifyContent="flex-end">
      <DeleteButton
        data-testid="deleteRule"
        onClick={(e: MouseEvent) => {
          e.preventDefault()
          setConfirming(true)
        }}
        disabled={!deleteRuleEnabled}
      />
    </CardFooter>
  )

  return (
    <Card
      backgroundColor={confirming ? 'rgba(255, 0, 0, 0.15)' : 'white'}
      key={rule.number}
      as={Link}
      to={`/products/${productId}/versions/${productVersion}/rules/${rule.number}`}>
      <Box>
        <Heading mb={1} variant="h3" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {rule.name}
        </Heading>
        <Tag color="gsSmoke" mb={1}>
          Core
        </Tag>
        <Text color="copyTwo">{rule.description}</Text>
      </Box>
      {confirming ? (
        <RemoveRuleController noteName="rule" handleConfirm={handleDeleteRule} setConfirming={setConfirming} />
      ) : (
        renderCard()
      )}
    </Card>
  )
}

export default ProductRuleCard
