import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import VersionList from 'components/product-configuration/VersionList'
import { GET_PRODUCT } from 'gql/GET_PRODUCT'

interface Props {
  productId: string
}

const VersionListLoader = ({ productId }: Props) => {
  const { loading, error, data, refetch } = useQuery(GET_PRODUCT, {
    variables: { productId },
    fetchPolicy: 'cache-and-network'
  })

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  return <VersionList product={data.product} refetch={refetch} />
}

export default VersionListLoader
