import React, { useContext } from 'react'
import { AuthContext } from 'components/auth/Auth'
import { useAuth0 } from 'components/auth/Auth0'
import JwtTools from 'tools/JwtTools'

const Auth0Button = () => {
  const { loginSuccess } = useContext(AuthContext)

  const { user, token } = useAuth0()

  if (user && token) {
    const jwt = JwtTools.parseJwt(token)

    const userInfo = {
      dateOfBirth: undefined,
      name: user.name || '',
      photo: user.picture || '',
      email: user.email || '',
      externalUserId: user.sub,
      tokenId: token,
      source: 'auth0' as 'auth0',
      permissions: jwt.permissions
    }

    loginSuccess(userInfo)
  }

  return <div>Logging you in</div>
}

export default Auth0Button
