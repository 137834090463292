import gql from 'graphql-tag'

import { FRAGMENT_RULE, FRAGMENT_DECISION_RULE } from './fragments'

const GET_AUDIT_LOG = gql`
  ${FRAGMENT_RULE}
  ${FRAGMENT_DECISION_RULE}
  query productVersionAuditTrail($productId: String!, $versionNumber: Int!) {
    productVersionAuditTrail(productId: $productId, versionNumber: $versionNumber) {
      ... on ProductVersionAttributeAdded {
        name
        value
        author
        createdAt
        __typename
      }
      ... on ProductVersionApprovalRequested {
        approver
        author
        createdAt
        __typename
      }
      ... on ProductVersionApproved {
        author
        createdAt
        __typename
      }
      ... on ProductVersionAttributeChanged {
        name
        oldValue
        newValue
        author
        createdAt
        __typename
      }
      ... on ProductVersionCreated {
        author
        createdAt
        __typename
      }
      ... on ProductVersionCloned {
        baseVersion
        author
        createdAt
        __typename
      }
      ... on ProductVersionStatusChanged {
        oldStatus
        newStatus
        author
        createdAt
        __typename
      }
      ... on ProductVersionRejected {
        comment
        author
        createdAt
        __typename
      }
      ... on ProductVersionAttributeRemoved {
        name
        value
        author
        createdAt
        __typename
      }
      ... on ProductVersionFieldChanged {
        author
        createdAt
        field
        oldFieldValue
        newFieldValue
        __typename
      }
      ... on ProductVersionNoteAdded {
        author
        createdAt
        noteName
        noteValue
        noteVisibility
      }
      ... on ProductVersionNoteRemoved {
        author
        createdAt
        noteName
      }
      ... on ProductVersionNoteChanged {
        author
        createdAt
        noteName
        oldNoteValue
        oldNoteVisibility
        newNoteValue
        newNoteVisibility
      }
      ... on ProductVersionFileAdded {
        author
        createdAt
        fileId
        fileName
        fileDescription
        fileType
      }
      ... on ProductVersionFileRemoved {
        author
        createdAt
        fileId
        fileName
        fileType
      }
      ... on ProductVersionFileChanged {
        author
        createdAt
        fileId
        oldFileName
        oldFileDescription
        newFileName
        newFileDescription
      }
      ... on ProductVersionRuleAdded {
        author
        createdAt
        rule {
          ...Rule
        }
      }
      ... on ProductVersionRuleDeleted {
        author
        createdAt
        rule {
          ...Rule
        }
      }
      ... on ProductVersionRuleChanged {
        author
        createdAt
        oldRule {
          ...Rule
        }
        newRule {
          ...Rule
        }
      }
      ... on ProductVersionDecisionRuleAdded {
        author
        createdAt
        ruleName
        rule {
          ...DecisionRule
        }
      }
      ... on ProductVersionDecisionRuleChanged {
        author
        createdAt
        ruleName
        oldDecisionRule {
          ...DecisionRule
        }
        newDecisionRule {
          ...DecisionRule
        }
      }
      ... on ProductVersionWorkflowTriggerAdded {
        author
        createdAt
        triggerId
        __typename
      }
      ... on ProductVersionWorkflowTriggerRemoved {
        author
        createdAt
        triggerId
        __typename
      }
      ... on ProductVersionRoleAdded {
        author
        createdAt
        roleId
        __typename
      }
      ... on ProductVersionRoleRemoved {
        author
        createdAt
        roleId
        __typename
      }
    }
  }
`

export default GET_AUDIT_LOG
