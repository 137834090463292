import { createContext } from 'react'

export interface RulesContextValues {
  TheContext: unknown
}

const RulesContext = createContext<RulesContextValues>({
  TheContext: undefined
})

export default RulesContext
