import React, { useContext, useState } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import MainPage from 'components/product-configuration/MainPage'
import FormSection from 'components/product-configuration/FormSection'
import FormField from 'components/product-configuration/FormField'
import { FormProvider } from 'components/product-configuration/Form'
import FormController from 'components/product-configuration/FormController'
import CardProviderInput from 'components/product-configuration/payment-provider/CardProviderInput'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { getAttributeValue } from 'util/productVersion'
import useUpsertAttributes from 'gql/useUpsertAttributes'
import { Flex, Switch, Text } from '@11FSFoundry/figloo'

const PaymentProvider = () => {
  const [loading, setLoading] = useState(false)
  const { product, version } = useContext(ProductVersionContext)
  const hasUpdateProductVersionPermission = useCheckPermissions('update:product-versions')
  const productVersionEditable = useProductVersionEditable()
  const disabled = !hasUpdateProductVersionPermission || !productVersionEditable

  const cardProviderSelect = getAttributeValue<string>(version, 'cardProviderSelect') ?? ''

  const upsertAttributes = useUpsertAttributes(product.id, version)

  const completeSubmit = async (attributes: Record<string, unknown>) => {
    setLoading(true)
    try {
      await upsertAttributes({
        cardProviderSelect: attributes.cardProviderSelect
      })

      notifySuccess('Updated payment provider')
    } catch (error) {
      notifyError('Updating payment provider failed')
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormProvider completeSubmit={completeSubmit} initialData={{ cardProviderSelect }} disabled={disabled} fields={[]}>
      <FormController testId="payment-provider-form">
        <MainPage
          headline="Payment provider"
          loading={loading}
          saveDisabled={disabled}
          saveLabel="Save"
          returnUrl={`/products/${product.id}/versions/${version.number}`}>
          <Flex alignItems="center" mb={3}>
            <Switch checked disabled onChange={() => {}} />
            <Text mx={2}>Product has a payment artifact</Text>
          </Flex>
          <FormSection>
            <FormField label="Payment provider">
              <CardProviderInput />
            </FormField>
          </FormSection>
        </MainPage>
      </FormController>
    </FormProvider>
  )
}

export default PaymentProvider
