import MainPage from 'components/product-configuration/MainPage'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import DecisionRule from 'components/product-configuration/rules/decision-rules/DecisionRule'
import { DecisionRuleType } from 'components/product-configuration/rules/decision-rules/types'
import useDecisionRuleHandler from 'hooks/useDecisionRuleHandler'
import React, { MouseEvent, useContext, useState } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'

const CreditLimitPage = () => {
  const { product, version } = useContext(ProductVersionContext)
  const creditLimitHandler = useDecisionRuleHandler(DecisionRuleType.CreditLimit)

  const [loading, setLoading] = useState(false)

  const onClick = async (event: MouseEvent) => {
    event.preventDefault()

    setLoading(true)

    try {
      if (creditLimitHandler.hasData) await creditLimitHandler.saveDecisionRule()
      notifySuccess('Updated credit limits')
    } catch (e) {
      notifyError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const saveEnabled = creditLimitHandler.editable && creditLimitHandler.hasData

  return (
    <MainPage
      headline="Credit limits"
      handleSave={onClick}
      loading={loading}
      saveDisabled={!saveEnabled}
      returnUrl={`/products/${product.id}/versions/${version.number}`}>
      <DecisionRule
        handler={creditLimitHandler}
        newRuleLabel="+ Add new credit limit"
        defaultResultLabel="Nominal limit"
      />
    </MainPage>
  )
}

export default CreditLimitPage
