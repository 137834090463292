import { Text } from '@11FSFoundry/figloo'
import React, { useContext } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import Confirmation from 'components/product-configuration/Confirmation'
import useDeleteNote from 'gql/useDeleteNote'

interface Props {
  noteName: string
  setConfirming: (confirming: boolean) => void
}

const RemoveNoteController = ({ noteName, setConfirming }: Props) => {
  const { product, version } = useContext(ProductVersionContext)

  const [removeNoteMutation] = useDeleteNote()

  const handleConfirm = async () => {
    try {
      await removeNoteMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          noteName
        }
      })

      notifySuccess('Note deleted')
      setConfirming(false)
    } catch (errors) {
      notifyError('Deleting note failed')
    }
  }

  return (
    <Confirmation handleConfirm={handleConfirm} setConfirming={setConfirming}>
      <Text>Are you sure you want to delete this note?</Text>
    </Confirmation>
  )
}

export default RemoveNoteController
