import React from 'react'
import { Flex, Heading, Text } from '@11FSFoundry/figloo'

interface Props {
  productName: string
  accountNumber: string
}

const AccountCard = ({ productName, accountNumber }: Props) => (
  <Flex width={360} height={250} p={2} pt={7} m={2} mb={2} bg="white" flexDirection="column">
    <Heading variant="h3" as="h3" mb={2}>
      {productName}
    </Heading>
    <Text>Account: {accountNumber}</Text>
  </Flex>
)

export default AccountCard
