import React, { useContext } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import AddNote from 'components/product-configuration/notes/AddNote'
import { ProductVersionNote } from 'types'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import useAddNote from 'gql/useAddNote'

const AddNoteController = () => {
  const { product, version } = useContext(ProductVersionContext)
  const [addNoteMutation] = useAddNote()

  const addNote = async (note: ProductVersionNote) => {
    try {
      await addNoteMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          note
        }
      })
      notifySuccess('Note added')
    } catch (errors) {
      notifyError('Adding note failed')
    }
  }

  return <AddNote addNote={addNote} />
}

export default AddNoteController
