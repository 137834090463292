import { Box, Select } from '@11FSFoundry/figloo'
import OperatorController from 'components/product-configuration/rules/OperatorController'
import {
  ConditionData,
  RulesSchema,
  ValidateParametersArgs,
  ParameterActionData
} from 'components/product-configuration/rules/types'
import React from 'react'

interface Props {
  schemaItem: RulesSchema
  condition: ConditionData
  disabled?: boolean
  index: number
  useValidateParameters: (args: ValidateParametersArgs) => void
  setGroup: (group: string) => void
  setProperty: (property: string) => void
  setOperator: (operator: string) => void
  setParameter: (paramActionData: ParameterActionData) => void
}

function Condition({
  schemaItem,
  condition,
  disabled,
  index,
  useValidateParameters,
  setGroup,
  setProperty,
  setOperator,
  setParameter
}: Props) {
  const { conditionProperty, conditionGroup, conditionOperator, conditionParameters } = condition
  const selectedPropertyGroup = schemaItem.propertyGroups.find(({ name }) => name === conditionGroup)
  const selectedProperty = selectedPropertyGroup?.properties.find(({ name }) => name === conditionProperty)

  return (
    <>
      <Box mr={3} py={1}>
        <Select
          data-testid={`conditionGroupSelector-${index}`}
          key={conditionGroup}
          disabled={disabled}
          value={conditionGroup}
          onChange={({ target }) => setGroup(target.value)}>
          <option value="">Please select...</option>
          {schemaItem.propertyGroups.map(({ name, label }) => (
            <option value={name} key={name}>
              {label}
            </option>
          ))}
        </Select>
      </Box>
      {selectedPropertyGroup && (
        <Box mr={3} py={1}>
          <Select
            data-testid={`conditionPropertySelector-${index}`}
            key={conditionGroup}
            disabled={disabled}
            value={conditionProperty}
            onChange={({ target }) => setProperty(target.value)}>
            <option value="">Please select...</option>
            {selectedPropertyGroup.properties.map(({ name, label }) => (
              <option value={name} key={name}>
                {label}
              </option>
            ))}
          </Select>
        </Box>
      )}
      {selectedProperty && (
        <OperatorController
          key={conditionProperty}
          operatorInput={selectedProperty.operatorInput}
          conditionOperator={conditionOperator}
          index={index}
          conditionParameters={conditionParameters}
          disabled={disabled}
          setOperator={setOperator}
          setParameter={setParameter}
          useValidateParameters={useValidateParameters}
        />
      )}
    </>
  )
}

export default Condition
