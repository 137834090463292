import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

export const GET_PRODUCT = gql`
  query($productId: String!) {
    product(productId: $productId) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`
