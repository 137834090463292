import React, { ReactNode } from 'react'
import { Flex } from '@11FSFoundry/figloo'

interface Props {
  children?: ReactNode
}

const PageTitle = ({ children }: Props) => (
  <Flex bg="white" alignSelf="stretch" flexDirection="row" justifyContent="center" py={5} mb={4}>
    <Flex alignItems="center" width={1152} justifyContent="space-between">
      {children}
    </Flex>
  </Flex>
)
export default PageTitle
