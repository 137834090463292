const removeProp = (object: Record<string, unknown>, propToDelete = '__typename'): Record<string, unknown> => {
  const clone = JSON.parse(JSON.stringify(object))

  const go = (obj: Record<string, unknown>): void =>
    Object.keys(obj).forEach(key => {
      if (key === propToDelete) {
        /* eslint no-param-reassign: [2, { "props": false }] */
        delete obj[key]
      } else if (typeof obj[key] === 'object') {
        go(obj[key] as Record<string, unknown>)
      }
    })

  go(clone)
  return clone
}

export default removeProp
