import AuditController from 'components/product-configuration/audit/AuditController'
import React from 'react'
import Page from 'components/Page'
import ProductVersionTabs from 'components/product-configuration/ProductVersionTabs'

const ProductVersionRevisionsPage = () => {
  return (
    <Page>
      <ProductVersionTabs>
        <AuditController />
      </ProductVersionTabs>
    </Page>
  )
}

export default ProductVersionRevisionsPage
