import { useQuery } from '@apollo/react-hooks'
import { Box, Button, Flex, Text } from '@11FSFoundry/figloo'
import DecisionRuleConditionRow from 'components/product-configuration/rules/decision-rules/DecisionRuleConditionRow'
import {
  DecisionRuleActionType,
  DecisionRuleCondition,
  DefinitionsSchema
} from 'components/product-configuration/rules/decision-rules/types'
import Parameters from 'components/product-configuration/rules/Parameters'
import { ConditionActionType, ParameterActionData } from 'components/product-configuration/rules/types'
import GET_DECISION_RULE_DEFINITIONS from 'gql/GET_DECISION_RULE_DEFINITIONS'
import { DecisionRuleHandler } from 'hooks/useDecisionRuleHandler'
import React, { ReactNode } from 'react'
import { SortableContainer as sortableContainer } from 'react-sortable-hoc'

interface ContainerProps {
  children: ReactNode
}

const SortableContainer = sortableContainer(({ children }: ContainerProps) => (
  <Flex flexDirection="column">{children}</Flex>
))

interface SortEnd {
  oldIndex: number
  newIndex: number
}

interface Props {
  handler: DecisionRuleHandler
  newRuleLabel: string
  defaultResultLabel: string
}

const DecisionRule = ({ handler, newRuleLabel, defaultResultLabel }: Props) => {
  // Note that although this loads schemas for all rules, it is acceptable to run the query
  // for each specific `DecisionRule` as Apollo automatically caches the result.
  const { data, error, loading } = useQuery<{ definitions: DefinitionsSchema }>(GET_DECISION_RULE_DEFINITIONS)

  const ruleSchema = data?.definitions.decisionRules.find(rule => rule.name === handler.ruleType)

  if (!ruleSchema) return <Text>No schema found for decision rule {handler.ruleType}</Text>
  if (error) return <Text>Failed to load schema</Text>
  if (loading) return <Text>orLoading</Text>
  if (!data) return null

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    handler.dispatch({
      type: DecisionRuleActionType.REORDER,
      oldIndex,
      newIndex
    })
  }

  const setDefaultResult = (paramActionData: ParameterActionData) => {
    handler.dispatch({
      ...paramActionData,
      type: DecisionRuleActionType.SET_DEFAULT_RESULT
    })
  }

  const setResult = (conditionIndex: number) => (paramActionData: ParameterActionData) =>
    handler.dispatch({
      ...paramActionData,
      conditionIndex,
      type: DecisionRuleActionType.SET_RESULT
    })

  const setGroup = (index: number) => (conditionGroup: string) =>
    handler.dispatch({ type: ConditionActionType.SET_GROUP, conditionGroup, index })

  const setProperty = (index: number) => (conditionProperty: string) =>
    handler.dispatch({ type: ConditionActionType.SET_PROPERTY, conditionProperty, index })

  const setParameter = (conditionIndex: number) => (paramActionData: ParameterActionData) => {
    handler.dispatch({
      ...paramActionData,
      conditionIndex,
      type: ConditionActionType.SET_PARAMETER
    })
  }

  const setOperator = (index: number) => (conditionOperator: string) =>
    handler.dispatch({ type: ConditionActionType.SET_OPERATOR, index, conditionOperator })

  return (
    <>
      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        {handler.state.conditions.map((condition: DecisionRuleCondition, index: number) => (
          <DecisionRuleConditionRow
            key={condition.key}
            index={index}
            condition={condition}
            ruleSchema={ruleSchema}
            conditionIndex={index}
            label={index === 0 ? 'IF' : 'ELSE IF'}
            editable={handler.editable}
            disabled={!handler.editable}
            setResult={setResult(index)}
            setGroup={setGroup(index)}
            setProperty={setProperty(index)}
            setParameter={setParameter(index)}
            setOperator={setOperator(index)}
            deleteCondition={() => handler.dispatch({ type: ConditionActionType.DELETE_CONDITION, index })}
          />
        ))}
      </SortableContainer>
      {handler.editable && (
        <Flex bg="rgba(255, 255, 255, 0.4)" p={2} mb={1}>
          <Box width={20} mr={2} />
          <Button
            variant="secondary"
            data-testid="addNewRuleButton"
            onClick={() => handler.dispatch({ type: ConditionActionType.ADD_BLANK_CONDITION })}>
            {newRuleLabel}
          </Button>
        </Flex>
      )}
      <Flex p={2} bg="white">
        <Flex flex={1} alignItems="center">
          <Box width={20} mr={2} />
          <Flex width={50} mr={2} alignItems="center">
            {handler.state.conditions.length ? 'ELSE' : ''}
          </Flex>
          <Text>{defaultResultLabel}</Text>
        </Flex>
        <Flex justifyContent="flex-end" mr={3} alignItems="center">
          <Parameters
            parameterDefinitions={ruleSchema.result.parameters}
            parameters={handler.state.defaultResult}
            handleChange={setDefaultResult}
            disabled={!handler.editable}
            testLabel="defaultResult"
          />
        </Flex>
      </Flex>
    </>
  )
}

export default DecisionRule
