import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { GET_PRODUCT } from 'gql/GET_PRODUCT'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import ApplicationsList from './ApplicationsList'

const ApplicationsPage = () => {
  const hasVersionOpenAndPausePermission = useCheckPermissions('update-applications-state:product-versions')
  const { productId } = useParams()
  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { productId },
    fetchPolicy: 'cache-and-network'
  })

  if (!hasVersionOpenAndPausePermission) return <p>You do not have sufficient permissions to access this page</p>

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  return <ApplicationsList product={data.product} />
}

export default ApplicationsPage
