import React, { MouseEvent, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Flex, Box, Text, theme, Logo } from '@11FSFoundry/figloo'
import NavBarItem from 'components/product-configuration/NavBarItem'
import { AuthContext } from 'components/auth/Auth'
import { AdminApplication, availableApplications } from 'components/auth/ApplicationAccess'

const formatName = (name: string) => name.toUpperCase().charAt(0)

const appPaths: Record<AdminApplication, string> = {
  [AdminApplication.ProductConfiguration]: '/products',
  [AdminApplication.CustomerAdvisorPortal]: '/customers/search'
}

const NavBar = () => {
  const { logout, tokenData } = useContext(AuthContext)

  return (
    <Flex py={4} bg="navBackground" justifyContent="center" style={{ position: 'fixed', zIndex: 1 }} width="100%">
      <Flex width={1152}>
        <Box pr={6}>
          <NavLink to="/">
            <Logo type="solo-light" />
          </NavLink>
        </Box>

        {tokenData?.permissions &&
          // render a navbar link for each application the user is permitted to access
          availableApplications(tokenData.permissions).map(application => {
            const path = appPaths[application]

            return (
              <NavBarItem
                key={application}
                to={path}
                isActive={(match, location) => {
                  // prod config is also at '/'
                  if (application === AdminApplication.ProductConfiguration)
                    return (location.pathname === '/' && match?.isExact) || location.pathname.startsWith('/product')
                  return location.pathname.startsWith(path)
                }}
                testId="products">
                {application}
              </NavBarItem>
            )
          })}

        {tokenData && (
          <Flex flex={1} justifyContent="flex-end" alignItems="center">
            <Box pr={3}>
              <Text css={theme.text.circle} backgroundColor={theme.colors.tagPeach}>
                {formatName(tokenData.name)}
              </Text>
            </Box>
            <NavBarItem
              to="/"
              isActive={() => false}
              onClick={(event: MouseEvent) => {
                event.preventDefault()
                logout()
              }}
              testId="logout">
              Logout
            </NavBarItem>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default NavBar
