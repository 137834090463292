import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Heading, InputWithValidation, Button, Label, Box } from '@11FSFoundry/figloo'
import Page from 'components/Page'

const Search = () => {
  const [query, setQuery] = useState('')
  const [noUserFound, setNoUserFound] = useState(false)
  const history = useHistory()

  return (
    <Page>
      <form
        onSubmit={event => {
          event.preventDefault()
          // This is a workaround for unfortunate API behaviour whereby
          // a new user is created when querying for id starting with
          // the string foundry:identity:user:foundry: and an error is thrown
          // otherwise
          if (query.startsWith('foundry:identity:user:foundry:')) {
            history.push(`/customers/customer/${query}`)
          } else {
            setNoUserFound(true)
          }
        }}>
        <Flex flex={1} justifyContent="center">
          <Flex flexDirection="column" width={1152}>
            <Heading variant="h1" as="h1" mt={8} mb={5}>
              Customer accounts
            </Heading>
            <Label mb={2}>Search by Foundry Id</Label>

            <Flex>
              <Flex flex={1} mr={1}>
                <InputWithValidation
                  data-testid="customer-search-input"
                  bg="white"
                  mr={1}
                  height="auto"
                  flex={1}
                  value={query}
                  onChange={({ target }) => {
                    setNoUserFound(false)
                    setQuery(target.value)
                  }}
                  errorMessage={noUserFound ? `No customers found with id ${query}` : undefined}
                />
              </Flex>
              <Flex>
                <Box>
                  <Button height="auto" type="submit" p={2} sx={{ border: '1px solid transparent' }}>
                    Search
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Page>
  )
}

export default Search
