import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import GET_RULE_DEFINITIONS from 'gql/GET_RULE_DEFINITIONS'
import { TacoRulesSchema } from 'components/product-configuration/rules/taco-rules/types'
import TacoRuleEditor from 'components/product-configuration/rules/taco-rules/TacoRuleEditor'
import { ProductRuleInput } from 'components/product-configuration/rules/types'

interface Props {
  saveRule: (rule: ProductRuleInput) => void
  loading: boolean
}

const TacoRuleSchemaController = ({ saveRule, loading }: Props) => {
  const { loading: rulesSchemaLoading, error: rulesSchemaError, data: rulesSchemaData } = 
  useQuery<{ definitions: { rules: TacoRulesSchema[] } }>(GET_RULE_DEFINITIONS)

  if (rulesSchemaLoading) return <p>Loading</p>
  if (rulesSchemaError) return <p>Error</p>
  if (!rulesSchemaData) return null

  return <TacoRuleEditor schema={rulesSchemaData.definitions.rules} saveRule={saveRule} loading={loading} />
}

export default TacoRuleSchemaController
