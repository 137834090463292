import React from 'react'
import { useParams } from 'react-router-dom'
import Page from 'components/Page'
import VersionListLoader from 'components/product-configuration/VersionListLoader'

const ProductVersions = () => {
  const { productId } = useParams()

  const getContent = () => {
    if (!productId) return 'Product id not specified'

    return <VersionListLoader productId={productId} />
  }

  return <Page>{getContent()}</Page>
}
export default ProductVersions
