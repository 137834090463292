import React from 'react'
import { Button } from '@11FSFoundry/figloo'

interface Props {
  withdrawVersion: () => void
}

const WithdrawVersionFromReview = ({ withdrawVersion }: Props) => (
  <Button onClick={() => withdrawVersion()}>Cancel approval request and revert to draft</Button>
)

export default WithdrawVersionFromReview
