import React, { useContext } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import { useHistory } from 'react-router-dom'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import useAddRule from 'gql/useAddRule'
import TacoRuleSchemaController from 'components/product-configuration/rules/taco-rules/TacoRuleSchemaController'
import { ProductRuleInput, ProductRule } from 'components/product-configuration/rules/types'
import RulesContext from 'components/product-configuration/rules/RulesContext'
import { TacoRulesProvider, TacoRulesContext } from 'components/product-configuration/rules/taco-rules/TacoRules'

const CreateTacoRulePage = () => {
  const { product, version } = useContext(ProductVersionContext)
  const [addRuleMutation, { loading }] = useAddRule()
  const history = useHistory()

  const addRule = async (productRuleInput: ProductRuleInput) => {
    const variables = { productId: product.id, versionNumber: version.number, rule: productRuleInput }

    try {
      const { data } = await addRuleMutation({ variables })
      const updatedVersion = data?.product.addRule.versions.find(({ number }) => number === version.number)

      // get latest rule
      const rule = updatedVersion?.rules.reduce<ProductRule | undefined>((latestRule, currentRule) => {
        if (!latestRule) return currentRule
        return latestRule.number > currentRule.number ? latestRule : currentRule
      }, undefined)

      if (rule) history.push(`/products/${product.id}/versions/${version.number}/rules/${rule.number}`)

      notifySuccess('Rule added')
    } catch (error) {
      notifyError('Adding rule failed')
    }
  }

  return (
    <RulesContext.Provider value={{ TheContext: TacoRulesContext }}>
      <TacoRulesProvider>
        <TacoRuleSchemaController saveRule={addRule} loading={loading} />
      </TacoRulesProvider>
    </RulesContext.Provider>
  )
}

export default CreateTacoRulePage
