import React, { useEffect, useState, useContext } from 'react'
import { Image } from '@11FSFoundry/figloo'
import { AuthContext } from './Auth'

interface Props {
  url: string
}

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })

const AuthImage = ({ url }: Props) => {
  const { tokenData } = useContext(AuthContext)
  const [src, setSrc] = useState('')

  const token = tokenData?.tokenId

  useEffect(() => {
    if (!token) throw TypeError('AuthContext does not contain tokenData')

    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    })
      .then(res => res.blob())
      .then(blob => convertBlobToBase64(blob))
      .then(srcBase64 => setSrc(srcBase64 as string))
      .catch(() => console.log('unable to render image'))
  }, [token, url])

  return <Image src={src} maxHeight="100%" maxWidth="100%" />
}

export default AuthImage
