import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const EDIT_FILE = gql`
  mutation($productId: String!, $versionNumber: Int!, $input: EditProductVersionFileInput!) {
    editFileInProductVersion(productId: $productId, versionNumber: $versionNumber, input: $input) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default EDIT_FILE
