import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import Audit from 'components/product-configuration/audit/Audit'
import { ActualAuditItem } from 'components/product-configuration/audit/types'
import GET_AUDIT_LOG from 'gql/GET_AUDIT_LOG'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'

const AuditController = () => {
  const { product, version } = useContext(ProductVersionContext)
  const productId = product.id
  const versionNumber = version.number
  const { data, loading, error } = useQuery<{ productVersionAuditTrail: ActualAuditItem[] }>(GET_AUDIT_LOG, {
    variables: { productId, versionNumber },
    fetchPolicy: 'cache-and-network'
  })

  if (error) return <p>Error :(</p>
  if (data?.productVersionAuditTrail) {
    return <Audit auditTrail={data.productVersionAuditTrail} />
  }

  if (loading) return <p>Loading...</p>

  return null
}

export default AuditController
