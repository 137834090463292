import React, { useContext, useState } from 'react'
import { IconBin, Text, Flex } from '@11FSFoundry/figloo'
import { notifySuccess, notifyError } from 'components/Toast'
import Confirmation from 'components/product-configuration/Confirmation'
import useEditFile from 'gql/useEditFile'
import useDeleteFile from 'gql/useDeleteFile'
import { ProductVersionFile } from 'types'
import AuthImage from 'components/auth/AuthImage'
import { documentDownloadURL } from 'hooks/useAuthenticatedDownload'
import ImageCard from 'components/product-configuration/ImageCard'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'

interface Props {
  image: ProductVersionFile
}

const ExistingImage = ({ image }: Props) => {
  const [confirming, setConfirming] = useState(false)
  const { product, version } = useContext(ProductVersionContext)

  const [editFileMutation] = useEditFile()
  const [deleteFileMutation] = useDeleteFile()

  const { id, name, description, url } = image

  const updateImage = async (fieldName: string, value: string) => {
    try {
      await editFileMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          input: {
            fileId: id,
            name,
            description: description === null ? '' : description,
            [fieldName]: value
          }
        }
      })

      notifySuccess('Image updated')
    } catch (error) {
      notifyError('Edit image failed')
    }
  }

  const deleteImage = async () => {
    try {
      await deleteFileMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          fileIds: [id]
        }
      })

      notifySuccess('Image deleted')
    } catch (error) {
      notifyError('Image deletion failed')
    }
  }

  return confirming ? (
    <Flex sx={{ width: 365, p: 5, mr: 3, mb: 3, bg: 'white' }} flexDirection="column">
      <Confirmation vertical handleConfirm={deleteImage} setConfirming={setConfirming}>
        <Text>Are you sure you want to delete this image?</Text>
      </Confirmation>
    </Flex>
  ) : (
    <ImageCard
      // eslint-disable-next-line react/no-array-index-key
      key={id}
      name={name}
      description={description}
      updateImage={updateImage}
      deleteIcon={IconBin}
      deleteImage={() => setConfirming(true)}>
      <AuthImage url={documentDownloadURL(url)} />
    </ImageCard>
  )
}

export default ExistingImage
