import React, { ReactNode, useContext, FormEvent } from 'react'
import { FormContext } from 'components/product-configuration/Form'

interface Props {
  children: ReactNode
  testId?: string
}

const FormController = ({ children, testId }: Props) => {
  const { handleSubmit, validate } = useContext(FormContext)

  const validateAndSubmit = (event: FormEvent) => {
    validate() ? handleSubmit(event) : event.preventDefault()
  }

  return (
    <form style={{ display: 'flex', flex: 1 }} onSubmit={validateAndSubmit} data-testid={testId}>
      {children}
    </form>
  )
}

export default FormController
