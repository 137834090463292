import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const REJECT_VERSION = gql`
  mutation($productId: String!, $versionNumber: Int!, $comment: String!) {
    rejectProductVersion(productId: $productId, versionNumber: $versionNumber, comment: $comment) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default REJECT_VERSION
