import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

export const EDIT_VERSION = gql`
  mutation($productId: String!, $versionNumber: Int!, $note: String!) {
    editProductVersion(productId: $productId, versionNumber: $versionNumber, note: $note) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default EDIT_VERSION
