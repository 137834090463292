import { MutationFunctionOptions, ExecutionResult } from '@apollo/react-common'
import { ProductRule } from 'components/product-configuration/rules/types'
import { DecisionRuleData } from 'components/product-configuration/rules/decision-rules/types'

export interface SimpleDate {
  day: number
  month: number
  year: number
}

export interface Token {
  id_token: string // eslint-disable-line
  access_token: string // eslint-disable-line
}

export interface UserInfo {
  dateOfBirth?: SimpleDate
  name: string
  photo: string
  email: string
  foundryUserId?: string
  externalUserId: string
  tokenId: string
  permissions?: UserPermission[]
  source: 'foundryauth' | 'google' | 'auth0'
}

export type UserPermission =
  | 'approve:product-versions'
  | 'reject:product-versions'
  | 'create:products'
  | 'clone:product-versions'
  | 'create:product-versions'
  | 'update:product-versions'
  | 'archive:product-versions'
  | 'withdraw-approval-request:product-versions'
  | 'request-approval:product-versions'
  | 'create:notes'
  | 'delete:notes'
  | 'update:notes'
  | 'create:attributes'
  | 'update:attributes'
  | 'delete:attributes'
  | 'create:files'
  | 'update:files'
  | 'delete:files'
  | 'create:rules'
  | 'update:rules'
  | 'delete:rules'
  | 'read:audit-trails'
  | 'create:decision-rules'
  | 'update:decision-rules'
  | 'delete:decision-rules'
  | 'read:accounts'
  | 'update-applications-state:product-versions'

export interface User {
  name: string
  permissions: string[]
}

export interface TemplateCatalog {
  [templateId: string]: SettingGroup[]
}

export interface SettingGroup {
  name: string
  fields: SettingField[]
}

export type InputField =
  | 'percentage'
  | 'boolean'
  | 'text'
  | 'textarea'
  | 'select'
  | {
      type: 'money'
      currency: 'NOK'
    }

export interface SettingField {
  label: string
  description: string
  input: InputField
  name: string
}

export interface InitialSettingValue {
  productId: string
  settingId: string
  value: ValueType
  initial?: boolean
}

export interface SettingValue extends InitialSettingValue {
  id: string
  status: string
  staged?: boolean
  date: Date | null
  endDate: Date | null
  requestedBy?: User
}

export type ValueType = string | boolean

export interface SettingValues {
  [id: string]: SettingValue
}

export type ValueStatus = 'APPLIED' | 'CREATED' | 'REQUESTED' | 'APPROVED'

export type ChangeSet = {
  [status in ValueStatus]: SettingValue
}

export type ChangeSetWithConfigBySettingId = {
  [settingId: string]: ChangeSet & {
    settingConfig: SettingField
  }
}

export type VersionStatus =
  | 'DRAFT'
  | 'UNDER_REVIEW'
  | 'REJECTED'
  | 'APPROVED'
  | 'ARCHIVED'
  | 'APPLICATIONS_OPEN'
  | 'APPLICATIONS_PAUSED'

export interface Attribute {
  name: string
  value: unknown
}
export interface AttributeGroup {
  attributes: Attribute[]
}

export interface ProductVersion {
  note: string
  number: number
  userCount: number
  status: VersionStatus
  attributeGroups: AttributeGroup[]
  review: null | {
    reviewer: string
    comment: null | string
  }
  rules: ProductRule[]
  notes: ProductVersionNote[]
  files: ProductVersionFile[]
  decisionRules: DecisionRuleData[]
}

export interface Product {
  id: string
  name: string
  description: string
  liveVersions: number
  status: 'ARCHIVED' | 'DRAFT' | 'LIVE'
  userCount: number
  versions: ProductVersion[]
}

export interface Option {
  label: string
  value: string
}

export interface VersionAttribute {
  id: string
  label: string
  description: string
  tags: string[]
  input?: string
  type?: string
  values?: string[]
  constraints: ValidationConfig
}

export interface ValidationConfig {
  required?: string
  positive?: string
  validAmount?: string
}

export interface ValidationRules {
  [ruleName: string]: (value: unknown) => boolean
}

export interface VersionConfig {
  id: string
  label: string
  description?: string
  attributes: VersionAttribute[]
}

export type ValidationConstraintNames = ValidationConstraintName[]

export type ValidationConstraintName = 'required' | 'positive'

export interface EditVersionVariables {
  versionNumber: number
  note: string
  productId: string
}

export interface RequestReviewVariables {
  productId: string
  versionNumber: number
  reviewerId: string
}

export interface ApproveVersionVariables {
  productId: string
  versionNumber: number
}

export interface AllowApplicationsVariables {
  productId: string
  versionNumber: number
}

export interface RejectVersionVariables {
  productId: string
  versionNumber: number
  comment: string
}
export interface WithdrawVersionVariables {
  productId: string
  versionNumber: number
}

export interface ArchiveVersionVariables {
  productId: string
  versionNumber: number
}
export interface CloneVersionVariables {
  productId: string
  versionNumber: number
  note: string
}

export interface AddAttributesVariables {
  productId: string
  versionNumber: number
  attributes: string
}
export interface AddNoteVariables {
  productId: string
  versionNumber: number
  note: ProductVersionNote
}

export interface AddFilesVariables {
  files: ProductVersionFileInput[]
  versionNumber: number
  productId: string
}

export interface EditFileVariables {
  input: EditProductVersionFileInput
  versionNumber: number
  productId: string
}

export interface DeleteFilesVariables {
  fileIds: string[]
  versionNumber: number
  productId: string
}

export interface DeleteRuleVariables {
  productId: string
  versionNumber: number
  ruleNumber: number
}

export interface DeleteNoteVariables {
  productId: string
  versionNumber: number
  noteName: string
}

export type AddFilesFunction = (
  options?: MutationFunctionOptions<{ product: { addFiles: Product } }, AddFilesVariables>
) => Promise<ExecutionResult<{ product: { addFiles: Product } }>>

export interface ProductVersionNote extends Record<string, unknown> {
  name: string
  value: string
  visibility: 'PUBLIC' | 'ADMIN'
}

export interface ApproverItem {
  id: string
  name: string
}

export enum ProductVersionFileType {
  Image = 'IMAGE',
  Document = 'DOCUMENT',
  Approval = 'APPROVAL'
}

export interface ProductVersionFile {
  id: string
  fileName: string
  url: string
  name: string
  description: string | undefined
  type: ProductVersionFileType
}

// Data required to upload a file over GraphQL
export interface ProductVersionFileInput {
  file: File
  name: string
  description?: string
  type: ProductVersionFileType
}

export interface EditProductVersionFileInput {
  fileId: string // file ID, note the file itself
  name: string
  description?: string
  // if description is empty, the description of the file will also be set to empty.
  // to avoid this, ensure the existing description is included when submitting requests
}
