import React, { ReactNode, useContext } from 'react'
import { NavLink, Link as RouterLink } from 'react-router-dom'
import { Flex, Text, theme, Link, IconArrowLeft } from '@11FSFoundry/figloo'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import VersionNoteInput from 'components/product-configuration/VersionNoteInput'

interface Props {
  children: ReactNode
}

const ProductVersionTabs = ({ children }: Props) => {
  const { product, version } = useContext(ProductVersionContext)
  const productVersionEditable = useProductVersionEditable()

  return (
    <Flex flexDirection="column" minHeight="100vh" bg="#fff">
      <Flex justifyContent="center">
        <Flex flexDirection="column" width={1152} pt={5}>
          <Link variant="secondaryButtonLink" as={RouterLink} to={`/products/${product.id}`} width="fit-content" px={3}>
            <IconArrowLeft /> {product.name}
          </Link>

          <VersionNoteInput version={version} productId={product.id} disabled={!productVersionEditable} />
          <Flex mx={5}>
            <NavLink
              exact
              style={{
                backgroundColor: 'rgba(232, 232, 232, 0.3)',
                padding: '16px 24px',
                fontWeight: 'bold',
                margin: '0 3px'
              }}
              activeStyle={{
                backgroundColor: theme.colors.gsMist
              }}
              to={`/products/${product.id}/versions/${version.number}`}>
              <Text>Configuration</Text>
            </NavLink>
            <NavLink
              exact
              style={{
                backgroundColor: 'rgba(232, 232, 232, 0.3)',
                padding: '16px 24px',
                fontWeight: 'bold',
                margin: '0 3px'
              }}
              activeStyle={{
                backgroundColor: theme.colors.gsMist
              }}
              to={`/products/${product.id}/versions/${version.number}/workflows`}>
              <Text>Workflows</Text>
            </NavLink>
            <NavLink
              exact
              style={{
                backgroundColor: 'rgba(232, 232, 232, 0.3)',
                padding: '16px 24px',
                fontWeight: 'bold',
                margin: '0 3px'
              }}
              activeStyle={{
                backgroundColor: theme.colors.gsMist
              }}
              to={`/products/${product.id}/versions/${version.number}/revisions`}>
              <Text>Revision History</Text>
            </NavLink>
            <NavLink
              exact
              style={{
                backgroundColor: 'rgba(232, 232, 232, 0.3)',
                padding: '16px 24px',
                fontWeight: 'bold',
                margin: '0 3px'
              }}
              activeStyle={{
                backgroundColor: theme.colors.gsMist
              }}
              to={`/products/${product.id}/versions/${version.number}/approve`}>
              <Text>Approvals</Text>
            </NavLink>
          </Flex>
        </Flex>
      </Flex>
      <Flex backgroundColor="gsMist" flex="1" flexDirection="column" alignItems="center">
        {children}
      </Flex>
    </Flex>
  )
}

export default ProductVersionTabs
