import React from 'react'
import { Flex, Button } from '@11FSFoundry/figloo'
import { OauthSender } from 'react-oauth-flow'

const { origin } = window.location

const FoundryOAuthSender = () => (
  <Flex my={2}>
    <Button variant="secondary" data-testid="foundry-oauth-button">
      <OauthSender
        authorizeUrl={process.env.REACT_APP_FOUNDRY_OAUTH_AUTHORIZE_URL}
        clientId={process.env.REACT_APP_FOUNDRY_OAUTH_CLIENT_ID}
        redirectUri={`${origin}/auth/foundry`}
        state={{ from: '/settings' }}
        render={({ url }: { url: string }) => <a href={url}>Foundry OAuth</a>}
      />
    </Button>
  </Flex>
)

export default FoundryOAuthSender
