import React from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { Flex, Heading, Link, IconArrowLeft, Text } from '@11FSFoundry/figloo'
import Page from 'components/Page'
import { useQuery } from '@apollo/react-hooks'
import { UserAccount } from 'components/customer-service/types'
import GET_USER_ACCOUNTS from 'gql/GET_USER_ACCOUNTS'
import AccountCard from './AccountCard'

const AccountsPage = () => {
  const { customerId } = useParams()

  const { data, error, loading } = useQuery<{
    user: {
      accounts: UserAccount[]
    }
  }>(GET_USER_ACCOUNTS, {
    variables: { userId: customerId }
  })

  if (loading) return <p>loading</p>
  if (error) return <p>error</p>

  return (
    <Page>
      <Flex justifyContent="center">
        <Flex flexDirection="column" width={1152}>
          <Link
            variant="secondaryButtonLink"
            as={RouterLink}
            to="/customers/search"
            mt={5}
            mb={1}
            px={3}
            width="fit-content">
            <IconArrowLeft /> Search
          </Link>

          <Heading variant="h1" as="h1" my={5}>
            {customerId}
          </Heading>
          <Heading variant="h2" as="h2" mt={5} mb={3}>
            Accounts
          </Heading>
          {data?.user.accounts.length ? (
            <Flex flexWrap="wrap" m={-2}>
              {data?.user.accounts.map(account => (
                <Link key={account.id} as={RouterLink} to={`/customers/customer/${customerId}/account/${account.id}`}>
                  <AccountCard productName={account.product.name} accountNumber={account.id} />
                </Link>
              ))}
            </Flex>
          ) : (
            <Text color="gsConcrete">Customer has no accounts</Text>
          )}
        </Flex>
      </Flex>
    </Page>
  )
}

export default AccountsPage
