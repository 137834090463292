import * as Sentry from '@sentry/browser'
import { ProductVersion, AddAttributesVariables } from 'types'
import { containsAttribute } from 'util/productVersion'
import useAddAttributes from './useAddAttributes'
import useEditAttributes from './useEditAttributes'

const logError = (e: Error) =>
  Sentry.withScope(scope => {
    scope.setLevel(Sentry.Severity.Error)
    Sentry.captureMessage(`Error upserting attributes: ${e.message}`)
  })

const logAndThrow = (e: Error) => {
  logError(e)
  throw e
}

const useUpsertAttributes = (productId: string, version: ProductVersion) => {
  const [addAttributesMutation] = useAddAttributes()
  const [editAttributesMutation] = useEditAttributes()

  const addAttributes = async (variables: AddAttributesVariables) => {
    const { data, errors } = await addAttributesMutation({ variables })
    if (!data) return logAndThrow(new Error(`No data in response when adding attributes to product ${productId}`))
    if (!data.addAttributesToProductVersion.id)
      return logAndThrow(new Error(`No product ID in response when adding attributes to product ${productId}`))
    if (errors) {
      errors.forEach(logError)
      throw errors
    }
  }

  const editAttributes = async (variables: AddAttributesVariables) => {
    const { data, errors } = await editAttributesMutation({ variables })
    if (!data) return logAndThrow(new Error(`No data in response when editing attributes on product ${productId}`))
    if (!data.editAttributesOfProductVersion.id)
      return logAndThrow(new Error(`No product ID in response when editing attributes on product ${productId}`))
    if (errors) {
      errors.forEach(logError)
      throw errors
    }
  }

  return async (attribute: { [key: string]: unknown }) => {
    const keys = Object.keys(attribute)
    const existingAttributes = keys.filter(k => containsAttribute(version, k))
    const newAttributes = keys.filter(k => !containsAttribute(version, k))
    const baseVariables = {
      productId,
      versionNumber: version.number
    }
    existingAttributes.length > 0 &&
      (await editAttributes({
        ...baseVariables,
        attributes: JSON.stringify(
          existingAttributes.map(k => ({
            [k]: attribute[k]
          }))
        )
      }))

    newAttributes.length > 0 &&
      (await addAttributes({
        ...baseVariables,
        attributes: JSON.stringify(
          newAttributes.map(k => ({
            [k]: attribute[k]
          }))
        )
      }))
  }
}

export default useUpsertAttributes
