import {
  RuleParameter,
  RuleParameterType,
  RuleConditionInputSingle,
  RuleParameterInput,
  ConditionData,
  AssetAndBalance
} from 'components/product-configuration/rules/types'

export const conditionParameterToInput = ({ name, value, type }: RuleParameter): RuleParameterInput => {
  switch (type) {
    case RuleParameterType.Text:
      return {
        name: name as string,
        stringValues: value as string[]
      }

    case RuleParameterType.Enum:
      return {
        name: name as string,
        enumValues: value as string[]
      }

    case RuleParameterType.Decimal:
      return {
        name: name as string,
        bigDecimalValues: value as number[]
      }

    case RuleParameterType.Amount:
      return value
        ? {
            name: name as string,
            amountValues: [
              {
                // TODO fix this by linking parameter type to value type
                // @ts-ignore
                asset: value.asset,
                // @ts-ignore
                balance: value.quantity
              } as AssetAndBalance
            ]
          }
        : {
            name: name as string,
            amountValues: []
          }

    case RuleParameterType.Long:
      return {
        name: name as string,
        longValues: value as number[]
      }

    default:
      throw new Error(`${type} is not a known input type`)
  }
}

export const conditionToSingleInput = ({
  conditionGroup,
  conditionProperty,
  conditionOperator,
  conditionParameters
}: ConditionData): RuleConditionInputSingle => ({
  property: {
    name: conditionProperty as string,
    group: conditionGroup as string
  },
  operator: {
    name: conditionOperator as string,
    parameters: conditionParameters.map(conditionParameterToInput)
  }
})
