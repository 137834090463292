import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const APPROVE_VERSION = gql`
  mutation($productId: String!, $versionNumber: Int!) {
    approveProductVersion(productId: $productId, versionNumber: $versionNumber) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default APPROVE_VERSION
