import React from 'react'
import useValidationSubscription from 'hooks/useValidationSubscription'
import FiglooTextInputController from 'components/product-configuration/FiglooTextInputController'
import { ValidationResult, Valid, Invalid } from 'hooks/useFormHandler'

interface Props {
  testId?: string
}

const ProductNameInput = ({ testId }: Props) => {
  const name = 'name'

  const validate = (formValues: Record<string, unknown>): ValidationResult =>
    formValues[name] ? Valid : Invalid('Please enter a product name')

  useValidationSubscription(name, validate)

  return <FiglooTextInputController name={name} testId={testId} />
}
export default ProductNameInput
