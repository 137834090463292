import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const ADD_FILES = gql`
  mutation($productId: String!, $versionNumber: Int!, $files: [ProductVersionFileInput!]!) {
    addFilesToProductVersion(productId: $productId, files: $files, versionNumber: $versionNumber) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default ADD_FILES
