import React, { useContext } from 'react'
import { GoogleLogin } from 'react-google-login'
import { Redirect } from 'react-router-dom'
import { Box, Flex, Image, Text } from '@11FSFoundry/figloo'
import FoundryLogo from 'images/foundry-logo.svg'
import { AuthContext } from 'components/auth/Auth'
import Auth0Button from 'components/auth/Auth0Button'
import FoundryOAuthSender from './FoundryOAuthSender'

const Login = () => {
  const { tokenData, loginSuccess, loginFailure, returnTo } = useContext(AuthContext)
  const { REACT_APP_AUTH_PROVIDERS } = process.env

  function isAuthProviderEnabled(authProvider: string): boolean {
    return (REACT_APP_AUTH_PROVIDERS || '')
      .split(',')
      .map((provider: string) => provider.trim().toLowerCase())
      .includes(authProvider.toLowerCase())
  }

  return (
    <Flex flex={1} flexDirection="column">
      <Flex height="70px" backgroundColor="#efefef" />
      {tokenData ? (
        <Redirect to={returnTo || '/'} />
      ) : (
        <Flex justifyContent="center" alignItems="center" flexDirection="column" flex={1}>
          <Image src={FoundryLogo} alt="foundry logo" padding={2} />
          <Box paddingLeft={2}>
            <h1>Please sign in </h1>

            <Text py={2}>Sign in using one of the below auth providers </Text>

            {isAuthProviderEnabled('auth0') ? <Auth0Button /> : ''}

            {isAuthProviderEnabled('foundry') ? <FoundryOAuthSender /> : ''}

            {isAuthProviderEnabled('google') ? (
              <div className="google-auth-login">
                <GoogleLogin
                  clientId="230797615524-rac1uqsk20994lcf8egc98klltpm3scp.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={response => {
                    if (
                      // @ts-ignore
                      response.profileObj.email.includes('11fs.com') ||
                      // @ts-ignore
                      response.profileObj.email.includes('11fsfoundry.com')
                    ) {
                      return loginSuccess({
                        // @ts-ignore
                        tokenId: response.tokenId,
                        // @ts-ignore
                        name: response.profileObj.name,
                        // @ts-ignore
                        dateOfBirth: '',
                        // @ts-ignore
                        photo: response.profileObj.imageUrl,
                        // @ts-ignore
                        email: response.profileObj.email,
                        // @ts-ignore
                        externalUserId: response.googleId,
                        source: 'google'
                      })
                    }
                    return loginFailure()
                  }}
                  onFailure={loginFailure}
                />
              </div>
            ) : (
              ''
            )}
          </Box>
        </Flex>
      )}
      <Flex
        height="70px"
        backgroundColor="#acacac"
        fontSize="22px"
        color="#222"
        justifyContent="center"
        alignItems="center">
        Contact us: <a href="mailto:support.foundry@11fs.com">support.foundry@11fs.com</a>
      </Flex>
    </Flex>
  )
}

export default Login
