import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const DELETE_FILE = gql`
  mutation($productId: String!, $versionNumber: Int!, $fileIds: [String!]!) {
    deleteFilesFromProductVersion(productId: $productId, versionNumber: $versionNumber, fileIds: $fileIds) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default DELETE_FILE
