import React from 'react'
import { RadioField, Flex } from '@11FSFoundry/figloo'

interface Props {
  disabled: boolean
  onChange: (months: number) => void
  value: number
}

export const BillingInterval = ({ disabled, onChange, value }: Props) => {
  const options = [1, 2, 3, 6, 12]
  const availableOptions = [1]

  return (
    <Flex flexDirection="column">
      {options.map(option => (
        <RadioField
          data-testid="billingInterval"
          key={option.toString()}
          value={option.toString()}
          name="interval"
          label={`${option} ${option === 1 ? 'month' : 'months'}`}
          m={1}
          disabled={disabled || !availableOptions.includes(option)}
          checked={value === option}
          onChange={() => onChange(option)}
        />
      ))}
    </Flex>
  )
}

export default BillingInterval
