import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import {
  DecisionRuleActionType,
  DecisionRuleAction,
  DecisionRuleState
} from 'components/product-configuration/rules/decision-rules/types'
import { ConditionActionType, RuleParameter } from 'components/product-configuration/rules/types'

const reorder = (state: DecisionRuleState, oldIndex: number, newIndex: number) => {
  const movee = state.conditions.slice(oldIndex, oldIndex + 1)[0]
  return update(state, {
    conditions: {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, movee]
      ]
    }
  })
}

const setGroup = (state: DecisionRuleState, conditionGroup: string, index: number) =>
  update(state, {
    conditions: {
      [index]: {
        $merge: {
          conditionGroup,
          conditionProperty: undefined,
          conditionOperator: undefined,
          conditionParameters: []
        }
      }
    }
  })

const setProperty = (state: DecisionRuleState, conditionProperty: string, index: number) =>
  update(state, {
    conditions: {
      [index]: {
        $merge: {
          conditionProperty,
          conditionOperator: undefined,
          conditionParameters: []
        }
      }
    }
  })

const setOperator = (state: DecisionRuleState, conditionOperator: string, index: number) =>
  update(state, {
    conditions: {
      [index]: {
        $merge: {
          conditionOperator,
          conditionParameters: []
        }
      }
    }
  })

const setParameter = (
  state: DecisionRuleState,
  parameter: RuleParameter,
  conditionIndex: number,
  parameterIndex: number
) => {
  return update(state, {
    conditions: {
      [conditionIndex]: {
        conditionParameters: {
          [parameterIndex]: {
            $set: parameter
          }
        }
      }
    }
  })
}

const setResult = (
  state: DecisionRuleState,
  result: RuleParameter,
  conditionIndex: number,
  resultIndex: number
) => {
  return update(state, {
    conditions: {
      [conditionIndex]: {
        result: {
          [resultIndex]: {
            $set: result
          }
        }
      }
    }
  })
}

const setDefaultResult = (state: DecisionRuleState, result: RuleParameter, resultIndex: number) => {
  return update(state, {
    defaultResult: {
      [resultIndex]: {
        $set: result
      }
    }
  })
}

const addBlankCondition = (state: DecisionRuleState) => {
  return update(state, {
    conditions: {
      $push: [
        {
          key: uuidv4(),
          conditionGroup: undefined,
          conditionProperty: undefined,
          conditionOperator: undefined,
          conditionParameters: [],
          result: []
        }
      ]
    }
  })
}

const deleteCondition = (state: DecisionRuleState, index: number) =>
  update(state, {
    conditions: {
      $splice: [[index, 1]]
    }
  })

const reducer = (state: DecisionRuleState, action: DecisionRuleAction) => {
  switch (action.type) {
    case DecisionRuleActionType.REORDER:
      return reorder(state, action.oldIndex, action.newIndex)
    case ConditionActionType.SET_GROUP:
      return setGroup(state, action.conditionGroup, action.index)
    case ConditionActionType.SET_PROPERTY:
      return setProperty(state, action.conditionProperty, action.index)
    case ConditionActionType.SET_OPERATOR:
      return setOperator(state, action.conditionOperator, action.index)
    case ConditionActionType.SET_PARAMETER:
      return setParameter(
        state,
        {
          value: action.value,
          name: action.name,
          type: action.dataType
        },
        action.conditionIndex,
        action.parameterIndex
      )
    case ConditionActionType.DELETE_CONDITION:
      return deleteCondition(state, action.index)
    case DecisionRuleActionType.SET_RESULT:
      return setResult(
        state,
        {
          value: action.value,
          name: action.name,
          type: action.dataType
        },
        action.conditionIndex,
        action.parameterIndex
      )
    case DecisionRuleActionType.SET_DEFAULT_RESULT:
      return setDefaultResult(
        state,
        {
          value: action.value,
          name: action.name,
          type: action.dataType
        },
        action.parameterIndex
      )
    case ConditionActionType.ADD_BLANK_CONDITION:
      return addBlankCondition(state)
    default:
      return state
  }
}

export default reducer
