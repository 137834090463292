import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const DELETE_NOTE = gql`
  mutation($productId: String!, $versionNumber: Int!, $noteName: String!) {
    product(productId: $productId) {
      removeNote(versionNumber: $versionNumber, noteName: $noteName) {
        ...product
      }
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default DELETE_NOTE
