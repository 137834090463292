import update from 'immutability-helper'
import { generateBlankCondition } from 'components/product-configuration/rules/taco-rules/TacoRules'
import {
  TacoAction,
  TacoRulesActionType,
  TacoRuleState,
  LogicalOperator
} from 'components/product-configuration/rules/taco-rules/types'
import { RuleParameter, ConditionActionType } from 'components/product-configuration/rules/types'

const deleteCondition = (state: TacoRuleState, index: number) => {
  const { conditions } = state
  // if condition being deleted is the only item in the list
  const newConditions =
    !index && conditions.length === 1
      ? // replace condition with blank condition rather than deleting it
        // since we need an initial condition object to be present
        [generateBlankCondition()]
      : // otherwise filter out condition being deleted
        conditions.filter((_, currentIndex) => index !== currentIndex)

  const output: TacoRuleState = { ...state, conditions: newConditions }

  return output
}

const setGroup = (state: TacoRuleState, conditionGroup: string, index: number) => {
  return update<TacoRuleState>(state, {
    conditions: {
      [index]: {
        $merge: {
          conditionGroup,
          conditionProperty: undefined,
          conditionOperator: undefined,
          conditionParameters: []
        }
      }
    }
  })
}

const setProperty = (state: TacoRuleState, conditionProperty: string, index: number) => {
  return update<TacoRuleState>(state, {
    conditions: {
      [index]: {
        $merge: {
          conditionProperty,
          conditionOperator: undefined,
          conditionParameters: []
        }
      }
    }
  })
}

const setOperator = (state: TacoRuleState, conditionOperator: string, index: number) => {
  return update<TacoRuleState>(state, {
    conditions: {
      [index]: {
        $merge: {
          conditionOperator,
          conditionParameters: []
        }
      }
    }
  })
}

const addBlankCondition = (state: TacoRuleState) => {
  return update<TacoRuleState>(state, {
    conditions: {
      $push: [generateBlankCondition()]
    }
  })
}

const setParameter = (
  state: TacoRuleState,
  parameter: RuleParameter,
  conditionIndex: number,
  parameterIndex: number
) => {
  return update<TacoRuleState>(state, {
    conditions: {
      [conditionIndex]: {
        conditionParameters: {
          [parameterIndex]: {
            $set: parameter
          }
        }
      }
    }
  })
}

const setRuleName = (state: TacoRuleState, ruleName: string) => {
  return update<TacoRuleState>(state, {
    $merge: {
      ruleName
    }
  })
}

const setRuleDescription = (state: TacoRuleState, ruleDescription: string) => {
  return update<TacoRuleState>(state, {
    $merge: {
      ruleDescription
    }
  })
}

const setTriggerName = (state: TacoRuleState, triggerName: string) => {
  return update<TacoRuleState>(state, {
    $merge: {
      triggerName
    }
  })
}

const setActionName = (state: TacoRuleState, actionName: string) => {
  return update<TacoRuleState>(state, {
    $merge: {
      actionName
    }
  })
}

const setActionParameter = (state: TacoRuleState, parameter: RuleParameter, parameterIndex: number) => {
  return update<TacoRuleState>(state, {
    actionParams: {
      [parameterIndex]: {
        $set: parameter
      }
    }
  })
}

const clearActionParams = (state: TacoRuleState) => {
  return update<TacoRuleState>(state, {
    actionParams: {
      $set: []
    }
  })
}

const setParametersValid = (state: TacoRuleState, parametersAreValid: boolean, index: number) => {
  return update<TacoRuleState>(state, {
    conditions: {
      [index]: {
        $merge: {
          parametersAreValid
        }
      }
    }
  })
}

const setConditionLogicalOperator = (state: TacoRuleState, value: LogicalOperator) => {
  return update<TacoRuleState>(state, {
    $merge: {
      conditionLogicalOperator: value
    }
  })
}

const reducer = (state: TacoRuleState, action: TacoAction) => {
  switch (action.type) {
    case ConditionActionType.SET_GROUP:
      return setGroup(state, action.conditionGroup, action.index)
    case ConditionActionType.SET_PROPERTY:
      return setProperty(state, action.conditionProperty, action.index)
    case ConditionActionType.SET_OPERATOR:
      return setOperator(state, action.conditionOperator, action.index)
    case ConditionActionType.SET_PARAMETER:
      return setParameter(
        state,
        {
          value: action.value,
          name: action.name,
          type: action.dataType
        },
        action.conditionIndex,
        action.parameterIndex
      )
    case ConditionActionType.DELETE_CONDITION:
      return deleteCondition(state, action.index)
    case ConditionActionType.ADD_BLANK_CONDITION:
      return addBlankCondition(state)

    case TacoRulesActionType.SET_RULE_NAME:
      return setRuleName(state, action.ruleName)
    case TacoRulesActionType.SET_RULE_DESCRIPTION:
      return setRuleDescription(state, action.ruleDescription)
    case TacoRulesActionType.SET_TRIGGER_NAME:
      return setTriggerName(state, action.triggerName)
    case TacoRulesActionType.SET_ACTION_NAME:
      return setActionName(state, action.actionName)
    case TacoRulesActionType.SET_ACTION_PARAMETER:
      return setActionParameter(
        state,
        {
          value: action.value,
          name: action.name,
          type: action.dataType
        },
        action.parameterIndex
      )
    case TacoRulesActionType.CLEAR_ACTION_PARAMETERS:
      return clearActionParams(state)
    case TacoRulesActionType.SET_PARAMETER_VALID:
      return setParametersValid(state, action.valid, action.index)
    case TacoRulesActionType.SET_CONDITION_LOGICAL_OPERATOR:
      return setConditionLogicalOperator(state, action.value)

    default:
      return state
  }
}

export default reducer
