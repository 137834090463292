import React, { useContext, useEffect, useRef, useState } from 'react'
import { Text, Flex, IconEye, IconEdit, IconView, theme, Image, Box, Link } from '@11FSFoundry/figloo'
import { ProductVersion, VersionStatus } from 'types'
import { AuthContext } from 'components/auth/Auth'
import { formatUser } from 'util/format'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import IconEllipsis from 'images/icon-ellipsis.svg'
import { Link as RouterLink } from 'react-router-dom'
import useOpenApplications from 'gql/useOpenApplications'
import usePauseApplications from 'gql/usePauseApplications'
import { notifyError, notifySuccess } from 'components/Toast'
import useOutsideAlerter from 'hooks/useOutsideAlerter'

interface Props {
  version: ProductVersion
  productId: string
  currentLiveVersion?: ProductVersion | undefined
  archiveVersion: () => void
}

const getParams = (status: VersionStatus, iAmReviewer: boolean) => {
  switch (status) {
    case 'DRAFT':
      return {
        tabIcon: <IconEdit />,
        flagLabel: 'Draft',
        backgroundColor: theme.colors.white
      }
    case 'REJECTED':
      return {
        tabIcon: <IconEdit />,
        flagLabel: 'Rejected',
        backgroundColor: theme.colors.white
      }

    case 'UNDER_REVIEW':
      return {
        tabIcon: iAmReviewer ? <IconEye color="copyOne" /> : <IconView />,
        flagLabel: 'In review',
        backgroundColor: iAmReviewer ? theme.colors.mintIce : theme.colors.white
      }

    case 'APPROVED':
      return {
        tabIcon: <IconView />,
        flagLabel: 'Approved',
        backgroundColor: theme.colors.white
      }

    case 'APPLICATIONS_OPEN':
      return {
        tabIcon: <IconView />,
        flagLabel: 'Applications Open',
        backgroundColor: theme.colors.white
      }

    case 'APPLICATIONS_PAUSED':
      return {
        tabIcon: <IconView />,
        flagLabel: 'Applications Paused',
        backgroundColor: theme.colors.white
      }

    case 'ARCHIVED':
      return {
        tabIcon: <IconView />,
        flagLabel: 'Archived',
        backgroundColor: theme.colors.white
      }

    default:
      throw new Error(`Unkown status: ${status}`)
  }
}

type ActionPermissionTypes = {
  canDelete: boolean
  canEdit: boolean
  canActivate: boolean
  canDeactivate: boolean
}

enum APPLICATIONS_ACTION_TYPE {
  OPEN_APPLICATIONS = 'OPEN_APPLICATIONS',
  PAUSE_APPLICATIONS = 'PAUSE_APPLICATIONS'
}

interface ActionsMenuProps {
  actionPermissions: ActionPermissionTypes
  setActionsMenu: React.Dispatch<React.SetStateAction<boolean>>
  version: ProductVersion
  productId: string
  currentLiveVersion: ProductVersion | undefined
  handleApplicationAction: (type: string, versionNumber: number) => Promise<void>
  archiveVersion: () => void
}

function ActionsMenu({
  actionPermissions,
  setActionsMenu,
  productId,
  version,
  handleApplicationAction,
  archiveVersion
}: ActionsMenuProps) {
  const wrapperRef = useRef(null)
  const [focused] = useOutsideAlerter(wrapperRef)
  const { canEdit, canDelete, canActivate, canDeactivate } = actionPermissions

  useEffect(() => {
    if (!focused) setActionsMenu(false)
  }, [focused, setActionsMenu])

  return (
    <Box
      ref={wrapperRef}
      sx={{
        boxShadow: '#22222225 0px 0px 10px 0px',
        position: 'absolute',
        top: 53,
        right: 30,
        zIndex: 2,
        ':before': {
          content: '""',
          position: 'absolute',
          right: '6px',
          top: '-15px',
          width: 0,
          height: 0,
          borderTop: '8px solid transparent',
          borderLeft: '8px solid white',
          borderRight: '8px solid transparent',
          borderBottom: '8px solid transparent ',
          transform: 'rotate(-90deg)'
        }
      }}
      px={3}
      pt={2}
      bg="white">
      <Flex alignItems="center" flexDirection="column">
        <Link as={RouterLink} to={`/products/${productId}/versions/${version.number}`} variant="default" pb={2}>
          {canEdit ? <Text>Edit</Text> : <Text>View</Text>}
        </Link>
        {canDeactivate && (
          <Text
            style={{ cursor: 'pointer' }}
            pb={1}
            onClick={() => {
              setActionsMenu(false)
              handleApplicationAction(APPLICATIONS_ACTION_TYPE.PAUSE_APPLICATIONS, version.number)
            }}>
            Pause
          </Text>
        )}
        {canActivate && (
          <Text
            style={{ cursor: 'pointer' }}
            pb={2}
            onClick={() => {
              setActionsMenu(false)
              handleApplicationAction(APPLICATIONS_ACTION_TYPE.OPEN_APPLICATIONS, version.number)
            }}>
            Set live
          </Text>
        )}
        {canDelete && (
          <Text
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setActionsMenu(false)
              archiveVersion()
            }}
            color="cherry"
            pb={2}>
            Delete
          </Text>
        )}
      </Flex>
    </Box>
  )
}

const VersionBlock = ({ version, productId, currentLiveVersion, archiveVersion }: Props) => {
  const [actionsMenu, setActionsMenu] = useState(false)
  const hasArchiveProductVersionPermission = useCheckPermissions('archive:product-versions')
  const hasVersionOpenAndPausePermission = useCheckPermissions('update-applications-state:product-versions')
  const hasUpdateProductVersionPermission = useCheckPermissions('update:product-versions')
  const [openApplications] = useOpenApplications()
  const [pauseApplications] = usePauseApplications()
  const archiveProductVersionDisabled = !hasArchiveProductVersionPermission || !(version.status === 'DRAFT')
  const { tokenData } = useContext(AuthContext)
  const { note, number, status, review } = version
  const source = tokenData && tokenData.source
  const externalUserId = tokenData && tokenData.externalUserId
  const iAmReviewer = !!(review && tokenData && review.reviewer === `foundry:identity:user:${source}:${externalUserId}`)
  const { flagLabel, backgroundColor } = getParams(status, iAmReviewer)

  const actionPermissions = {
    canDelete: !archiveProductVersionDisabled || status === 'ARCHIVED' || status === 'APPROVED',
    canEdit: hasUpdateProductVersionPermission && status === 'DRAFT',
    canActivate:
      hasVersionOpenAndPausePermission &&
      (status === 'APPROVED' || status === 'APPLICATIONS_PAUSED') &&
      !currentLiveVersion,
    canDeactivate: hasVersionOpenAndPausePermission && status === 'APPLICATIONS_OPEN'
  }

  const handleApplicationAction = async (type: string, versionNumber: number) => {
    try {
      if (type === APPLICATIONS_ACTION_TYPE.OPEN_APPLICATIONS) {
        await openApplications({ variables: { productId, versionNumber } })
        notifySuccess('Accepting Applications')
      } else if (type === APPLICATIONS_ACTION_TYPE.PAUSE_APPLICATIONS) {
        await pauseApplications({ variables: { productId, versionNumber } })
        notifySuccess('Paused Applications')
      }
    } catch (error) {
      notifyError('Application update failed')
    }
  }

  return (
    <Flex
      height={72}
      width={1}
      mb={2}
      alignItems="center"
      backgroundColor={backgroundColor}
      data-testid="versionBlock"
      style={{ position: 'relative' }}>
      <Text flex={2} pl={24} fontSize={16} data-testid="versionNumber">
        {number.toString().padStart(3, '0')}
      </Text>
      <Text flex={4} fontSize={16} data-testid="versionName">
        {note}
      </Text>
      <Flex flex={2}>
        <Text css={theme.text.circle} backgroundColor={theme.colors.tagBanana}>
          E
        </Text>
      </Flex>
      <Flex flex={2}>
        {version.review ? (
          <Text css={theme.text.circle} backgroundColor={theme.colors.tagPeach}>
            {formatUser(version.review.reviewer)}
          </Text>
        ) : (
          <div />
        )}
      </Flex>
      <Text flex={2} data-testid={`versionState-${flagLabel}`}>
        {flagLabel}
      </Text>
      <Flex
        style={{ cursor: 'pointer' }}
        width="100%"
        height="100%"
        alignItems="center"
        onClick={() => {
          setActionsMenu(!actionsMenu)
        }}
        flex={1}
        justifyContent="center">
        <Image src={IconEllipsis} height={24} width={24} />
      </Flex>
      {actionsMenu && (
        <ActionsMenu
          actionPermissions={actionPermissions}
          version={version}
          productId={productId}
          setActionsMenu={setActionsMenu}
          currentLiveVersion={currentLiveVersion}
          handleApplicationAction={handleApplicationAction}
          archiveVersion={() => {
            archiveVersion()
          }}
        />
      )}
    </Flex>
  )
}

export default VersionBlock
