import React, { ReactNode } from 'react'
import { NavLinkProps, NavLink } from 'react-router-dom'
import { Flex, theme } from '@11FSFoundry/figloo'

interface Props extends NavLinkProps {
  children: ReactNode | string
  testId?: string
}

const NavBarItem = ({ children, testId, ...rest }: Props) => (
  <Flex pr={4} alignItems="center">
    <NavLink
      style={{ color: theme.colors.navInactive }}
      activeStyle={{ color: theme.colors.navActive }}
      {...rest}
      data-testid={testId}>
      {children}
    </NavLink>
  </Flex>
)

export default NavBarItem
