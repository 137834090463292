import React, { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { GET_PRODUCT } from 'gql/GET_PRODUCT'
import { ProductVersion } from 'types'
import { ProductVersionProvider } from 'components/product-configuration/ProductVersion'

interface Props {
  children: ReactNode
}

const ProductVersionController = ({ children }: Props) => {
  const { productId, versionNumber } = useParams()

  const { loading, error, data, refetch, networkStatus } = useQuery<{
    product: {
      id: string
      name: string
      versions: ProductVersion[]
    }
  }>(GET_PRODUCT, {
    variables: { productId },
    fetchPolicy: 'cache-and-network'
  })

  if (loading && networkStatus === 1) return <p>Loading</p>
  if (error) return <p>Error</p>
  if (!data) return <p>Product not found</p>

  const { product } = data
  const version = product.versions.find(({ number }) => Number(versionNumber) === number)

  if (!version) return <p>Product version not found</p>

  return (
    <ProductVersionProvider product={product} version={version} refetch={refetch}>
      {children}
    </ProductVersionProvider>
  )
}

export default ProductVersionController
