import React, { useEffect, useState } from 'react'

const useOutsideAlerter = (ref: React.MutableRefObject<null | HTMLDivElement>) => {
  const [focused, setFocused] = useState(true)
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFocused(false)
      } else {
        setFocused(true)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
  return [focused]
}

export default useOutsideAlerter
