import React, { SyntheticEvent, useContext, useState } from 'react'
import { Box, Flex, Button, Link, Text, IconDownload, IconBin } from '@11FSFoundry/figloo'
import { notifySuccess, notifyError } from 'components/Toast'
import { documentDownloadURL, useAuthenticatedDownload } from 'hooks/useAuthenticatedDownload'
import { ProductVersionFile } from 'types'
import ClickEdit from 'components/product-configuration/ClickEdit'
import Confirmation from 'components/product-configuration/Confirmation'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import useDeleteFile from 'gql/useDeleteFile'
import useEditFile from 'gql/useEditFile'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

interface Props {
  doc: ProductVersionFile
}

const DocumentRow = ({ doc, handleBinClick }: { doc: ProductVersionFile; handleBinClick: () => void }) => {
  const { product, version } = useContext(ProductVersionContext)
  const [name, setName] = useState(doc.name)

  const [editFileMutation] = useEditFile()

  const hasDeleteFilesPermission = useCheckPermissions('delete:files')
  const hasUpdateFilesPermission = useCheckPermissions('update:files')
  const productVersionEditable = useProductVersionEditable()
  const deleteDocumentEnabled = hasDeleteFilesPermission && productVersionEditable
  const changeDocumentDisabled = !hasUpdateFilesPermission || !productVersionEditable

  const authenticatedDownload = useAuthenticatedDownload()

  const download = (docToDownload: ProductVersionFile) => async (e: SyntheticEvent) => {
    e.preventDefault()
    authenticatedDownload(documentDownloadURL(docToDownload.url), docToDownload.fileName)
  }

  const renameDocument = async (docToRename: ProductVersionFile, newName: string) => {
    try {
      await editFileMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          input: {
            fileId: docToRename.id,
            name: newName,
            description: docToRename.description // editing descriptions not currently in UI
          }
        }
      })

      notifySuccess('Document updated')
    } catch (error) {
      notifyError('Updating document failed')
    }
  }

  return (
    <>
      <Flex px={32} alignItems="center" justifyContent="center">
        <Link href="#" onClick={download(doc)}>
          <IconDownload />
        </Link>
      </Flex>
      <Box flex={1} pl={40} pr={54}>
        <ClickEdit
          disabled={changeDocumentDisabled}
          handleChange={value => setName(value)}
          handleCommit={() => renameDocument(doc, name)}
          value={name}>
          <Text color="copyOne">{doc.name || 'File reference'}</Text>
        </ClickEdit>
      </Box>
      <Text flex={2}>{doc.fileName}</Text>
      <Text flex={1}>{/* mime type/extension should go here, but is not currently in graph schema */}</Text>
      {deleteDocumentEnabled && (
        <Button data-testid="deleteDocument" variant="icon" as="div" onClick={handleBinClick}>
          <IconBin color="cherry" />
        </Button>
      )}
    </>
  )
}

const DeleteConfirmation = ({
  doc,
  setDeleting
}: {
  doc: ProductVersionFile
  setDeleting: (deleting: boolean) => void
}) => {
  const { product, version } = useContext(ProductVersionContext)

  const [deleteFileMutation] = useDeleteFile()

  const deleteDocument = async () => {
    try {
      await deleteFileMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          fileIds: [doc.id]
        }
      })

      setDeleting(false)

      notifySuccess('Document deleted')
    } catch (error) {
      notifyError('Deleting document failed')
    }
  }

  return (
    <Confirmation setConfirming={setDeleting} handleConfirm={deleteDocument}>
      <Text>
        Are you sure you want to delete <strong>{doc.name}</strong> ({doc.fileName})?
      </Text>
    </Confirmation>
  )
}

const ExistingDocument = ({ doc }: Props) => {
  const [deleting, setDeleting] = useState(false)

  return (
    <Flex height={88} alignItems="center" backgroundColor="white" mb={1} key={doc.id} pr={32}>
      {deleting ? (
        <DeleteConfirmation setDeleting={setDeleting} doc={doc} />
      ) : (
        <DocumentRow doc={doc} handleBinClick={() => setDeleting(true)} />
      )}
    </Flex>
  )
}

export default ExistingDocument
