import React, { ChangeEvent } from 'react'
import { Amount, Select, Input, MultiTagsSelect, ListBox, Box, Flex, AmountType, Text } from '@11FSFoundry/figloo'
import {
  RuleParameterDefinition,
  RuleParameterType,
  ParameterActionData,
  RuleParameterValue
} from 'components/product-configuration/rules/types'
import AssetInput from 'components/product-configuration/AssetInput'

interface Props {
  parameterIndex: number
  parameterDefinition: RuleParameterDefinition
  parameterValue: RuleParameterValue
  disabled?: boolean
  handleChange: (parameterActionData: ParameterActionData) => void
  testId?: string
}

const Parameter = ({ parameterDefinition, parameterValue, parameterIndex, disabled, handleChange, testId }: Props) => {
  const { type, options, name, arity, label, unit } = parameterDefinition

  switch (type) {
    case 'amount':
      return (
        <Amount
          data-testid={testId}
          disabled={disabled}
          assets={['GBP', 'NOK', 'USD']}
          value={parameterValue as AmountType}
          onChange={newValue =>
            handleChange({
              name,
              value: newValue,
              dataType: RuleParameterType.Amount,
              parameterIndex
            })
          }
        />
      )

    case 'string': {
      const multiSelect = ['OneOrMany', 'ZeroOrMany'].includes(arity)

      if (multiSelect) {
        if (options.length) {
          return (
            <MultiTagsSelect
              data-testid={testId}
              initialItems={(parameterValue as string[] | undefined)?.map(s => ({
                label: s,
                value: s
              }))}
              options={options.map(o => ({
                label: o.description ? `${o.label} - ${o.description}` : o.label,
                value: o.name
              }))}
              disabled={disabled}
              onItemsChange={selectedOptions => {
                // TODO temp fix for Downshift misbehaving
                // https://github.com/downshift-js/downshift/issues/962
                setTimeout(() => {
                  handleChange({
                    name,
                    value: selectedOptions.map(o => o.value),
                    dataType: RuleParameterType.Enum,
                    parameterIndex
                  })
                }, 0)
              }}
            />
          )
        }
        // creatable: user can add values
        return (
          <MultiTagsSelect
            data-testid={testId}
            creatable
            initialItems={(parameterValue as string[] | undefined)?.map(s => ({
              label: s,
              value: s
            }))}
            disabled={disabled}
            onItemsChange={selectedOptions =>
              // TODO temp fix for Downshift misbehaving
              // https://github.com/downshift-js/downshift/issues/962
              setTimeout(() => {
                handleChange({
                  name,
                  value: selectedOptions.map(o => o.value),
                  dataType: RuleParameterType.Text,
                  parameterIndex
                })
              }, 0)
            }
          />
        )
      }

      const stringParameters = parameterValue as string[] | undefined

      // single case, arity = "ZeroOrOne" or "One"
      if (options.length) {
        // big number of options = use searchable ListBox
        if (options.length > 15) {
          const initialValue = stringParameters && options.find(o => o.name === stringParameters[0])
          return (
            <Box minWidth={200}>
              <ListBox
                data-testid={testId}
                disabled={disabled}
                options={options.map(o => ({
                  label: o.description ? `${o.label} - ${o.description}` : o.label,
                  value: o.name
                }))}
                initialValue={
                  initialValue && {
                    label: initialValue.description
                      ? `${initialValue.label} - ${initialValue.description}`
                      : initialValue.label,
                    value: initialValue.name
                  }
                }
                onValueChange={selectedOption =>
                  // TODO temp fix for Downshift misbehaving
                  // https://github.com/downshift-js/downshift/issues/962
                  setTimeout(() => {
                    handleChange({
                      name,
                      value: selectedOption ? [selectedOption] : [],
                      dataType: RuleParameterType.Enum,
                      parameterIndex
                    })
                  }, 0)
                }
              />
            </Box>
          )
        }
        // small number of options = use simple Select
        return (
          <Select
            data-testid={testId}
            disabled={disabled}
            value={stringParameters && stringParameters[0]}
            onChange={({ target }) =>
              handleChange({
                name,
                value: [target.value],
                dataType: RuleParameterType.Enum,
                parameterIndex
              })
            }>
            <option value="">Please select...</option>
            {options.map(optionValue => (
              <option value={optionValue.name} key={optionValue.name}>
                {optionValue.label}
                {optionValue.description && `- ${optionValue.description}`}
              </option>
            ))}
          </Select>
        )
      }

      return (
        <Input
          data-testid={testId}
          disabled={disabled}
          width="200px"
          placeholder={label}
          value={stringParameters ? stringParameters[0] : []}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            handleChange({
              name,
              value: [target.value],
              dataType: RuleParameterType.Text,
              parameterIndex
            })
          }
        />
      )
    }

    case 'long':
      return (
        <Flex alignItems="center">
          {unit?.affix === 'Prefix' && <Text mr={1}>{unit.symbol}</Text>}
          <Input
            data-testid={testId}
            type="number"
            disabled={disabled}
            width="128px"
            placeholder={label}
            value={parameterValue ? (parameterValue as number[])[0] : []}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
              handleChange({
                name,
                value: [parseInt(target.value, 10)],
                dataType: RuleParameterType.Long,
                parameterIndex
              })
            }
          />
          {unit?.affix === 'Suffix' && <Text ml={1}>{unit.symbol}</Text>}
        </Flex>
      )

    case 'decimal':
      if (['One', 'ZeroOrOne'].includes(arity)) {
        return (
          <Flex alignItems="center">
            {unit?.affix === 'Prefix' && <Text mr={1}>{unit.symbol}</Text>}
            <Input
              type="number"
              pattern="[0-9]+([,.][0-9]+)?"
              disabled={disabled}
              width="128px"
              placeholder={label}
              value={parameterValue ? (parameterValue as number[])[0] : []}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                const { valueAsNumber } = target

                handleChange({
                  name,
                  value: [Number.isNaN(valueAsNumber) ? undefined : valueAsNumber],
                  dataType: RuleParameterType.Decimal,
                  parameterIndex
                })
              }}
            />
            {unit?.affix === 'Suffix' && <Text ml={1}>{unit.symbol}</Text>}
          </Flex>
        )
      }

      return (
        <MultiTagsSelect
          data-testid={testId}
          creatable
          initialItems={(parameterValue as string[] | undefined)?.map(s => ({
            label: s,
            value: s
          }))}
          filter={(value: string) => /^(\d+\.?\d*|)$/.test(value)}
          disabled={disabled}
          onItemsChange={selectedOptions =>
            // TODO temp fix for Downshift misbehaving
            // https://github.com/downshift-js/downshift/issues/962
            setTimeout(() => {
              handleChange({
                name,
                value: selectedOptions.filter(o => !Number.isNaN(parseFloat(o.value))).map(o => parseFloat(o.value)),
                dataType: RuleParameterType.Decimal,
                parameterIndex
              })
            }, 0)
          }
        />
      )

    case 'asset':
      return (
        <Flex alignItems="center">
          <AssetInput
            onChange={(asset: string) =>
              handleChange({
                name,
                value: [asset],
                dataType: RuleParameterType.Text,
                parameterIndex
              })
            }
            disabled={disabled}
            value={parameterValue && (parameterValue as string[])[0]}
          />
        </Flex>
      )

    default:
      return (
        <Flex>
          <Text backgroundColor="red">{`Unknown input type: ${type}`}</Text>
        </Flex>
      )
  }
}

export default Parameter
