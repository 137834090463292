import React, { useState, ReactNode, ComponentType } from 'react'
import { Flex, Text, Button, theme } from '@11FSFoundry/figloo'

import ClickEdit from 'components/product-configuration/ClickEdit'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

interface Props {
  updateImage: (fieldName: string, value: string) => void
  children: ReactNode
  name?: string
  description?: string
  deleteIcon: ComponentType<{ color?: keyof typeof theme['colors'] }>
  deleteImage: () => void
  highlighted?: boolean
}

const ImageCard = ({
  children,
  updateImage,
  name = '',
  description = '',
  deleteIcon: DeleteIcon,
  deleteImage,
  highlighted
}: Props) => {
  const hasDeleteFilesPermission = useCheckPermissions('delete:files')
  const hasUpdateFilesPermission = useCheckPermissions('update:files')
  const productVersionEditable = useProductVersionEditable()
  const updateImageDisabled = !hasUpdateFilesPermission || !productVersionEditable
  const deleteImageEnabled = hasDeleteFilesPermission && productVersionEditable

  const [imageName, setImageName] = useState(name)
  const [imageDescription, setImageDescription] = useState(description)

  return (
    <Flex
      sx={{ width: 365, p: 5, mr: 3, mb: 3, bg: 'white' }}
      flexDirection="column"
      bg={highlighted ? 'tagPeach' : 'white'}>
      <Flex justifyContent="center" height={124}>
        {children}
      </Flex>
      <Flex flexDirection="column" mt={2}>
        <ClickEdit
          data-testid="editImageName"
          ml={-3}
          value={imageName}
          handleCommit={() => updateImage('name', imageName)}
          handleChange={setImageName}
          disabled={updateImageDisabled}>
          <Text py={2}>{imageName || 'Add image name'}</Text>
        </ClickEdit>
        <ClickEdit
          data-testid="editImageDescription"
          ml={-3}
          value={imageDescription}
          handleCommit={() => updateImage('description', imageDescription)}
          handleChange={setImageDescription}
          disabled={updateImageDisabled}>
          <Text py={2}>{imageDescription || 'Add image description'}</Text>
        </ClickEdit>
        <Flex justifyContent="flex-end">
          {deleteImageEnabled && (
            <Button data-testid="deleteImage" variant="icon" onClick={deleteImage}>
              <DeleteIcon color="cherry" />
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ImageCard
