import React from 'react'
import { Route, Switch } from 'react-router-dom'
import FoundryOAuthReceiver from 'components/auth/FoundryOAuthReceiver'
import ProductVersions from 'components/product-configuration/ProductVersions'
import ProductVersionController from 'components/product-configuration/ProductVersionController'
import ProductGallery from 'components/product-configuration/ProductGallery'
import LoginFailed from 'components/auth/LoginFailed'
import CreateProductController from 'components/product-configuration/create-product/CreateProductController'
import AuthRoute from 'components/auth/AuthRoute'
import Login from 'components/auth/Login'
import Auth0Receiver from 'components/auth/Auth0Receiver'
import ProductVersionRoutes from 'components/product-configuration/ProductVersionRoutes'
import CustomerSearch from 'components/customer-service/SearchPage'
import CustomerAccounts from 'components/customer-service/AccountsPage'
import CustomerAccount from 'components/customer-service/AccountPage'
import ApplicationsPage from 'components/product-configuration/ApplicationsPage'
import HomePage from 'components/HomePage'

import 'styles/App.css'

const App = () => (
  <Switch>
    <AuthRoute exact path="/" component={HomePage} />
    <AuthRoute exact path="/products" component={ProductGallery} />
    <AuthRoute exact path="/products/create" component={CreateProductController} />
    <AuthRoute
      path="/products/:productId/versions/:versionNumber"
      render={() => (
        <ProductVersionController>
          <ProductVersionRoutes />
        </ProductVersionController>
      )}
    />
    <Route exact path="/products/:productId/applications" component={ApplicationsPage} />
    <AuthRoute exact path="/products/:productId" component={ProductVersions} />
    <AuthRoute exact path="/customers/search" component={CustomerSearch} />
    <AuthRoute exact path="/customers/customer/:customerId" component={CustomerAccounts} />
    <AuthRoute exact path="/customers/customer/:customerId/account/:accountId" component={CustomerAccount} />
    <Route exact path="/loginFailed" component={LoginFailed} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/auth/foundry" component={FoundryOAuthReceiver} />
    <Route exact path="/auth/auth0" component={Auth0Receiver} />
  </Switch>
)
export default App
