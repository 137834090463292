import { ProductVersion, AttributeGroup } from 'types'

export const containsAttribute = (version: ProductVersion, name: string) =>
  version.attributeGroups.some((g: AttributeGroup) => g.attributes.some(a => a.name === name))

export const getAttributeValue = <A>(version: ProductVersion, name: string): A | undefined => {
  const allAttributes = version?.attributeGroups?.flatMap((g: AttributeGroup) => g.attributes)
  const attribute = allAttributes?.find(a => a.name === name)
  return attribute?.value as A
}
