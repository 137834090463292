import React, { Dispatch } from 'react'

export enum ReducerActions {
  ADD_ATTRIBUTE = 'ADD_ATTRIBUTE',
  REMOVE_ALL_ATTRIBUTES = 'REMOVE_ALL_ATTRIBUTES'
}

interface AttributeStateObject {
  label: string
  name: string
  addedToAccount: string
}

interface AttributeState {
  [step: string]: AttributeStateObject[]
}

export interface Attribute {
  label: string
  name: string
  addedToAccount: string
  step: string
}

interface AttributeAction {
  type: ReducerActions
  payload?: Attribute
}

export const initialState: AttributeState = {}

interface AttributeContextData {
  state: AttributeState
  dispatch: Dispatch<AttributeAction>
}

export const WorkflowContext = React.createContext<AttributeContextData>({ state: {}, dispatch: () => {} })

export const reducer = (state: AttributeState, action: AttributeAction) => {
  switch (action.type) {
    case ReducerActions.ADD_ATTRIBUTE:
      if (action.payload)
        return {
          ...state,
          [action.payload.step]: [
            ...(state[action.payload.step] || []),
            { label: action.payload.label, name: action.payload.name, addedToAccount: action.payload.addedToAccount }
          ]
        }
      return state
    case ReducerActions.REMOVE_ALL_ATTRIBUTES:
      return initialState
    default:
      return state
  }
}
