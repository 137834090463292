import React, { useContext, ComponentType, ReactNode, useEffect } from 'react'
import { Route, RouteProps, useLocation, RouteComponentProps, useHistory } from 'react-router-dom'
import { AuthContext } from './Auth'

interface Props {
  component?: ComponentType
  render?: (props: RouteComponentProps) => ReactNode
}

function AuthRoute({ component: Component, render, ...rest }: RouteProps & Props) {
  const location = useLocation()
  const history = useHistory()
  const { tokenData, setReturnTo } = useContext(AuthContext)
  const externalUserId = tokenData && tokenData.externalUserId

  useEffect(() => {
    if (!externalUserId) {
      setReturnTo(location.pathname)
      history.push('/login')
    }
  })

  // @ts-ignore
  return <Route {...rest} render={props => (render ? render(props) : <Component {...props} />)} />
}

export default AuthRoute
