import React, { useState } from 'react'
import { Flex, Heading, Text, Box, Link, IconAdd, theme } from '@11FSFoundry/figloo'
import { Link as RouterLink } from 'react-router-dom'
import { ProductVersion } from 'types'
import ArchiveController from 'components/product-configuration/ArchiveController'
import VersionBlock from 'components/product-configuration/VersionBlock'
import LinkButton from 'components/product-configuration/LinkButton'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import PageTitle from 'components/PageTitle'
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import NewVersionModal from './NewVersionModal'

interface Props {
  product: {
    id: string
    name: string
    description: string
    versions: ProductVersion[]
  }
  refetch: (variables?: { productId: string }) => void
}

const cssShowArchived = { margin: '20px 0 30px 0' }

const VersionList = ({ product, refetch }: Props) => {
  const hasCloneProductVersionPermission = useCheckPermissions('clone:product-versions')
  const breadcrumbs = useBreadcrumbs();
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [modal, setModal] = useState(false);
  const archivedVersions = product.versions.filter(({ status }) => status === 'ARCHIVED')
  const openVersion = product.versions.find(({ status }) => status === 'APPLICATIONS_OPEN')

  return (
    <>
    {modal && <NewVersionModal versions={product.versions} productId={product.id} setModal={setModal} />}
    <Flex flex={1} flexDirection="column" alignItems="center">
      <PageTitle>
      <Flex flexDirection="row" justifyContent="space-between" flex={1}>
        <Flex flexDirection="column"> 
        <Flex mb={3}>
          {breadcrumbs.map(({breadcrumb, key, match}, i) => {
            return (<Link key={key} as={RouterLink} to={match.url}>{i < breadcrumbs.length - 1 ? <Text>{breadcrumb}<span>&nbsp;/&nbsp;</span></Text> : <Text>{product.name}</Text>}</Link>)
          })}
          </Flex>
          <Heading variant="h1" as="h1">
            {product.name}
          </Heading>
          {product.description && <Text mt={2}>{product.description}</Text>}
        </Flex> 
        </Flex>
      </PageTitle>
      <Flex flexDirection="column" mb={6} mx={5} width={1152} data-testid="versionList">
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <Heading variant="h2" fontFamily="demi">Versions</Heading>
          {hasCloneProductVersionPermission && (
            <Flex data-testid="cloneProductVersion" onClick={() => {
              setModal(true);
            }} justifyContent="center" px={2} py={1} alignItems="center" style={{border: '2px solid #000', cursor: 'pointer'}}>
              <IconAdd color="copyOne" size={theme.fontSizes[1]} />
              <Text ml={1} variant="default" fontFamily="heavy" fontSize={1}>
                Create new version
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex  pt={3} pb={3} style={{ fontWeight: 'bold' }}>
          <Text flex={2} pl={2}>
            ID
          </Text>
          <Text flex={4}>Name</Text>
          <Text flex={2}>Editor</Text>
          <Text flex={2}>Approver</Text>
          <Text flex={2}>Status</Text>
          <Text textAlign="center" flex={1}>Action</Text>
        </Flex>
        {product.versions
          .sort((a, b) => b.number - a.number)
          .filter(({ status }) => status !== 'ARCHIVED')
          .map(version => (
            <ArchiveController key={version.number} version={version} productId={product.id} currentLiveVersion={openVersion} refetch={refetch} />
          ))}
        <Box css={cssShowArchived}>
          <LinkButton handleClick={() => setArchiveOpen(!archiveOpen)} data-testid="archiveList">
            <Box
              as="span"
              css={{ fontWeight: 500, transition: '0.30s', transform: archiveOpen ? 'rotate(90deg)' : 'rotate(0deg)' }}>
              >
            </Box>
            <Box as="span" css={{ marginLeft: '5px' }}>
              Archived versions ({archivedVersions.length})
            </Box>
          </LinkButton>
        </Box>
        <Box css={{ display: archiveOpen ? 'block' : 'none' }}>
          {archivedVersions
            .sort((a, b) => b.number - a.number)
            .map(version => (
              <VersionBlock key={version.number} version={version} productId={product.id} archiveVersion={() => {}} />
            ))}
        </Box>
      </Flex>
    </Flex>
    </>
  )
}
export default VersionList
