import React, { createContext, useState, useEffect, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from 'components/auth/Auth0'
import { UserInfo } from 'types'

interface Auth {
  tokenData?: UserInfo
  reset: () => void
  loginSuccess: (tokenData: UserInfo) => void
  // setFoundryUserId: (foundryUserId: string) => void
  loginFailure: () => void
  logout: () => void
  returnTo: string | undefined
  setReturnTo: (returnTo: string) => void
}

export const AuthContext = createContext<Auth>({
  reset: () => {},
  loginSuccess: () => {},
  // setFoundryUserId: () => {},
  loginFailure: () => {},
  logout: () => {},
  returnTo: undefined,
  setReturnTo: () => {}
})

interface Props {
  children: ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const history = useHistory()

  const [tokenData, setTokenData] = useState<UserInfo | undefined>()
  const [returnTo, setReturnTo] = useState<string>()
  const { logout: auth0Logout } = useAuth0()

  const reset = () => setTokenData(undefined)

  const loginSuccess = (incomingTokenData: UserInfo) => {
    // @ts-ignore
    window.localStorage.setItem('tokenData', JSON.stringify(incomingTokenData))
    // @ts-ignore
    window.localStorage.setItem('token', incomingTokenData.tokenId)
    setTokenData(incomingTokenData)

    history.push(returnTo || '/')
  }

  const loginFailure = () => {
    window.location.replace('/loginFailed')
  }

  const logout = () => {
    // @ts-ignore
    window.localStorage.removeItem('tokenData')
    // @ts-ignore
    window.localStorage.removeItem('token')
    if (tokenData && tokenData.source === 'auth0') {
      auth0Logout({ returnTo: `${window.location.origin}/login` })
    } else {
      window.location.replace('/')
    }
  }

  useEffect(() => {
    // @ts-ignore
    const tokenDataString = window.localStorage.getItem('tokenData')
    if (tokenDataString) setTokenData(JSON.parse(tokenDataString))
  }, [])

  return (
    <AuthContext.Provider value={{ tokenData, reset, loginSuccess, loginFailure, logout, returnTo, setReturnTo }}>
      {children}
    </AuthContext.Provider>
  )
}
