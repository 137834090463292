import React, { useContext } from 'react'

import { TextareaWithValidation } from '@11FSFoundry/figloo'
import { FormContext } from 'components/product-configuration/Form'

interface Props {
  name: string
  testId?: string
}

const FiglooTextareaController = ({ name, testId }: Props) => {
  const { handleChange, getValue, getError } = useContext(FormContext)

  const value = (getValue(name) as string) || ''
  return (
    <TextareaWithValidation
      testId={testId}
      name={name}
      onChange={event => handleChange(name, event.target.value)}
      value={value}
      errorMessage={getError(name)}
    />
  )
}

export default FiglooTextareaController
