import React, { useEffect, useCallback, useState } from 'react'
import { Flex, Link } from '@11FSFoundry/figloo'

interface SideNavItemProps {
  label: string
  href: string
  active?: boolean
}

const SideNavItem = ({ label, active, href }: SideNavItemProps) => {
  const styles = {
    lineHeight: '32px',
    fontWeight: 500,
    borderLeftWidth: '3px',
    borderLeftStyle: 'solid',
    borderLeftColor: active ? 'mintNeon' : 'gsRat',
    paddingLeft: 3
  }
  return (
    <Link as="a" sx={styles} href={href}>
      {label}
    </Link>
  )
}

const navItems = [
  {
    href: '#currency',
    label: 'Currency'
  },
  {
    href: '#payment-provider',
    label: 'Payment provider'
  },
  {
    href: '#pricing',
    label: 'Pricing & billing'
  },
  {
    href: '#rules',
    label: 'Rules'
  },
  {
    href: '#workflow',
    label: 'Workflow'
  },
  {
    href: '#media',
    label: 'Media'
  }
]

const SideNav = () => {
  const [hash, setHash] = useState(navItems[0].href)
  const onHashChange = useCallback(() => setHash(window.location.hash), [])

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange, false)

    return () => {
      window.removeEventListener('hashchange', onHashChange, false)
    }
  }, [onHashChange])

  const styles = {
    top: 0,
    position: 'sticky',
    pt: 7
  }

  return (
    <Flex flexDirection="column" sx={styles}>
      {navItems.map(({ href, label }) => (
        <SideNavItem key={href} active={hash === href} href={href} label={label} />
      ))}
    </Flex>
  )
}

export default SideNav
