import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import CreateTacoRulePage from './rules/taco-rules/CreateTacoRulePage'
import EditTacoRulePage from './rules/taco-rules/EditTacoRulePage'
import DocumentsPage from './documents/DocumentsPage'
import ApprovePage from './approval/ApprovePage'
import RevisionsPage from './audit/RevisionsPage'
import AssetPage from './asset/AssetPage'
import PaymentProviderPage from './payment-provider/PaymentProviderPage'
import BillingDatesPage from './billing-dates/BillingDatesPage'
import ProductVersionHome from './ProductVersionHome'
import NotesPage from './notes/NotesPage'
import VersionImages from './VersionImages'
import CreditLimitPage from './rules/decision-rules/credit-limit/CreditLimitPage'
import RepaymentSchedulePage from './repayment-schedule/RepaymentSchedulePage'
import InterestRatePage from './rules/decision-rules/interest-rate/InterestRatePage'
import FeesPage from './rules/decision-rules/fees/FeesPage'
import WorkflowPage from './workflow/WorkflowPage'

const ProductVersionRoutes = () => {
  // @ts-ignore
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={ProductVersionHome} />
      <Route exact path={`${path}/workflows`} component={WorkflowPage} />
      <Route exact path={`${path}/approve`} component={ApprovePage} />
      <Route exact path={`${path}/revisions`} component={RevisionsPage} />
      <Route exact path={`${path}/currency`} component={AssetPage} />
      <Route exact path={`${path}/payment-provider`} component={PaymentProviderPage} />
      <Route exact path={`${path}/billing`} component={BillingDatesPage} />
      <Route exact path={`${path}/notes`} component={NotesPage} />
      <Route exact path={`${path}/images`} component={VersionImages} />
      <Route exact path={`${path}/documents`} component={DocumentsPage} />
      <Route exact path={`${path}/credit-interest-rates`} component={InterestRatePage} />
      <Route exact path={`${path}/credit-limits`} component={CreditLimitPage} />
      <Route exact path={`${path}/repayment-schedule`} component={RepaymentSchedulePage} />
      <Route exact path={`${path}/fees`} component={FeesPage} />
      <Route exact path={`${path}/workflow`} component={WorkflowPage} />
      <Switch>
        <Route exact path={`${path}/rules/create`} component={CreateTacoRulePage} />
        <Route exact path={`${path}/rules/:ruleNumber?`} component={EditTacoRulePage} />
      </Switch>
    </Switch>
  )
}

export default ProductVersionRoutes
