import React from 'react'
import { diffWords } from 'diff'
import { Flex } from '@11FSFoundry/figloo'

import RuleChanges from 'components/product-configuration/audit/RuleChanges'

interface Props {
  newRule: string
  oldRule: string
}

const RuleDiff = ({ oldRule, newRule }: Props) => {
  const changes = diffWords(oldRule, newRule)

  return (
    <Flex>
      <Flex flex={1}>
        <RuleChanges changes={changes} when="before" />
      </Flex>
      <Flex flex={1}>
        <RuleChanges changes={changes} when="after" />
      </Flex>
    </Flex>
  )
}

export default RuleDiff
