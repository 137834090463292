export const formatAmount = ({ amount, asset }: { amount: number; asset: string }) => {
  const formattedAsset = asset.startsWith('credit:') ? asset.substring(7) : asset

  return `${formattedAsset}${amount}`
}

export const formatUser = (userId: string) => {
  const lastIndex = userId.lastIndexOf(':')
  return userId
    .substring(lastIndex + 1)
    .toUpperCase()
    .charAt(0)
}
