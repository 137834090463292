import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { notifySuccess, notifyError } from 'components/Toast'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import TacoRuleSchemaController from 'components/product-configuration/rules/taco-rules/TacoRuleSchemaController'
import RulesContext from 'components/product-configuration/rules/RulesContext'
import { TacoRulesContext, TacoRulesProvider } from 'components/product-configuration/rules/taco-rules/TacoRules'
import useEditRule from 'gql/useEditRule'
import { ProductRule, ProductRuleInput } from 'components/product-configuration/rules/types'

const EditTacoRulePage = () => {
  const { ruleNumber } = useParams()
  const { product, version } = useContext(ProductVersionContext)
  const [editRuleMutation, { loading }] = useEditRule()

  if (!ruleNumber) return <p>Rule not found</p>

  const ruleNumberNumeric = parseInt(ruleNumber, 10)
  const loadedRule = version.rules.find(({ number }: ProductRule) => number === ruleNumberNumeric)

  if (!loadedRule) return <p>Rule not found</p>

  const editRule = async (productRuleInput: ProductRuleInput) => {
    const variables = {
      productId: product.id,
      versionNumber: version.number,
      input: {
        ruleNumber: loadedRule.number,
        rule: {
          ...productRuleInput
        }
      }
    }

    try {
      await editRuleMutation({ variables })

      notifySuccess('Rule updated')
    } catch (error) {
      notifyError('Editing rule failed')
    }
  }

  return (
    <RulesContext.Provider value={{ TheContext: TacoRulesContext }}>
      <TacoRulesProvider loadedRule={loadedRule}>
        <TacoRuleSchemaController saveRule={editRule} loading={loading} />
      </TacoRulesProvider>
    </RulesContext.Provider>
  )
}

export default EditTacoRulePage
