import React from 'react'
import { Flex, Heading, Button, Link, IconArrowLeft } from '@11FSFoundry/figloo'
import { Link as RouterLink } from 'react-router-dom'
import Page from 'components/Page'
import { FormProvider } from 'components/product-configuration/Form'
import FormField from 'components/product-configuration/FormField'
import FormController from 'components/product-configuration/FormController'
import ProductNameInput from 'components/product-configuration/create-product/ProductNameInput'
import ProductDescriptionInput from 'components/product-configuration/create-product/ProductDescriptionInput'

interface Props {
  completeSubmit: (values: Record<string, unknown>) => void
  loading: boolean
}
const CreateProduct = ({ completeSubmit, loading }: Props) => {
  return (
    <Page>
      <FormProvider
        completeSubmit={variables => {
          completeSubmit({
            variables: {
              ...variables,
              attributes: '[]'
            }
          })
        }}
        initialData={{}}
        fields={[]}>
        <FormController>
          <Flex flex={1} justifyContent="center">
            <Flex flexDirection="column" my={6} mx={5} flex="1" maxWidth={1152}>
              <Heading variant="h2" as="h2" mb={5}>
                Product
              </Heading>
              <FormField label="Product name" description="Please select a product name">
                <ProductNameInput testId="create-product-name" />
              </FormField>
              <FormField label="Description" description="Please enter a description">
                <ProductDescriptionInput testId="create-product-description" />
              </FormField>
              <Flex py={5}>
                <Link variant="secondaryButtonLink" as={RouterLink} flex={1} mr={2} to="/">
                  <IconArrowLeft /> Back
                </Link>
                <Button
                  variant="primary"
                  flex="1"
                  ml={2}
                  loading={loading}
                  type="submit"
                  data-testid="create-product-submit">
                  Create product
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </FormController>
      </FormProvider>
    </Page>
  )
}

export default CreateProduct
