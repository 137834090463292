import gql from 'graphql-tag'

export const GET_WORKFLOW_DEFINITIONS = gql`
  query getWorkflows {
    definitions {
      workflows {
        __typename
        name
        label
        steps {
          __typename
          name
          label
          ... on UserInputWorkflowStepDefinition {
            requiredAttributes {
              name
              label
              accountAttributeName
            }
          }
          ... on SystemInputWorkflowStepDefinition {
            requiredAttributes {
              name
              label
              source
              accountAttributeName
            }
          }
          ... on UserFactsWorkflowStepDefinition {
            requiredAttributes {
              name
              label
              source
              accountAttributeName
            }
          }
        }
      }
    }
  }
`
export default GET_WORKFLOW_DEFINITIONS
