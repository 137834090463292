import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const REQUEST_REVIEW = gql`
  mutation($productId: String!, $versionNumber: Int!, $reviewerId: String!) {
    submitProductVersionForReview(productId: $productId, versionNumber: $versionNumber, reviewerId: $reviewerId) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default REQUEST_REVIEW
