import React from 'react'

import { Text, Box, Flex, theme, Image } from '@11FSFoundry/figloo'
import { Link } from 'react-router-dom'

interface Props {
  to: string
  label?: string
  image?: any
  active?: boolean
  count?: string
}

const BoxLink = ({ to, label, image, active, count }: Props) => (
  <Link
    to={to}
    style={{
      display: 'flex',
      flexFlow: 'column',
      backgroundColor: 'white',
      flex: '0',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '150px',
      margin: '8px',
      textAlign: 'center',
      flexBasis: '222px'
    }}>
    <Image src={image} mb={2} />
    <Flex alignItems="center">
      {label && (
        <Text fontSize="24px" mr={1}>
          {label}
        </Text>
      )}
      {count && (
        <Box
          display="flex"
          width={20}
          height={20}
          sx={{ borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}
          backgroundColor={theme.colors.mint}>
          <Text fontSize={13}>{count}</Text>
        </Box>
      )}
    </Flex>
    {active && (
      <Flex mt={1} alignItems="baseline">
        <Box width={10} height={10} sx={{ borderRadius: 5 }} backgroundColor={theme.colors.mint} mr={1} />
        <Text fontWeight={400}>ON</Text>
      </Flex>
    )}
  </Link>
)

export default BoxLink
