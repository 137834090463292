import React, { useState, ChangeEvent } from 'react'
import { Button, Flex, Text, RadioField, Textarea, Box, CheckboxField } from '@11FSFoundry/figloo'
import { ProductVersion } from 'types'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

interface Props {
  product: {
    id: string
    name: string
    versions: ProductVersion[]
  }
  versionNumber: number
  handleApprove: () => void
  handleDecline: (feedback: string) => void
}

const ReviewVersion = ({ product, versionNumber, handleApprove, handleDecline }: Props) => {
  const hasApproveProductVersionPermission = useCheckPermissions('approve:product-versions')
  const hasRejectProductVersionPermission = useCheckPermissions('reject:product-versions')

  const version = product.versions.find(({ number }) => Number(versionNumber) === number)

  const [decision, setDecision] = useState('')
  const [feedback, setFeedback] = useState('')
  const [approvalFromAllParties, setApprovalFromAllParties] = useState(false)

  const handleApprovalChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDecision(event.target.value)
  }

  const handleFeedbackChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(event.target.value)
  }

  const handleApprovalFromAllPartiesChange = () => {
    setApprovalFromAllParties(!approvalFromAllParties)
  }

  if (!version) return <p>Product version not found</p>

  return (
    <Flex flexDirection="column" justifyContent="center" flex={1}>
      <Flex flexDirection="column" my={6}>
        <Text fontSize={3} opacity={0.3} pb={3}>
          Notes
        </Text>
        <Text opacity={0.3}>No notes added</Text>
      </Flex>
      <Flex flex={1} mx={-2}>
        <Flex flex={1} flexDirection="column" m={2} backgroundColor="white" p={5}>
          <Text fontSize={3} pb={3}>
            Approve?
          </Text>
          <Box mb={4}>
            <RadioField
              data-testid="approveSection"
              value="approve"
              name="decision"
              onChange={handleApprovalChange}
              label="Approve this version"
              disabled={!hasApproveProductVersionPermission}
            />
          </Box>
          <CheckboxField
            data-testid="approvalFromAllParties"
            checked={approvalFromAllParties}
            disabled={!hasApproveProductVersionPermission || decision !== 'approve'}
            onChange={handleApprovalFromAllPartiesChange}
            label="I have approval from all parties"
          />
          <Flex flex={1} flexDirection="column" justifyContent="flex-end">
            <Button
              data-testid="approveProductVersion"
              mt={4}
              disabled={!hasApproveProductVersionPermission || !approvalFromAllParties || decision !== 'approve'}
              onClick={handleApprove}>
              Approve
            </Button>
          </Flex>
        </Flex>
        <Flex flex={1} flexDirection="column" m={2} backgroundColor="white" p={5}>
          <Text fontSize={3} pb={3}>
            Decline?
          </Text>
          <Box mb={4}>
            <RadioField
              data-testid="declineSection"
              value="decline"
              name="decision"
              onChange={handleApprovalChange}
              label="Decline with feedback"
              disabled={!hasRejectProductVersionPermission}
            />
          </Box>
          <Flex flex={1} flexDirection="column">
            <Text pb={1} opacity={decision !== 'decline' ? 0.3 : 1}>
              Note for Editor
            </Text>
            <Flex flex={1}>
              <Textarea
                data-testid="declineComment"
                disabled={!hasRejectProductVersionPermission || decision !== 'decline'}
                onChange={handleFeedbackChange}
              />
            </Flex>
          </Flex>
          <Button
            data-testid="declineProductVersion"
            variant="secondary"
            disabled={!hasRejectProductVersionPermission || decision !== 'decline'}
            mt={4}
            onClick={() => handleDecline(feedback)}>
            Decline
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ReviewVersion
