import React from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import WithdrawVersionFromReview from 'components/product-configuration/approval/WithdrawVersionFromReview'
import useWithdrawVersion from 'gql/useWithdrawVersion'

interface Props {
  productId: string
  versionNumber: number
}
const WithdrawVersionFromReviewController = ({ productId, versionNumber }: Props) => {
  const [withdrawVersionMutation] = useWithdrawVersion()

  const handleWithdraw = async () => {
    try {
      await withdrawVersionMutation({
        variables: {
          productId,
          versionNumber
        }
      })

      notifySuccess('Version withdrawn')
    } catch (errors) {
      notifyError('withdrawing version failed')
    }
  }

  return <WithdrawVersionFromReview withdrawVersion={handleWithdraw} />
}

export default WithdrawVersionFromReviewController
