import React from 'react'
import { toast as Toaster } from 'react-toastify'
import { IconAlert, IconCheck, Text, Flex } from '@11FSFoundry/figloo'

export const notifySuccess = (message: string) => {
  Toaster.success(
    <Flex>
      <IconCheck />
      <Text m="auto" fontSize={16} fontFamily="FuturaPT-Heavy">
        {message}
      </Text>
    </Flex>
  )
}

export const notifyError = (message: string) => {
  Toaster.error(
    <Flex>
      <IconAlert />
      <Text m="auto" fontSize={16} fontFamily="FuturaPT-Heavy">
        {message}
      </Text>
    </Flex>
  )
}
