import gql from 'graphql-tag'

export const GET_USER_ACCOUNTS = gql`
  query($userId: String!) {
    user(userId: $userId) {
      accounts {
        id
        product {
          name
        }
      }
    }
  }
`

export default GET_USER_ACCOUNTS
