import React from 'react'
import { Button, Flex, Text, Select, Box, theme, FileUploader } from '@11FSFoundry/figloo'
import { notifyError } from 'components/Toast'
import { ApproverItem, ProductVersion, ProductVersionFile, ProductVersionFileType } from 'types'
import ApprovalDocument from 'components/product-configuration/approval/ApprovalDocument'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

interface Props {
  productVersion: ProductVersion
  submitVersionForReview: () => void
  approvers: ApproverItem[]
  approverId: string
  setApproverId: (approverId: string) => void
  uploading: boolean
  upload: (files: FileList) => void
  deleteApproval: (file: string) => void
  edit: (file: ProductVersionFile, description: string) => void
}

const SubmitVersionForReview = ({
  productVersion,
  submitVersionForReview,
  approvers,
  approverId,
  setApproverId,
  uploading,
  upload,
  deleteApproval,
  edit
}: Props) => {
  const hasSubmitForReviewPermission = useCheckPermissions('request-approval:product-versions')
  const hasCreateFilePermission = useCheckPermissions('create:files')
  const editApprovalDescription = (file: ProductVersionFile) => (description: string) => {
    edit(file, description)
  }

  const renderApprovalDocuments = () =>
    productVersion.files
      .filter(f => f.type === ProductVersionFileType.Approval)
      .map(f => (
        <ApprovalDocument key={f.id} file={f} deleteApproval={deleteApproval} edit={editApprovalDescription(f)} />
      ))

  return (
    <Flex flex={1} flexDirection="column" p={5}>
      <Flex flexDirection="column">
        <Box mb={3} backgroundColor={theme.colors.white} css={{ border: `3px dashed ${theme.colors.gsRat}` }}>
          <FileUploader onChange={upload} multiple disabled={!hasCreateFilePermission}>
            <Flex>
              <Button
                as="div"
                m={3}
                loading={uploading}
                disabled={!hasCreateFilePermission}
                opacity={!hasCreateFilePermission ? 0.3 : 1}>
                + Upload proof
              </Button>
            </Flex>
          </FileUploader>
        </Box>
        <Box>{renderApprovalDocuments()}</Box>
        <Flex flexDirection="column" m={5} opacity={!hasSubmitForReviewPermission ? 0.3 : 1}>
          <Text pb={1}>Invite To Review & Approve</Text>
          <Flex alignItems="center">
            <Select
              flex={1}
              disabled={!hasSubmitForReviewPermission}
              value={approverId}
              onChange={({ target }) => setApproverId(target.value)}
              backgroundColor={theme.colors.gsSmoke}>
              <option value="">Please select an approver</option>
              {approvers.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
            <Button
              ml={1}
              disabled={!hasSubmitForReviewPermission}
              onClick={() => {
                if (!approverId) {
                  return notifyError('Please select an approver')
                }
                submitVersionForReview()
              }}>
              Send for approval
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SubmitVersionForReview
