/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react'
import { Box, Flex, Heading, Text, Image, Button, Input, Select, IconBin } from '@11FSFoundry/figloo'
import ChevronDown from 'images/icon-chev-mini-down.svg'
import IconDragger from 'images/icon-dragger.svg'
import { WorkflowContext, ReducerActions, Attribute } from 'reducers/WorkflowReducer'
import IconSave from 'images/icon-save.svg'
import { notifySuccess } from 'components/Toast'
import { WorkflowStepDefinition } from './types'

interface Props {
  step: WorkflowStepDefinition
}

interface AttributeInputState extends Omit<Attribute, 'step'> {
  visible: boolean
}

const WorkflowBlock = ({ step }: Props) => {
  const [active, setActive] = useState(false)
  const [attributeInput, setAttributeInput] = useState<AttributeInputState>({
    visible: false,
    label: '',
    name: '',
    addedToAccount: 'Yes'
  })
  const [canSaveAttribute, setCanSaveAttribute] = useState(false)
  const { state, dispatch } = useContext(WorkflowContext)
  const displayAccountAttr = (attr: string, accountAttr: string | null) => {
    if (accountAttr == null) return 'No'
    if (accountAttr === attr) return 'Yes'
    return `Yes - as ${accountAttr}`
  }

  useEffect(() => {
    setActive(false)
  }, [step])

  useEffect(() => {
    if (attributeInput.label && attributeInput.name) return setCanSaveAttribute(true)
    setCanSaveAttribute(false)
  }, [attributeInput])

  const isUserFact = step.__typename === 'UserFactsWorkflowStepDefinition' && step.__typename
  const isSystemInput = step.__typename === 'SystemInputWorkflowStepDefinition' && step.__typename
  const isUserInput = step.__typename === 'UserInputWorkflowStepDefinition' && step.__typename

  const renderStepAttributes = () => {
    if (isSystemInput || isUserFact) {
      return (
        step.requiredAttributes
          .sort((a, b) => a.name.localeCompare(b.name))
          // @ts-ignore
          .map(({ name, label, source, accountAttributeName }, i) => (
            <Flex pt={1} bg={i % 2 && '#f1f1f145'}>
              <Flex flex={2}>
                <Text bg="#47b79a25" py="2px" px="11px" style={{ borderRadius: '20px' }}>
                  {label}
                </Text>
              </Flex>
              <Flex flex={3}>
                <Text>{name}</Text>
              </Flex>
              <Flex flex={1}>
                <Text>{source}</Text>
              </Flex>
              <Flex flex={1}>
                <Text>{displayAccountAttr(name, accountAttributeName)}</Text>
              </Flex>
            </Flex>
          ))
      )
    }

    return step.requiredAttributes
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ name, label, accountAttributeName }, i) => (
        <Flex pt="4px" pb="4px" bg={i % 2 && '#f1f1f145'}>
          <Flex flex={2}>
            <Text bg="#47b79a25" py="2px" px="11px" style={{ borderRadius: '20px' }}>
              {label}
            </Text>
          </Flex>
          <Flex flex={3}>
            <Text>{name}</Text>
          </Flex>
          <Flex flex={1}>
            <Text>{displayAccountAttr(name, accountAttributeName)}</Text>
          </Flex>
        </Flex>
      ))
  }

  const renderAdditionalAttributes = () => {
    return state[step.name]?.map(({ name, label, addedToAccount }, i) => {
      return (
        <Flex pt="4px" pb="4px" bg={i % 2 && '#f1f1f145'}>
          <Flex flex={2}>
            <Text bg="#FFEED4" py="2px" px="11px" style={{ borderRadius: '20px' }}>
              {label}
            </Text>
          </Flex>
          <Flex flex={3}>
            <Text>{name}</Text>
          </Flex>
          <Flex flex={1}>
            <Text>{addedToAccount}</Text>
          </Flex>
        </Flex>
      )
    })
  }

  return (
    <Box bg="white" mt={3} p={2} pr={1}>
      <Flex px={2} flexDirection="column">
        <Flex
          alignItems="center"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setActive(!active)
          }}>
          <Image mr={3} src={IconDragger} />
          <Flex alignItems="baseline">
            <Heading variant="h3" fontFamily="demi" flex={1} mr={2}>
              {isUserInput && 'User Input:'}
              {isSystemInput && 'System Input:'}
              {isUserFact && 'User Fact:'}
            </Heading>
            <Text variant="h3">{step.label || step.name}</Text>
          </Flex>
          <Image
            ml="auto"
            style={{ transform: `rotate(${active ? '180' : '0'}deg)` }}
            height={48}
            width={48}
            src={ChevronDown}
          />
        </Flex>
        {active && (
          <Flex
            overflow="hidden"
            px={1}
            pt={3}
            pb={3}
            flexDirection="column"
            style={{ borderTop: '1px solid #C4C4C4' }}>
            <Flex>
              <Text fontWeight={600} flex={2}>
                Description
              </Text>
              <Text fontWeight={600} flex={3}>
                Id
              </Text>
              {step.__typename === 'SystemInputWorkflowStepDefinition' && (
                <Text fontWeight={600} flex={1}>
                  Source
                </Text>
              )}
              <Text variant="h4" fontWeight={600} flex={1}>
                Added to account
              </Text>
            </Flex>
            {renderStepAttributes()}
            {renderAdditionalAttributes()}
            {attributeInput.visible && (
              <Flex height="40px" pt={1} mb={2}>
                <Flex flex={2}>
                  <Input
                    p={1}
                    height="100%"
                    value={attributeInput.label}
                    onChange={e => {
                      const { value } = e.target
                      setAttributeInput(prevState => ({ ...prevState, label: value }))
                    }}
                    width="100px"
                    bg="white"
                    style={{ border: '2px solid #b7b7b7', borderRadius: '4px' }}
                  />
                </Flex>
                <Flex flex={3}>
                  <Input
                    p={1}
                    height="100%"
                    onChange={e => {
                      const { value } = e.target
                      setAttributeInput(prevState => ({ ...prevState, name: value }))
                    }}
                    value={attributeInput.name}
                    width="100px"
                    bg="white"
                    style={{ border: '2px solid #b7b7b7', borderRadius: '4px' }}
                  />
                </Flex>
                <Flex flex={1}>
                  <Select
                    height="100%"
                    onChange={e => {
                      const { value } = e.target
                      setAttributeInput(prevState => ({ ...prevState, addedToAccount: value }))
                    }}
                    value={attributeInput.addedToAccount}
                    width="100px"
                    bg="white"
                    style={{ border: '2px solid #b7b7b7', borderRadius: '4px' }}>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Select>
                </Flex>
                <Flex alignItems="center">
                  <Image
                    height={20}
                    width={20}
                    opacity={canSaveAttribute ? 1 : 0.2}
                    mr={1}
                    style={{ cursor: canSaveAttribute ? 'pointer' : 'default' }}
                    src={IconSave}
                    onClick={() => {
                      if (canSaveAttribute) {
                        const { label, name, addedToAccount } = attributeInput
                        dispatch({
                          type: ReducerActions.ADD_ATTRIBUTE,
                          payload: { label, name, addedToAccount, step: step.name }
                        })
                        notifySuccess('Attribute added')
                        setAttributeInput({ label: '', name: '', addedToAccount: 'Yes', visible: false })
                      }
                    }}
                  />
                  <Flex
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setAttributeInput({ label: '', name: '', addedToAccount: 'Yes', visible: false })
                    }}>
                    <IconBin color="cherry" />
                  </Flex>
                </Flex>
              </Flex>
            )}
            {!attributeInput.visible && (
              <Flex mt={2}>
                <Button
                  onClick={() => {
                    setAttributeInput(prevState => ({ ...prevState, visible: true }))
                  }}
                  style={{ borderRadius: '4px' }}
                  variant="secondary">
                  + Add Attribute
                </Button>
              </Flex>
            )}
            <Flex justifyContent="flex-end">
              <Flex alignItems="center">
                <Flex style={{ borderRadius: '20px' }} bg="#47B79A25" height="28px" width="28px" />
                <Text ml={1} mr={3}>
                  Required
                </Text>
                <Flex style={{ borderRadius: '20px' }} bg="#FFEED4" height="28px" width="28px" />
                <Text ml={1}>Additionally Requesting</Text>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export default WorkflowBlock
