import React, { useContext } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import { useHistory } from 'react-router-dom'
import ReviewVersion from 'components/product-configuration/approval/ReviewVersion'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import useApproveVersion from 'gql/useApproveVersion'
import useRejectVersion from 'gql/useRejectVersion'

const ReviewVersionController = () => {
  const history = useHistory()
  const { product, version } = useContext(ProductVersionContext)

  const [approveVersionMutation] = useApproveVersion()

  const [rejectVersionMutation] = useRejectVersion()

  const handleApprove = async () => {
    try {
      await approveVersionMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number
        }
      })
      notifySuccess('Version approved')
      history.push(`/products/${product.id}/versions/${version.number}`)
    } catch (error) {
      notifyError('Approving version failed')
    }
  }

  const handleDecline = async (comment: string) => {
    try {
      await rejectVersionMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          comment: comment || 'No comment'
        }
      })
      notifySuccess('Version rejected')
    } catch (errors) {
      notifyError('Rejecting version failed')
    }
  }

  return (
    <ReviewVersion
      product={product}
      versionNumber={version.number}
      handleApprove={handleApprove}
      handleDecline={handleDecline}
    />
  )
}

export default ReviewVersionController
