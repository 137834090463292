import { Button, Flex, IconBin, IconDragger, Text } from '@11FSFoundry/figloo'
import Condition from 'components/product-configuration/rules/Condition'
import { DecisionRuleCondition, DecisionRulesSchema } from 'components/product-configuration/rules/decision-rules/types'
import Parameters from 'components/product-configuration/rules/Parameters'
import { ParameterActionData } from 'components/product-configuration/rules/types'
import React from 'react'
import { SortableElement as sortableElement, SortableHandle as sortableHandle } from 'react-sortable-hoc'

const DragHandle = sortableHandle(() => <IconDragger style={{ cursor: 'grab' }} />)

interface SortableProps {
  ruleSchema: DecisionRulesSchema
  condition: DecisionRuleCondition
  conditionIndex: number
  label: string
  editable: boolean
  setResult: (paramActionData: ParameterActionData) => void
  setGroup: (group: string) => void
  setProperty: (property: string) => void
  setOperator: (operator: string) => void
  setParameter: (paramActionData: ParameterActionData) => void
  deleteCondition: () => void
}

const DecisionRuleConditionRow = sortableElement(
  ({
    condition,
    conditionIndex,
    ruleSchema,
    label,
    editable,
    setResult,
    setGroup,
    setProperty,
    setOperator,
    setParameter,
    deleteCondition
  }: SortableProps) => {
    return (
      <Flex bg="white" p={2} mb={1}>
        <Flex flex={1}>
          <Flex width={20} mr={2} alignItems="center">
            <DragHandle />
          </Flex>
          <Flex width="50px" mr={2} alignItems="center">
            <Text>{label}</Text>
          </Flex>
          <Flex flexWrap="wrap" flex={1}>
            <Condition
              index={conditionIndex}
              schemaItem={ruleSchema}
              condition={condition}
              useValidateParameters={() => {}}
              disabled={!editable}
              setGroup={setGroup}
              setProperty={setProperty}
              setOperator={setOperator}
              setParameter={setParameter}
            />
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center">
          <Parameters
            conditionIndex={conditionIndex}
            key={condition.conditionOperator}
            parameterDefinitions={ruleSchema.result.parameters}
            handleChange={setResult}
            parameters={condition.result}
            disabled={!editable}
            testLabel="result"
          />
          <Button onClick={deleteCondition} variant="icon" disabled={!editable}>
            <IconBin color="cherry" />
          </Button>
        </Flex>
      </Flex>
    )
  }
)

export default DecisionRuleConditionRow
