import React, { ReactNode } from 'react'
import { Flex } from '@11FSFoundry/figloo'
import NavBar from 'components/NavBar'

interface Props {
  children?: ReactNode
}

const Page = ({ children }: Props) => (
  <Flex flexDirection="column" flex={1} bg="gsSmokeLight">
    <NavBar />
    <Flex justifyContent="center" flex={1} mt={102}>
      <Flex flexDirection="column" flex={1}>
        {children}
      </Flex>
    </Flex>
  </Flex>
)
export default Page
