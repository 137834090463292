import React, { useContext } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import Note from 'components/product-configuration/notes/Note'
import { ProductVersionNote } from 'types'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import useEditNote from 'gql/useEditNote'

interface Props {
  note: ProductVersionNote
}

const NotesController = ({ note }: Props) => {
  const { product, version } = useContext(ProductVersionContext)

  const [editNoteMutation] = useEditNote()

  const editNote = async (updatedNote: ProductVersionNote) => {
    try {
      await editNoteMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          note: updatedNote
        }
      })

      notifySuccess('Note updated')
    } catch (errors) {
      notifyError('Editing note failed')
    }
  }

  return <Note note={note} editNote={editNote} />
}

export default NotesController
