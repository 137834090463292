import { useContext } from 'react'
import download from 'downloadjs'
import { AuthContext } from 'components/auth/Auth'

// use downloadjs to download a file via fetch,
// allowing auth headers to be added
export const useAuthenticatedDownload = () => {
  const authContext = useContext(AuthContext)

  return async (url: string, filename?: string, mimeType?: string) => {
    const token = authContext.tokenData?.tokenId

    if (!token) throw TypeError('AuthContext does not contain tokenData')

    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    })

    const blob = await response.blob()

    return download(blob, filename, mimeType)
  }
}

// download a document given a relative url (starting with `/document`)
export const documentDownloadURL = (relativePath: string) => {
  const baseURL = new URL(process.env.REACT_APP_API_URL || '')
  return `${baseURL.protocol}//${baseURL.host}/${relativePath}`
}
