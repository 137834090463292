import { DecisionRuleState, DecisionRuleType } from 'components/product-configuration/rules/decision-rules/types'
import { conditionParameterToInput, conditionToSingleInput } from 'components/product-configuration/rules/format'
import useAddDecisionRule from 'gql/useAddDecisionRule'
import useEditDecisionRule from 'gql/useEditDecisionRule'

interface Params {
  decisionRuleType: DecisionRuleType
  newRule: boolean
  productId: string
  versionNumber: number
  failureMessage: string
}

const useSaveDecisionRule = ({ decisionRuleType, newRule, productId, versionNumber, failureMessage }: Params) => {
  const [addDecisionRuleMutation] = useAddDecisionRule()
  const [editDecisionRuleMutation] = useEditDecisionRule()
  const saveDecisionRule = newRule ? addDecisionRuleMutation : editDecisionRuleMutation

  return async ({ conditions, defaultResult }: DecisionRuleState) => {
    try {
      const input = {
        name: decisionRuleType,
        rule: {
          steps: conditions.map(({ result, ...condition }) => ({
            condition: {
              single: conditionToSingleInput(condition)
            },
            result: {
              parameters: result.map(parameter => ({
                name: parameter.name,
                ...conditionParameterToInput(parameter)
              }))
            }
          })),
          defaultResult: {
            parameters: defaultResult.map(parameter => ({
              name: parameter.name,
              ...conditionParameterToInput(parameter)
            }))
          }
        }
      }

      await saveDecisionRule({ variables: { productId, versionNumber, input } })
    } catch (e) {
      throw new Error(failureMessage)
    }
  }
}

export default useSaveDecisionRule
