import { UserPermission } from 'types'
import update from 'immutability-helper'

// Top-level applications available with Admin UI; values are names shown in top nav
export enum AdminApplication {
  ProductConfiguration = 'Products',
  CustomerAdvisorPortal = 'Customers'
}

// Each `AdminApplication` has an associated group of permissions. If a user has
// at least one of the permissions in the group for a given app, they're granted access to it.
// This type models this mapping between an app and its permission group.
export type AppAccessByPermissionGroup = Record<AdminApplication, UserPermission[]>

const defaultAppAccess: AppAccessByPermissionGroup = {
  [AdminApplication.ProductConfiguration]: [
    'approve:product-versions',
    'reject:product-versions',
    'create:products',
    'clone:product-versions',
    'create:product-versions',
    'update:product-versions',
    'archive:product-versions',
    'withdraw-approval-request:product-versions',
    'request-approval:product-versions',
    'create:notes',
    'delete:notes',
    'update:notes',
    'create:attributes',
    'update:attributes',
    'delete:attributes',
    'create:files',
    'update:files',
    'delete:files',
    'create:rules',
    'update:rules',
    'delete:rules',
    'read:audit-trails',
    'create:decision-rules',
    'update:decision-rules',
    'delete:decision-rules'
  ],
  [AdminApplication.CustomerAdvisorPortal]: ['read:accounts']
}

// Find which apps are accessible for a single `UserPermission`.
const permittedApps = (permission: UserPermission, access: AppAccessByPermissionGroup): AdminApplication[] =>
  Object.keys(access).reduce<AdminApplication[]>((permitted, app) => {
    const adminApp = app as AdminApplication
    if (access[adminApp].includes(permission)) return permitted.concat(adminApp)
    return permitted
  }, [])

// Determine which apps are available for a given set of permissions
export const availableApplications = (
  userPermissions: UserPermission[],
  access: AppAccessByPermissionGroup = defaultAppAccess
): AdminApplication[] =>
  Array.from(
    userPermissions.reduce(
      (apps, permission) => update(apps, { $add: permittedApps(permission, access) }),
      new Set<AdminApplication>()
    )
  )
