import { Button, Card, Flex, Heading, Link } from '@11FSFoundry/figloo'
import BoxLink from 'components/product-configuration/BoxLink'
import Page from 'components/Page'
import ProductRuleCardController from 'components/product-configuration/ProductRuleCardController'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import ProductVersionTabs from 'components/product-configuration/ProductVersionTabs'
import { ProductRule } from 'components/product-configuration/rules/types'
import SideNav from 'components/product-configuration/SideNav'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import React, { useContext, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { getAttributeValue } from 'util/productVersion'
import BillingDatesIcon from 'images/icon-billing-dates.svg'
import CreditLimitIcon from 'images/icon-credit-limit.svg'
import DocumentsIcon from 'images/icon-documents.svg'
import ImagesIcon from 'images/icon-images.svg'
import NotesIcon from 'images/icon-notes.svg'
import PaymentProviderIcon from 'images/icon-payment-provider.svg'
import RepaymentScheduleIcon from 'images/icon-repayment-schedule.svg'
import PercentageIcon from 'images/icon-percentage.svg'
import { useInView } from 'react-intersection-observer'
import { ProductVersionFile, ProductVersionFileType } from 'types'

const ProductVersionHome = () => {
  const { product, version } = useContext(ProductVersionContext)
  const hasCreateRulesPermission = useCheckPermissions('create:rules')
  const productVersionEditable = useProductVersionEditable()
  const createRuleEnabled = hasCreateRulesPermission && productVersionEditable
  const productAsset = getAttributeValue<string>(version, 'productAsset') ?? ''
  const notesCount = version.notes?.length.toString()
  const imagesCount = version.files
    ?.filter(({ type }: ProductVersionFile) => type === ProductVersionFileType.Image)
    .length.toString()
  const documentsCount = version.files
    ?.filter((f: ProductVersionFile) => f.type === ProductVersionFileType.Document)
    .length.toString()

  const [currencyRef, currencyInView] = useInView()
  const [paymentRef, paymentInView] = useInView()
  const [pricingRef, pricingInView] = useInView()
  const [rulesRef, rulesInView] = useInView()
  const [mediaRef, mediaInView] = useInView()

  useEffect(() => {
    if (currencyInView) {
      window.location.hash = 'currency'
    } else if (paymentInView) {
      window.location.hash = 'payment-provider'
    } else if (pricingInView) {
      window.location.hash = 'pricing'
    } else if (rulesInView) {
      window.location.hash = 'rules'
    } else if (mediaInView) {
      window.location.hash = 'media'
    }
  }, [currencyInView, paymentInView, pricingInView, rulesInView, mediaInView])

  return (
    <Page>
      <ProductVersionTabs>
        <Flex flex={1} justifyContent="center">
          <Flex flexDirection="column" width={952} pt={7}>
            <Heading ref={currencyRef} variant="h2" as="h2" id="currency" mb={2}>
              Asset
            </Heading>
            <Flex mx={-1} justifyContent="space-between" width="25%" mb={7}>
              <BoxLink
                to={`/products/${product.id}/versions/${version.number}/currency`}
                label={productAsset ? productAsset.toUpperCase() : '+ Add new asset'}
              />
            </Flex>
            <Heading ref={paymentRef} variant="h2" as="h2" id="payment-provider" mb={2}>
              Payment provider
            </Heading>
            <Flex mx={-1} justifyContent="space-between" width="25%" mb={7}>
              <BoxLink
                to={`/products/${product.id}/versions/${version.number}/payment-provider`}
                label="Payment provider"
                image={PaymentProviderIcon}
              />
            </Flex>

            <Heading ref={pricingRef} variant="h2" as="h2" mb={2} id="pricing">
              Pricing
            </Heading>
            <Flex mx={-1} mb={7} flexWrap="wrap">
              <BoxLink
                to={`/products/${product.id}/versions/${version.number}/credit-interest-rates`}
                label="Credit"
                image={PercentageIcon}
              />

              <BoxLink
                to={`/products/${product.id}/versions/${version.number}/fees`}
                label="Fees"
                image={BillingDatesIcon}
              />
            </Flex>

            <Heading ref={pricingRef} variant="h2" as="h2" mb={2} id="pricing">
              Billing
            </Heading>
            <Flex mx={-1} mb={7} flexWrap="wrap">
              <BoxLink
                to={`/products/${product.id}/versions/${version.number}/credit-limits`}
                label="Credit limit range"
                image={CreditLimitIcon}
              />

              <BoxLink
                to={`/products/${product.id}/versions/${version.number}/repayment-schedule`}
                label="Repayment schedule"
                image={RepaymentScheduleIcon}
              />

              <BoxLink
                to={`/products/${product.id}/versions/${version.number}/billing`}
                label="Billing Dates"
                image={BillingDatesIcon}
              />
            </Flex>

            <Heading ref={rulesRef} variant="h2" as="h2" id="rules" mb={2}>
              Rules
            </Heading>
            <Flex flexWrap="wrap" m={-1} mb={7}>
              {version.rules.map((rule: ProductRule) => (
                <Flex key={rule.number} p={1} width="25%" mb={3}>
                  <ProductRuleCardController key={rule.number} rule={rule} />
                </Flex>
              ))}
              {createRuleEnabled && (
                <Link
                  data-testid="createRule"
                  as={RouterLink}
                  to={`/products/${product.id}/versions/${version.number}/rules/create`}
                  style={{ display: 'flex' }}
                  width="25%">
                  <Flex p={1} flex={1} mb={3}>
                    <Card justifyContent="center">
                      <Button as="div" variant="secondary">
                        {' '}
                        + Add new rule
                      </Button>
                    </Card>
                  </Flex>
                </Link>
              )}
            </Flex>

            <Heading ref={mediaRef} variant="h2" as="h2" id="media" mb={2}>
              Media
            </Heading>

            <Flex mx={-1} justifyContent="space-between" mb={7}>
              <BoxLink
                count={notesCount}
                to={`/products/${product.id}/versions/${version.number}/notes`}
                label="Notes"
                image={NotesIcon}
              />
              <BoxLink
                count={imagesCount}
                to={`/products/${product.id}/versions/${version.number}/images`}
                label="Images"
                image={ImagesIcon}
              />
              <BoxLink
                count={documentsCount}
                to={`/products/${product.id}/versions/${version.number}/documents`}
                label="Documents"
                image={DocumentsIcon}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" width={200} alignItems="flex-end">
            <SideNav />
          </Flex>
        </Flex>
      </ProductVersionTabs>
    </Page>
  )
}

export default ProductVersionHome
