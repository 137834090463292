import React, { useState, useEffect } from 'react'
import { IconClose, Text, Flex, Image } from '@11FSFoundry/figloo'
import Confirmation from 'components/product-configuration/Confirmation'
import ImageCard from 'components/product-configuration/ImageCard'
import { ProductVersionFileInput } from 'types'

interface Props {
  image: ProductVersionFileInput
  updateImage: (fieldName: string, value: string) => void
  deleteImage: () => void
  highlightMissingNames: boolean
}

const UploadingImage = ({ image, updateImage, deleteImage, highlightMissingNames }: Props) => {
  const [confirming, setConfirming] = useState(false)
  const [highlighted, setHighlighted] = useState(false)

  useEffect(() => {
    if (highlightMissingNames) setHighlighted(!image.name)
  }, [highlightMissingNames, image.name])

  useEffect(() => {
    if (highlighted) setHighlighted(!image.name)
  }, [highlighted, image.name])

  return (
    <Flex key={image.name}>
      {confirming ? (
        <Flex sx={{ width: 365, p: 5, mr: 3, mb: 3, bg: 'white' }} flexDirection="column">
          <Confirmation
            vertical
            handleConfirm={() => {
              setConfirming(false)
              deleteImage()
            }}
            setConfirming={setConfirming}>
            <Text>Are you sure you want to remove this image?</Text>
          </Confirmation>
        </Flex>
      ) : (
        <ImageCard
          highlighted={highlighted}
          updateImage={updateImage}
          deleteIcon={IconClose}
          deleteImage={() => setConfirming(true)}
          name={image.name}
          description={image.description}>
          <Image src={URL.createObjectURL(image.file)} maxHeight="100%" maxWidth="100%" />
        </ImageCard>
      )}
    </Flex>
  )
}

export default UploadingImage
