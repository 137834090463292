import React from 'react'
import { Button, Flex, theme } from '@11FSFoundry/figloo'

interface Props {
  maxDays: number
  rows: number
  disabled: boolean
  onChange: (day: number) => void
  isDaySelected: (day: number) => boolean
}

export const DayPicker = ({ maxDays, rows, disabled, onChange, isDaySelected }: Props) => {
  const renderButtonRow = (rowNumber: number) => {
    const buttons = []
    for (let i = 1; i <= 10 && rowNumber * 10 + i <= maxDays; i += 1) {
      const day = rowNumber * 10 + i
      buttons.push(
        <Button
          data-testid="dueDay"
          key={day}
          m={1}
          width={3}
          onClick={() => onChange(day)}
          backgroundColor={isDaySelected(day) ? theme.colors.mintNeon : theme.colors.gsMist}
          disabled={disabled}>
          {day}
        </Button>
      )
    }
    return <Flex key={rowNumber}>{buttons}</Flex>
  }

  const renderButtons = () => {
    const dayRows = []
    for (let i = 0; i < rows; i += 1) {
      dayRows.push(renderButtonRow(i))
    }
    return dayRows
  }

  return <Flex flexDirection="column">{renderButtons()}</Flex>
}

export default DayPicker
