import React, { useContext } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import { ProductRule } from 'components/product-configuration/rules/types'
import useDeleteRule from 'gql/useDeleteRule'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import ProductRuleCard from 'components/product-configuration/ProductRuleCard'

interface Props {
  rule: ProductRule
}

const ProductRuleCardController = ({ rule }: Props) => {
  const { product, version } = useContext(ProductVersionContext)
  const [deleteRuleMutation] = useDeleteRule()

  const deleteRule = async () => {
    try {
      await deleteRuleMutation({
        variables: {
          productId: product.id,
          versionNumber: version.number,
          ruleNumber: rule.number
        }
      })

      notifySuccess('Rule deleted')
    } catch (e) {
      notifyError('Delete rule failed')
    }
  }

  return <ProductRuleCard productId={product.id} productVersion={version.number} rule={rule} deleteRule={deleteRule} />
}

export default ProductRuleCardController
