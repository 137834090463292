import ProductAssetInput from 'components/product-configuration/asset/ProductAssetInput'
import useUpsertAttributes from 'gql/useUpsertAttributes'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import React, { useContext, useState } from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import { getAttributeValue } from 'util/productVersion'
import { FormProvider } from 'components/product-configuration/Form'
import FormController from 'components/product-configuration/FormController'
import FormField from 'components/product-configuration/FormField'
import FormSection from 'components/product-configuration/FormSection'
import MainPage from 'components/product-configuration/MainPage'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'

const Asset = () => {
  const [loading, setLoading] = useState(false)
  const { product, version } = useContext(ProductVersionContext)
  const hasUpdateProductVersionPermission = useCheckPermissions('update:product-versions')
  const productVersionEditable = useProductVersionEditable()
  const disabled = !hasUpdateProductVersionPermission || !productVersionEditable
  const productAsset = getAttributeValue<string>(version, 'productAsset') ?? ''

  const upsertAttributes = useUpsertAttributes(product.id, version)

  const completeSubmit = async (attributes: Record<string, unknown>) => {
    setLoading(true)

    try {
      await upsertAttributes({
        productAsset: attributes.productAsset
      })
      notifySuccess('Updated currency')
    } catch (error) {
      notifyError('Saving currency failed')
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormProvider completeSubmit={completeSubmit} initialData={{ productAsset }} disabled={disabled} fields={[]}>
      <FormController testId="asset-form">
        <MainPage
          headline="Add asset"
          loading={loading}
          saveLabel="Save"
          returnUrl={`/products/${product.id}/versions/${version.number}`}
          saveDisabled={disabled}>
          <FormSection>
            <FormField label="Asset">
              <ProductAssetInput />
            </FormField>
          </FormSection>
        </MainPage>
      </FormController>
    </FormProvider>
  )
}

export default Asset
