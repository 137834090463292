import React, { MouseEvent } from 'react'
import { Button, IconBin } from '@11FSFoundry/figloo'

interface Props {
  'data-testid': string
  onClick: (e: MouseEvent) => void
  disabled: boolean
}

export const DeleteButton = (props: Props) => {
  const { disabled, ...rest } = props
  return (
    <Button color="copyThree" variant="icon" p={0} opacity={disabled ? 0.3 : 1} {...props} {...rest}>
      <IconBin color="cherry" />
    </Button>
  )
}

export default DeleteButton
