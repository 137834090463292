import gql from 'graphql-tag'

const GET_OPERATOR = gql`
  query($operatorInput: String!) {
    definitions {
      operators(operatorInput: $operatorInput) {
        name
        label
        parameters {
          name
          label
          type
          arity
          constraints
          options {
            name
            label
            description
          }
        }
      }
    }
  }
`
export default GET_OPERATOR
