import { Text } from '@11FSFoundry/figloo'
import React, { MouseEvent } from 'react'
import Confirmation from 'components/product-configuration/Confirmation'

interface Props {
  noteName: string
  setConfirming: (confirming: boolean) => void
  handleConfirm: (e: MouseEvent) => void
}

const RemoveRuleController = ({ setConfirming, handleConfirm }: Props) => {
  return (
    <Confirmation flipped vertical handleConfirm={handleConfirm} setConfirming={setConfirming}>
      <Text>Are you sure you want to delete this rule?</Text>
    </Confirmation>
  )
}

export default RemoveRuleController
