import React, { ReactNode, FormEvent } from 'react'
import { Box } from '@11FSFoundry/figloo'

interface Props {
  children: ReactNode | string
  handleClick: (event: FormEvent) => void
  customCss?: { [key: string]: unknown }
}

const css = {
  color: '#444444',
  border: 'none',
  background: 'transparent',
  fontSize: '1rem',
  lineHeight: 'normal',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center' as 'center'
}

const LinkButton = ({ children, handleClick, customCss, ...rest }: Props) => (
  <Box as="button" css={{ ...css, ...customCss }} onClick={event => handleClick(event)} {...rest}>
    {children}
  </Box>
)

export default LinkButton
