import React from 'react'
import Page from 'components/Page'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { Flex, Link, IconArrowLeft, Heading, Text, theme } from '@11FSFoundry/figloo'
import { useQuery } from '@apollo/react-hooks'
import { UserAccountDetails } from 'components/customer-service/types'
import GET_USER_ACCOUNT from 'gql/GET_USER_ACCOUNT'
import { format, parseISO } from 'date-fns'
import Info from './Info'

const AccountPage = () => {
  const { customerId, accountId } = useParams()

  const { data, loading } = useQuery<{
    user: {
      account: UserAccountDetails
    }
  }>(GET_USER_ACCOUNT, {
    variables: { userId: customerId, accountId }
  })

  if (loading) return <p>loading</p>

  const account = data?.user.account

  const { summary, params } = account?.credit.currentRepaymentSchedule ?? {}

  const asset = params?.asset.toUpperCase()

  const interestRate = params ? `${(params.annualNominalInterestRate * 100).toFixed(2)}%` : '-'
  const effectiveInterestRate = summary ? `${(summary.effectiveInterestRate * 100).toFixed(2)}%` : '-'
  const openingDate = params ? format(parseISO(params.accountOpeningDate), 'd MMMM yyyy') : '-'
  const monthlyFee = asset && params?.invoiceFee ? `${asset} ${params?.invoiceFee}` : '-'
  const principalAmount = asset && summary?.totalPrincipal ? `${asset} ${summary?.totalPrincipal}` : '-'

  return (
    <Page>
      <Flex justifyContent="center">
        <Flex flexDirection="column" width={1152}>
          <Link
            variant="secondaryButtonLink"
            as={RouterLink}
            to={`/customers/customer/${customerId}`}
            mt={5}
            mb={1}
            px={3}
            width="fit-content">
            <IconArrowLeft /> {customerId}
          </Link>
          {account ? (
            <>
              <Heading variant="h2" as="h2" my={5}>
                {account.product.name}
              </Heading>
              <Flex justifyContent="space-between" pb={3} sx={{ borderBottom: `1px solid ${theme.colors.copyThree}` }}>
                <Info label="Status">{account.state}</Info>
                <Info label="Nominal interest rate" secondaryContent={`Effective ${effectiveInterestRate}`}>
                  {interestRate}
                </Info>
                <Info label="Monthly fee">{monthlyFee}</Info>
                <Info label="Account opened">{openingDate}</Info>
              </Flex>

              <Flex justifyContent="space-between" mt={3}>
                <Info label="Principal amount">{principalAmount}</Info>
              </Flex>
            </>
          ) : (
            <Text color="gsConcrete" mt={5}>
              Account not found
            </Text>
          )}
        </Flex>
      </Flex>
    </Page>
  )
}

export default AccountPage
