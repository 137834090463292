import React, { ReactNode } from 'react'
import { Box, Flex, Text } from '@11FSFoundry/figloo'

interface Props {
  label: string
  description?: string
  children: ReactNode
}

const FormField = ({ label, description, children }: Props) => (
  <Box p={6} backgroundColor="white" mb="1px">
    <Flex alignItems="center">
      <Flex flexDirection="column" flex="1">
        <Text variant="h3" as="label">
          {label}
        </Text>
        {description && <Text>{description}</Text>}
      </Flex>
      <Box width="400px" css={{ textAlign: 'right' }}>
        {children}
      </Box>
    </Flex>
  </Box>
)

export default FormField
