import React, { ChangeEvent } from 'react'
import { Box, Flex, Text, InputWithValidation } from '@11FSFoundry/figloo'

interface Props {
  disabled: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: number
}

export const DueDate = ({ disabled, onChange, value }: Props) => (
  <Flex alignItems="center" mt={4}>
    <Text>Period between invoice and due date</Text>
    <Box ml={2} mr={2}>
      <InputWithValidation
        name="days"
        value={value}
        onChange={onChange}
        pattern="[0-9]+"
        type="number"
        disabled={disabled}
      />
    </Box>
    <Text>days</Text>
  </Flex>
)

export default DueDate
