import React from 'react'
import { notifySuccess, notifyError } from 'components/Toast'
import { ProductVersion } from 'types'
import VersionBlock from 'components/product-configuration/VersionBlock'
import useArchiveVersion from 'gql/useArchiveVersion'

interface Props {
  version: ProductVersion
  productId: string
  currentLiveVersion?: ProductVersion | undefined
  refetch: () => void
}

const ArchiveController = ({ productId, version, currentLiveVersion }: Props) => {
  const [archiveVersionMutation] = useArchiveVersion()

  const archiveVersion = async () => {
    try {
      await archiveVersionMutation({
        variables: {
          productId,
          versionNumber: version.number
        }
      })
      notifySuccess('Version archived')
    } catch (e) {
      notifyError('Archive version failed')
    }
  }

  return (
    <VersionBlock
      key={version.number}
      version={version}
      productId={productId}
      archiveVersion={archiveVersion}
      currentLiveVersion={currentLiveVersion}
    />
  )
}

export default ArchiveController
