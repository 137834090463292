import React from 'react'
import * as Sentry from '@sentry/browser'
import {
  RuleParameterInputWithLabelAndUnit,
  ConditionType,
  RuleConditionSingle,
  ProductRule,
  Unit
} from 'components/product-configuration/rules/types'
import { DecisionRuleData } from 'components/product-configuration/rules/decision-rules/types'

function formatParameters<T>(label: string, unit: Unit | null, values: T[]) {
  const stringifiedValues = values
    .map((value) => {
      return `${unit?.affix === 'Prefix' ? unit.symbol : ''}${value}${unit?.affix === 'Suffix' ? unit.symbol : ''}`
    })
    .join(' ')

  return `${label}: ${stringifiedValues}`
}

export const stringifyParameters = (parameters: RuleParameterInputWithLabelAndUnit[], glue = ', ') => {
  return parameters
    .map(({ stringValues, enumValues, amountValues, longValues, bigDecimalValues, unit, label }) => {
      if (stringValues) return formatParameters<string>(label, unit, stringValues)
      if (enumValues) return formatParameters<string>(label, unit, enumValues)
      if (amountValues)
        return formatParameters<string>(
          label,
          unit,
          amountValues.map(({ asset, balance }) => `${asset} ${balance}`)
        )
      if (longValues) return formatParameters<number>(label, unit, longValues)
      if (bigDecimalValues) return formatParameters<number>(label, unit, bigDecimalValues)

      Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Error)
        Sentry.captureMessage('unknown parameter type')
      })

      return <span color="red">unknown parameter</span>
    })
    .join(glue)
}

export const stringifyCondition = (condition: RuleConditionSingle, label?: string) => {
  const { property, operator } = condition as RuleConditionSingle

  return `${label} ${property.groupLabel} ${property.label} is ${operator.label} ${stringifyParameters(
    operator.parameters
  )}`
}

export const stringifyConditions = (conditionList: ConditionType) => {
  // eslint-disable-next-line no-underscore-dangle
  if (conditionList.__typename === 'RuleCondition_And') {
    return conditionList.conditions.map((condition, index) => {
      return stringifyCondition(condition as RuleConditionSingle, index === 0 ? 'IF' : 'AND')
    })
  }

  // eslint-disable-next-line no-underscore-dangle
  if (conditionList.__typename === 'RuleCondition_Or') {
    return conditionList.conditions.map((condition, index) => {
      return stringifyCondition(condition as RuleConditionSingle, index === 0 ? 'IF' : 'OR')
    })
  }
}

export const stringifyTacoRule = (rule: ProductRule) => {
  const ruleStrings = [rule.name, rule.description, `WHEN: ${rule.trigger.label}`]

  const conditionStrings = stringifyConditions(rule.condition)

  ruleStrings.push(...conditionStrings)

  const action = `THEN: ${rule.action.label} ${stringifyParameters(rule.action.parameters, ', ')}`

  ruleStrings.push(action)

  return ruleStrings
}

export const stringifyDecisionRule = (rule: DecisionRuleData) => {
  const stringifiedRule = []

  const conditionStrings = rule.steps.map((step, index) => {
    const conditionString = stringifyCondition(step.condition, index ? 'ELSE IF' : 'IF')
    const resultString = stringifyParameters(step.result.parameters)
    return `${conditionString} - ${resultString}`
  })

  stringifiedRule.push(rule.label)
  stringifiedRule.push(...conditionStrings)
  stringifiedRule.push(`${rule.steps.length ? 'ELSE' : ''} ${stringifyParameters(rule.defaultResult.parameters, ', ')}`)

  return stringifiedRule
}
