import React from 'react'
import { Flex, Button, RadioField } from '@11FSFoundry/figloo'
import Condition from 'components/product-configuration/rules/taco-rules/TacoRuleCondition'
import { RulesSchema, ConditionActionType, ParameterActionData } from 'components/product-configuration/rules/types'
import useRulesContext from 'components/product-configuration/rules/useRulesContext'
import {
  TacoRulesContextData,
  TacoRulesActionType,
  LogicalOperator
} from 'components/product-configuration/rules/taco-rules/types'

interface Props {
  schemaItem: RulesSchema
  disabled?: boolean
}

const TacoRuleConditions = ({ schemaItem, disabled }: Props) => {
  const { state, dispatch } = useRulesContext<TacoRulesContextData>()
  const { conditions, conditionLogicalOperator } = state

  return (
    <Flex flexDirection="column">
      <Flex backgroundColor="white" py={3} px={4} mt={2} flexDirection="column">
        <RadioField
          disabled={disabled}
          data-testid="tacoOrOperator"
          label="Perform action if ANY condition is met"
          mb={1}
          value={LogicalOperator.OR}
          checked={conditionLogicalOperator === LogicalOperator.OR}
          name="conditionLogicalOperator"
          onChange={() =>
            dispatch({ type: TacoRulesActionType.SET_CONDITION_LOGICAL_OPERATOR, value: LogicalOperator.OR })
          }
        />
        <RadioField
          disabled={disabled}
          data-testid="tacoAndOperator"
          label="Perform action only when ALL conditions are met"
          value={LogicalOperator.AND}
          name="conditionLogicalOperator"
          checked={conditionLogicalOperator === LogicalOperator.AND}
          onChange={() =>
            dispatch({ type: TacoRulesActionType.SET_CONDITION_LOGICAL_OPERATOR, value: LogicalOperator.AND })
          }
        />
      </Flex>
      {conditions.map((condition, index) => (
        <Condition
          key={condition.key}
          index={index}
          condition={condition}
          schemaItem={schemaItem}
          disabled={disabled}
          conditionLogicalOperator={conditionLogicalOperator}
          setGroup={(group: string) => dispatch({ type: ConditionActionType.SET_GROUP, conditionGroup: group, index })}
          setProperty={(property: string) =>
            dispatch({ type: ConditionActionType.SET_PROPERTY, conditionProperty: property, index })
          }
          setOperator={(operator: string) =>
            dispatch({ type: ConditionActionType.SET_OPERATOR, conditionOperator: operator, index })
          }
          setParameter={(actionData: ParameterActionData) =>
            dispatch({ ...actionData, type: ConditionActionType.SET_PARAMETER, conditionIndex: index })
          }
          deleteCondition={() => dispatch({ type: ConditionActionType.DELETE_CONDITION, index })}
        />
      ))}
      <Flex backgroundColor="white" py={3} px={4} mt="1px" alignItems="center">
        <Button
          data-testid="addTacoCondition"
          disabled={disabled}
          variant="secondary"
          mr={4}
          onClick={() => dispatch({ type: ConditionActionType.ADD_BLANK_CONDITION })}>
          + Add condition
        </Button>
      </Flex>
    </Flex>
  )
}

export default TacoRuleConditions
