import React, { useReducer, createContext, ReactNode } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Sentry from '@sentry/browser'
import reducer from 'components/product-configuration/rules/taco-rules/reducer'
import {
  RuleConditionSingle,
  ProductRule,
  RuleParameterType,
  ConditionType,
  RuleParameter,
  RuleParameterInput
} from 'components/product-configuration/rules/types'
import {
  TacoRulesContextData,
  LogicalOperator,
  TacoRuleState,
  TacoRuleConditionData
} from 'components/product-configuration/rules/taco-rules/types'

export const TacoRulesContext = createContext<TacoRulesContextData>({
  state: {
    ruleName: undefined,
    ruleDescription: undefined,
    triggerName: undefined,
    conditions: [],
    actionName: undefined,
    actionParams: [],
    conditionLogicalOperator: LogicalOperator.OR
  },
  dispatch: () => {}
})

export const generateBlankCondition = (): TacoRuleConditionData => ({
  key: uuidv4(),
  conditionProperty: undefined,
  conditionGroup: undefined,
  conditionOperator: undefined,
  conditionParameters: [],
  parametersAreValid: false
})

export const getParameterData = (param: RuleParameterInput): RuleParameter => {
  if (param.stringValues)
    return {
      name: param.name,
      value: param.stringValues,
      type: RuleParameterType.Text
    }

  if (param.longValues)
    return {
      name: param.name,
      value: param.longValues,
      type: RuleParameterType.Long
    }

  if (param.bigDecimalValues)
    return {
      name: param.name,
      value: param.bigDecimalValues,
      type: RuleParameterType.Decimal
    }

  if (param.enumValues)
    return {
      name: param.name,
      value: param.enumValues,
      type: RuleParameterType.Enum
    }

  if (param.amountValues)
    return {
      name: param.name,
      value: {
        asset: param.amountValues[0].asset,
        quantity: param.amountValues[0].balance
      },
      type: RuleParameterType.Amount
    }

  throw new Error(`Parameter did not contain recognised value, ${JSON.stringify(param)}`)
}

interface Props {
  children: ReactNode
  loadedRule?: ProductRule
}

const getConditionLogicalCondition = (condition: ConditionType) => {
  // eslint-disable-next-line no-underscore-dangle
  switch (condition.__typename) {
    case 'RuleCondition_And':
      return {
        conditionLogicalOperator: LogicalOperator.AND,
        rawConditions: condition.conditions
      }
    case 'RuleCondition_Or':
      return {
        conditionLogicalOperator: LogicalOperator.OR,
        rawConditions: condition.conditions
      }
    default:
      Sentry.withScope((scope) => {
        // @ts-ignore
        scope.setLevel(Sentry.Severity.Error)
        Sentry.captureMessage('Top level condition logical operator has to be AND or OR')
      })

      throw new Error('Top level condition logical operator has to be AND or OR')
  }
}

const getInitialConditions = (
  loadedRule?: ProductRule
): { conditions: TacoRuleConditionData[]; conditionLogicalOperator: LogicalOperator } => {
  if (!loadedRule) {
    return {
      conditions: [generateBlankCondition()],
      conditionLogicalOperator: LogicalOperator.OR
    }
  }

  const { conditionLogicalOperator, rawConditions } = getConditionLogicalCondition(loadedRule.condition)

  const conditions: TacoRuleConditionData[] = rawConditions.map((condition) => {
    const singleCondition = condition as RuleConditionSingle

    return {
      key: singleCondition.number.toString(),
      conditionProperty: singleCondition.property.name,
      conditionGroup: singleCondition.property.group,
      conditionOperator: singleCondition.operator.name,
      conditionParameters: singleCondition.operator.parameters.map(getParameterData),
      parametersAreValid: true
    }
  })

  return { conditionLogicalOperator, conditions }
}

const getInitialActionParams = (loadedRule?: ProductRule): RuleParameter[] => {
  if (!loadedRule) return []
  return loadedRule.action.parameters.map(getParameterData)
}

export const TacoRulesProvider = ({ children, loadedRule }: Props) => {
  const { conditions, conditionLogicalOperator } = getInitialConditions(loadedRule)

  const actionParams = getInitialActionParams(loadedRule)

  const initialState: TacoRuleState = {
    ruleName: loadedRule?.name,
    ruleDescription: loadedRule?.description,
    triggerName: loadedRule?.trigger.name,
    actionName: loadedRule?.action.name,
    actionParams,
    conditions,
    conditionLogicalOperator
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  return <TacoRulesContext.Provider value={{ state, dispatch }}>{children}</TacoRulesContext.Provider>
}
