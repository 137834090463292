import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Flex, Heading } from '@11FSFoundry/figloo'
import { AuthContext } from './Auth'

const LoginFailed = () => {
  const { reset } = useContext(AuthContext)
  return (
    <Flex flexDirection="column" flex={1}>
      <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="center">
        <Heading as="h1" variant="h1">
          Unable to log in
        </Heading>
        <p>Sorry, it does not seem like you have access to this site.</p>
        <p>
          If you need any assistance, please contact foundry-support@11fs.com and the 11:FS Foundry team will be happy
          to help.
        </p>
      </Flex>
      <Flex justifyContent="center">
        <Link to="/" onClick={reset}>
          Back to login
        </Link>
      </Flex>
    </Flex>
  )
}

export default LoginFailed
