import React, { useContext } from 'react'
import { Flex, Text } from '@11FSFoundry/figloo'
import Page from 'components/Page'
import Authoriser from 'components/product-configuration/approval/Authoriser'
import ProductVersionTabs from 'components/product-configuration/ProductVersionTabs'
import WithdrawVersionFromReviewController from 'components/product-configuration/approval/WithdrawVersionFromReviewController'
import { ProductVersionContext } from 'components/product-configuration/ProductVersion'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import SubmitVersionForReviewController from 'components/product-configuration/approval/SubmitVersionForReviewController'
import ReviewVersionController from 'components/product-configuration/approval/ReviewVersionController'

const ProductVersionApprovePage = () => {
  const hasWithdrawProductVersionPermission = useCheckPermissions('withdraw-approval-request:product-versions')
  const hasSendForApprovalProductVersionPermission = useCheckPermissions('request-approval:product-versions')
  const hasApproveProductVersionPermission = useCheckPermissions('approve:product-versions')
  const hasRejectProductVersionPermission = useCheckPermissions('reject:product-versions')
  const { product, version } = useContext(ProductVersionContext)

  return (
    <Page>
      <ProductVersionTabs>
        <Flex justifyContent="center">
          <Flex flexDirection="column" width={1152}>
            <Flex my={5} justifyContent="flex-end">
              <Authoriser
                authorise={({ versionStatus }) =>
                  (versionStatus === 'DRAFT' || versionStatus === 'REJECTED') &&
                  hasSendForApprovalProductVersionPermission
                }>
                <SubmitVersionForReviewController />
              </Authoriser>
              <Authoriser
                authorise={({ versionStatus }) =>
                  versionStatus === 'UNDER_REVIEW' &&
                  (hasApproveProductVersionPermission || hasRejectProductVersionPermission)
                }>
                <ReviewVersionController />
              </Authoriser>
              <Authoriser
                authorise={({ versionStatus }) =>
                  versionStatus === 'UNDER_REVIEW' && hasWithdrawProductVersionPermission
                }>
                <WithdrawVersionFromReviewController productId={product.id} versionNumber={version.number} />
              </Authoriser>
              <Authoriser authorise={({ versionStatus }) => versionStatus === 'APPROVED'}>
                <Flex>
                  <Text>This product has already been approved</Text>
                </Flex>
              </Authoriser>
            </Flex>
          </Flex>
        </Flex>
      </ProductVersionTabs>
    </Page>
  )
}

export default ProductVersionApprovePage
