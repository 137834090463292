import React from 'react'
import { Button } from '@11FSFoundry/figloo'
import { useAuth0 } from './Auth0'

const Auth0Button = () => {
  const { loginWithRedirect } = useAuth0()

  const login = () => {
    loginWithRedirect({})
  }

  return (
    <div>
      <div data-testid="foundry-autho-button">
        <Button onClick={login}>Login with Auth0</Button>
      </div>
    </div>
  )
}

export default Auth0Button
