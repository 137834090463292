import React from 'react'
import { Flex, Text, Select } from '@11FSFoundry/figloo'
import { useProductVersionEditable } from 'hooks/useProductVersionEditable'
import { useCheckPermissions } from 'hooks/useCheckPermissions'

enum CalculationMethod {
  DecliningBalance = 'Declining balance with equal instalments'
}

const CalculationMethodForm = () => {
  const hasUpdateProductVersionPermission = useCheckPermissions('update:product-versions')
  const productVersionEditable = useProductVersionEditable()
  const editable = hasUpdateProductVersionPermission && productVersionEditable
  return (
    <Flex backgroundColor="white" py={3} px={6} my={2}>
      <Text my={1} mr={4}>
        Calculate interest using
      </Text>
      <Select disabled={!editable} flex={1}>
        {Object.entries(CalculationMethod).map(([method, description]) => (
          <option key={method} value={method}>
            {description}
          </option>
        ))}
      </Select>
    </Flex>
  )
}

export default CalculationMethodForm
