import React from 'react'
import { Text, Flex, theme, Box, Button } from '@11FSFoundry/figloo'
import { VersionStatus, ProductVersion } from 'types/index'
import useOpenApplications from 'gql/useOpenApplications'
import usePauseApplications from 'gql/usePauseApplications'
import { notifySuccess, notifyError } from 'components/Toast'

interface Props {
  version: ProductVersion
  productId: string
  currentLiveVersion: number | null
}

const getParams = (status: VersionStatus) => {
  switch (status) {
    case 'APPROVED':
      return {
        buttonText: 'Open Applications',
        circleColor: theme.colors.gsConcrete,
        applicationStatus: 'Approved'
      }

    case 'APPLICATIONS_OPEN':
      return {
        circleColor: theme.colors.mintDark,
        applicationStatus: 'Applications Open',
        buttonText: 'Pause Applications'
      }

    case 'APPLICATIONS_PAUSED':
      return {
        circleColor: theme.colors.lightCoral,
        applicationStatus: 'Applications Paused',
        buttonText: 'Open Applications'
      }

    default:
      throw new Error(`Unkown status: ${status}`)
  }
}

enum APPLICATIONS_ACTION_TYPE {
  OPEN_APPLICATIONS = 'OPEN_APPLICATIONS',
  PAUSE_APPLICATIONS = 'PAUSE_APPLICATIONS'
}

const ApplicationBlock = ({ version, currentLiveVersion, productId }: Props) => {
  const { note, number, status } = version
  const { circleColor, applicationStatus, buttonText } = getParams(status)
  const [openApplications, { loading: loadingOpen }] = useOpenApplications()
  const [pauseApplications, { loading: loadingPause }] = usePauseApplications()
  const applicationAction =
    status === 'APPLICATIONS_OPEN'
      ? APPLICATIONS_ACTION_TYPE.PAUSE_APPLICATIONS
      : APPLICATIONS_ACTION_TYPE.OPEN_APPLICATIONS

  const handleApplications = async (type: string, versionNumber: number) => {
    try {
      if (type === APPLICATIONS_ACTION_TYPE.OPEN_APPLICATIONS) {
        await openApplications({ variables: { productId, versionNumber } })
        notifySuccess('Accepting Applications')
      } else if (type === APPLICATIONS_ACTION_TYPE.PAUSE_APPLICATIONS) {
        await pauseApplications({ variables: { productId, versionNumber } })
        notifySuccess('Paused Applications')
      }
    } catch (error) {
      notifyError('Application update failed')
    }
  }

  return (
    <Flex minHeight={70} px={4} py={2} width={1} mb={2} alignItems="center" backgroundColor={theme.colors.white}>
      <Text flex={1} fontSize={16}>
        {note}
      </Text>
      <Flex flex={2}>
        <Box alignSelf="center" width={10} height={10} sx={{ borderRadius: 5 }} backgroundColor={circleColor} mr={20} />
        <Text>{applicationStatus}</Text>
      </Flex>
      <Button
        loading={loadingOpen || loadingPause}
        minWidth={174}
        disabled={!currentLiveVersion ? false : number !== currentLiveVersion}
        onClick={() => {
          handleApplications(applicationAction, number)
        }}
        variant="secondary">
        {buttonText}
      </Button>
    </Flex>
  )
}

export default ApplicationBlock
