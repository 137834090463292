import gql from 'graphql-tag'
import { FRAGMENT_PRODUCT } from './fragments'

const EDIT_ATTRIBUTES = gql`
  mutation($productId: String!, $versionNumber: Int!, $attributes: ProductAttributes!) {
    editAttributesOfProductVersion(productId: $productId, versionNumber: $versionNumber, attributes: $attributes) {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`

export default EDIT_ATTRIBUTES
