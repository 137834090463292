import React, { useContext, ChangeEvent } from 'react'
import { getAttributeValue } from 'util/productVersion'
import { Text, Select } from '@11FSFoundry/figloo'
import { ProductVersionContext } from './ProductVersion'

interface Props {
  onChange: (asset: string) => void
  value?: string
  disabled?: boolean
}

const AssetInput = ({ onChange, value = '', disabled }: Props) => {
  const { version } = useContext(ProductVersionContext)
  const asset = getAttributeValue<string>(version, 'productAsset')

  if (!asset) return <Text>No assets available</Text>

  return (
    <Select
      value={value}
      onChange={({ target }: ChangeEvent<HTMLSelectElement>) => onChange(target.value)}
      disabled={disabled}>
      <option disabled value="">
        Select asset
      </option>
      <option key={asset.toLowerCase()} value={asset.toLowerCase()}>
        {asset.toUpperCase()}
      </option>
      {/* If invalid asset is selected display it, but mark it disabled */}
      {value && value !== asset && (
        <option disabled key={value.toLowerCase()} value={value.toLowerCase()}>
          {value.toUpperCase()}
        </option>
      )}
    </Select>
  )
}

export default AssetInput
