import { useState, FormEvent } from 'react'

export interface ValidationResult {
  status: 'valid' | 'invalid'
  error?: string
}

export const Invalid = (error: string): ValidationResult => ({
  status: 'invalid',
  error
})

export const Valid: ValidationResult = { status: 'valid' }

export type ValidationFunction<T> = (formValues: Record<string, T>) => ValidationResult

function useFormHandler<T>(initialData: Record<string, T>, completeSubmit: (values: Record<string, unknown>) => void) {
  const [formValues, setFormValues] = useState<Record<string, T>>(initialData)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [validationFunctions, setValidationFunctions] = useState<{
    [key: string]: ValidationFunction<T>
  }>({})

  const handleChange = (name: string, value: T) => setFormValues({ ...formValues, [name]: value })

  const getValue = (name: string) => formValues[name]

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    completeSubmit(formValues)
  }

  const getError = (name: string) => errors[name]

  const validate = () =>
    Object.keys(validationFunctions).reduce((acc, key) => {
      const validationResult = validationFunctions[key](formValues)
      return validationResult.error
        ? {
            ...acc,
            [key]: validationResult.error
          }
        : acc
    }, {})

  const registerValidationFunction = (name: string, validationFunction: ValidationFunction<T>): void => {
    setValidationFunctions(existingFunctions => ({ ...existingFunctions, [name]: validationFunction }))
  }

  const evictValidationFunction = (name: string) => {
    const validationFunctionsClone = { ...validationFunctions }
    delete validationFunctionsClone[name]
    setValidationFunctions(validationFunctionsClone)
  }

  return {
    handleChange,
    getValue,
    getError,
    validate: () => {
      const validationErrors = validate()
      setErrors(validationErrors)
      return !Object.keys(validationErrors).length
    },
    registerValidationFunction,
    evictValidationFunction,
    handleSubmit
  }
}

export default useFormHandler
