import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardFooter, Box, Heading, Flex, NeedsReview, TotalVersions } from '@11FSFoundry/figloo'
import { AuthContext } from 'components/auth/Auth'

interface Product {
  id: string
  name: string
  liveVersions: number
  userCount: number
  versions: {
    status: string
    review: null | {
      reviewer: string
    }
  }[]
}

interface Props {
  products: Product[]
}

const ProductList = ({ products }: Props) => {
  const { tokenData } = useContext(AuthContext)
  const source = tokenData && tokenData.source
  const externalUserId = tokenData && tokenData.externalUserId
  const foundryUserId = tokenData && `foundry:identity:user:${source}:${externalUserId}`

  return (
    <Flex flexWrap="wrap" m={-1} data-testid="ProductList">
      {products.map(({ id, name, liveVersions, versions }: Product) => {
        const needsReview = !!versions.find(
          ({ status, review }) => status === 'UNDER_REVIEW' && review && review.reviewer === foundryUserId
        )
        return (
          <Flex p={1} width="25%" mb={3} key={id}>
            <Card as={Link} to={`/products/${id}`} needsReview={needsReview}>
              <Box>
                <Heading mb={1} variant="h3">
                  {name}
                </Heading>
                {needsReview && <NeedsReview />}
              </Box>
              <CardFooter>
                <TotalVersions totalVersions={liveVersions} />
              </CardFooter>
            </Card>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default ProductList
