import gql from 'graphql-tag'

// Note: Rule conditions are recursive (in that a `RuleCondition_And` can contain another `RuleCondition_And`),
// but there's no way to query recursively in GraphQl. As it stands, we avoid this problem by assuming that
// conditions don't contain this recursive nesting; so, any `RuleCondition_And` (or `Or`) will only contain
// `RuleCondition_Single`s.
export const FRAGMENT_RULE = gql`
  fragment SingleCondition on RuleCondition_Single {
    number
    property {
      name
      group
      groupLabel
      label
    }
    operator {
      name
      label
      parameters {
        name
        label
        ... on RuleParameter_String {
          stringValues: values
        }
        ... on RuleParameter_Long {
          longValues: values
        }
        ... on RuleParameter_Enum {
          enumValues: values
        }
        ... on RuleParameter_BigDecimal {
          bigDecimalValues: values
        }
        ... on RuleParameter_Amount {
          amountValues: values {
            asset
            balance
          }
        }
      }
    }
  }

  fragment Rule on Rule {
    number
    name
    description
    trigger {
      name
      label
    }
    action {
      name
      label
      parameters {
        name
        label
        ... on RuleParameter_String {
          stringValues: values
        }
        ... on RuleParameter_Long {
          longValues: values
        }
        ... on RuleParameter_Enum {
          enumValues: values
        }
        ... on RuleParameter_BigDecimal {
          bigDecimalValues: values
        }
        ... on RuleParameter_Amount {
          amountValues: values {
            asset
            balance
          }
        }
      }
    }

    condition {
      __typename
      ... on RuleCondition_And {
        number
        conditions {
          __typename
          ...SingleCondition
        }
      }
      ... on RuleCondition_Or {
        number
        conditions {
          __typename
          ...SingleCondition
        }
      }
      ...SingleCondition
    }
  }
`

export const FRAGMENT_DECISION_RULE = gql`
  fragment DecisionRule on DecisionRule {
    name
    label
    steps {
      condition {
        ...SingleCondition
      }
      result {
        parameters {
          name
          label
          unit {
            symbol
            affix
          }
          ... on RuleParameter_String {
            stringValues: values
          }
          ... on RuleParameter_Long {
            longValues: values
          }
          ... on RuleParameter_Enum {
            enumValues: values
          }
          ... on RuleParameter_BigDecimal {
            bigDecimalValues: values
          }
          ... on RuleParameter_Amount {
            amountValues: values {
              asset
              balance
            }
          }
        }
      }
    }
    defaultResult {
      parameters {
        name
        label
        unit {
          symbol
          affix
        }
        ... on RuleParameter_String {
          stringValues: values
        }
        ... on RuleParameter_Long {
          longValues: values
        }
        ... on RuleParameter_Enum {
          enumValues: values
        }
        ... on RuleParameter_BigDecimal {
          bigDecimalValues: values
        }
        ... on RuleParameter_Amount {
          amountValues: values {
            asset
            balance
          }
        }
      }
    }
  }
`

export const FRAGMENT_PRODUCT = gql`
  ${FRAGMENT_RULE}
  ${FRAGMENT_DECISION_RULE}
  fragment product on Product {
    id
    name
    description
    versions {
      note
      number
      userCount
      status
      attributeGroups {
        attributes {
          name
          value
        }
      }
      notes {
        name
        value
        visibility
      }
      files {
        id
        fileName
        url
        name
        description
        type
      }
      rules {
        ...Rule
      }
      decisionRules {
        ...DecisionRule
      }
    }
  }
`
