import gql from 'graphql-tag'

const GET_RULE_DEFINITIONS = gql`
  query {
    definitions {
      rules {
        trigger {
          name
          label
        }
        propertyGroups {
          name
          label
          properties {
            name
            label
            operatorInput
          }
        }
        action {
          name
          label
          parameters {
            name
            label
            type
            unit {
              symbol
              affix
            }
            arity
            constraints
            options {
              name
              label
              description
            }
          }
        }
        label
      }
    }
  }
`

export default GET_RULE_DEFINITIONS
