import React, { useState } from 'react'
import { Flex, Heading } from '@11FSFoundry/figloo'
import { notifySuccess, notifyError } from 'components/Toast'
import ClickEdit from 'components/product-configuration/ClickEdit'
import useEditVersion from 'gql/useEditVersion'
import { ProductVersion } from 'types'

interface Props {
  disabled?: boolean
  version: ProductVersion
  productId: string
}

const VersionNoteInput = ({ disabled, version, productId }: Props) => {
  const [note, setNote] = useState(version.note ?? '')
  const [editVersionMutation, { loading }] = useEditVersion()

  const handleChange = (updatedNote: string) => {
    setNote(updatedNote)
  }

  const handleCancel = () => {
    setNote(version.note)
  }

  const handleCommit = async () => {
    try {
      await editVersionMutation({
        variables: {
          productId,
          versionNumber: version.number,
          note
        }
      })
      notifySuccess('Note input saved')
    } catch (e) {
      notifyError('note input update failed')
    }
  }

  return (
    <Flex alignItems="center" mt={3} mb={5} pl={5}>
      <ClickEdit
        handleChange={handleChange}
        handleCommit={handleCommit}
        handleCancel={handleCancel}
        value={note}
        disabled={disabled}
        saving={loading}
        withExplicitSave>
        <Heading variant="h1" as="h1" data-testid="versionName">
          {version.note || 'Untitled version'}
        </Heading>
      </ClickEdit>
    </Flex>
  )
}

export default VersionNoteInput
